import { IonIcon } from '@ionic/react';
import { caretForwardCircleOutline } from 'ionicons/icons';
import React from 'react';
import classes from 'classnames';
interface DefaultRunAnyButtonProps {
  onClick: (event: React.MouseEvent<HTMLIonIconElement, MouseEvent>) => void;
  className?: string;
  disabled?: boolean;
}

export const RunBtn: React.FC<DefaultRunAnyButtonProps> = ({
  onClick,
  className = '',
  disabled,
}: DefaultRunAnyButtonProps) => {
  return (
    <IonIcon
      icon={caretForwardCircleOutline}
      onClick={(e) => !disabled && onClick(e)}
      className={classes(`z-10 text-4xl`, className, {
        'cursor-pointer': !disabled,
        'cursor-not-allowed': disabled,
        'text-gray-400': disabled,
      })}
    />
  );
};
