import {
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import React, { ReactNode } from 'react';
import { useTranslate } from '../../../i18n/translate';
import { Button } from '../Button';
import { FlexRow } from '../Layout';

type IonModalProps = {
  children?: React.ReactNode;
};

type DefaultModalProps = IonModalProps & {
  headerText: string;
  submitText: string;
  isOpen: boolean;
  toggleOpen: () => void;
  onSubmit: () => void;
  disabledSubmitButton?: boolean;
  showCancelButton?: boolean;
  hideSubmitButton?: boolean;
  showHeaderCancelButton?: boolean;
  cancelText?: string;
  showButtonsInFooter?: boolean;
  buttons?: ReactNode;
};

export const Modal: React.FC<DefaultModalProps> = ({
  headerText,
  submitText,
  isOpen,
  toggleOpen,
  onSubmit,
  disabledSubmitButton,
  hideSubmitButton = false,
  showCancelButton = false,
  showHeaderCancelButton = false,
  cancelText = 'modal.abort',
  children,
  buttons,
}: DefaultModalProps) => {
  const translate = useTranslate();

  return (
    <IonModal isOpen={isOpen} style={{ height: '100vh' }}>
      <IonContent
        style={{
          overflowY: 'scroll',
        }}
      >
        <div>
          <IonHeader mode={'md'} style={{ height: '7vh' }}>
            <IonToolbar>
              <FlexRow justifyContent="between">
                <IonTitle slot="start">{translate(headerText)}</IonTitle>
                {showHeaderCancelButton ? (
                  <IonIcon
                    icon={closeOutline}
                    onClick={toggleOpen}
                    className="absolute right-5 text-4xl"
                  />
                ) : null}
              </FlexRow>
            </IonToolbar>
          </IonHeader>
          <div
            style={{ height: '93vh' }}
            className="flex flex-grow flex-col justify-between"
          >
            <div>{children} </div>
            <div className="pt-5">
              {buttons || (
                <FlexRow justifyContent="between" spaceBetween={1}>
                  {showCancelButton ? (
                    <Button
                      className="w-full rounded-tr"
                      expand="full"
                      onClick={toggleOpen}
                    >
                      {cancelText}
                    </Button>
                  ) : null}
                  {hideSubmitButton || (
                    <Button
                      onClick={onSubmit}
                      disabled={disabledSubmitButton}
                      className="w-full rounded-tl"
                      expand="full"
                    >
                      {submitText}
                    </Button>
                  )}
                </FlexRow>
              )}
            </div>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};
