import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Project } from '~/types';
import { QueryOptions, unwrap } from './common';
import axios from 'axios';

export async function getProjects() {
  return await axios.get<Project[]>('/projects/').then(unwrap);
}

export function useProjects<T = Project[]>(
  options?: QueryOptions<Project[], T>,
) {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['project', 'list'],
    queryFn: async () => {
      const projects = await axios.get<Project[]>('/projects/').then(unwrap);
      for (const project of projects) {
        queryClient.setQueryData(['project', project.id], project);
      }
      return projects;
    },
    ...options,
  });
}

export async function getProject(projectId: number) {
  return axios.get<Project>(`/project/${projectId}/`).then(unwrap);
}

export function useProject<T = Project>({
  projectId,
  ...options
}: QueryOptions<Project, T> & { projectId: number }) {
  return useQuery({
    queryKey: ['project', projectId],
    queryFn: async () => getProject(projectId),
    ...options,
  });
}
