import { IonContent, IonFooter, IonPage, useIonToast } from '@ionic/react';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Button,
  FlexColumn,
  NumberInput,
  Option,
  Select,
  Textarea,
  TextInput,
} from '~/components/defaultUIComponents';
import Toolbar from '../components/Toolbar/Toolbar';
import routes from '../constants/routes.json';
import { useTranslate } from '~/i18n/translate';
import { Department, RunningNewJob, RunningSpecialJob } from '~/types';
import { newProjectJobSchema, newSpecialJobSchema } from '~/utils/yup/schemata';
import { useDepartments } from '~/api/DepartmentApi';
import { useProjects } from '~/api/ProjectApi';
import { useCreateNewJob, useStartNewJob } from '~/api/NewJobApi';
import { useCreateSpecialJob, useStartSpecialJob } from '~/api/SpecialJobApi';

import { useEmployeeId } from '~/state/auth';

enum ChosenJobType {
  none,
  projectJob,
  specialJob,
}

const CreateNewJob: React.FC = () => {
  const employeeId = useEmployeeId();
  const { data: departments } = useDepartments();
  const { data: projects } = useProjects();

  const onSuccess = (runningAny: RunningNewJob | RunningSpecialJob) => {
    if (chosenJobType === ChosenJobType.projectJob) {
      history.push(
        routes.RUN_NEW_JOB.replace(
          ':new_job',
          (runningAny as RunningNewJob).new_job.id,
        ),
      );
    } else if (chosenJobType === ChosenJobType.specialJob) {
      history.push(
        routes.RUN_SPECIAL_JOBS.replace(
          ':specialJob',
          (runningAny as RunningSpecialJob).special_job.id,
        ),
      );
    }
  };

  const { mutate: startNewJob, isPending: startNewPending } = useStartNewJob({
    onSuccess,
  });
  const { mutate: createNewJob, isPending: createNewPending } = useCreateNewJob(
    {
      onSuccess: (newJob) => {
        startNewJob({ newJobId: Number(newJob.id) });
      },
    },
  );

  const { mutate: startSpecialJob, isPending: startSpecialPending } =
    useStartSpecialJob({ onSuccess });
  const { mutate: createSpecialJob, isPending: createSpecialPending } =
    useCreateSpecialJob({
      onSuccess: (specialJob) => {
        startSpecialJob({ specialJobId: Number(specialJob.id) });
      },
    });

  const isPending =
    createNewPending ||
    startNewPending ||
    createSpecialPending ||
    startSpecialPending;

  const [selectedDepartment, setSelectedDepartment] =
    useState<Department | null>(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState<number>(1);
  const [projectId, setProject] = useState<string>();
  const [chosenJobType, setChosenJobType] = useState(ChosenJobType.none);

  const t = useTranslate();
  const history = useHistory();

  const handleNameChange = (value: string) => {
    setName(value.trimLeft());
  };

  const handleDescriptionChange = (value: string | null | undefined) => {
    if (!value) {
      setDescription('');
      return;
    }
    setDescription(value.trimLeft());
  };

  const handleAmountChange = (value: number) => {
    setAmount(value);
  };

  const handleSelectChange = (value: string | string[] | null | undefined) => {
    if (value) {
      setProject(value as string);
    }
  };

  const schema = useMemo(() => {
    if (chosenJobType === ChosenJobType.projectJob) {
      return newProjectJobSchema;
    } else if (chosenJobType === ChosenJobType.specialJob) {
      return newSpecialJobSchema;
    }
  }, [chosenJobType]);

  const [present] = useIonToast();

  const showToastSchemaIsNotValid = (message: string = 'yup.notValid') => {
    void present({
      message: t(message),
      duration: 1000,
      position: 'bottom',
      color: 'danger',
    });
  };

  function serverCall() {
    if (chosenJobType === ChosenJobType.specialJob) {
      createSpecialJob({
        name,
        amount,
        duration: 0,
        description,
        employee: employeeId,
      });
    } else if (chosenJobType === ChosenJobType.projectJob && projectId) {
      const project = projects?.find(
        (p) => Number(p.id) === parseInt(projectId),
      );
      if (project && selectedDepartment) {
        createNewJob({
          name,
          description,
          department: Number(selectedDepartment.id),
          project: Number(projectId),
          done_per_project: 1,
        });
      }
    }
  }

  const handleButtonClick = () => {
    schema
      ?.isValid(
        chosenJobType === ChosenJobType.projectJob
          ? {
              description,
              name,
              project: projectId,
              department: selectedDepartment ? selectedDepartment.id : null,
            }
          : {
              description,
              amount,
            },
      )
      .then(function (valid) {
        if (valid) {
          serverCall();
        } else showToastSchemaIsNotValid();
      })
      .catch((error) => {
        showToastSchemaIsNotValid(error as string);
      });
  };

  return (
    <IonPage>
      <Toolbar>{t('createNewJob.toolbar')}</Toolbar>
      <IonContent>
        {chosenJobType === ChosenJobType.none ? (
          <FlexColumn padding={2}>
            <Button
              onClick={() => {
                setChosenJobType(ChosenJobType.projectJob);
              }}
              className="mr-4 mb-2 h-36 text-2xl"
            >
              {t('createNewJob.projektJob')}
            </Button>
            <Button
              onClick={() => {
                setChosenJobType(ChosenJobType.specialJob);
              }}
              className="mr-4 mb-2 h-36 text-2xl"
            >
              {t('createNewJob.specialJob')}
            </Button>
          </FlexColumn>
        ) : (
          <FlexColumn padding={2} className="gap-2">
            {chosenJobType === ChosenJobType.projectJob ? (
              <>
                <Button
                  onClick={() => {
                    setChosenJobType(ChosenJobType.none);
                  }}
                  className="mr-4"
                >
                  {t('createNewJob.goBack')}
                </Button>
                <Select
                  placeholder="select.placeholder.chooser"
                  label="createNewJob.project"
                  name="projectSelect"
                  onChange={handleSelectChange}
                  showDefaultValue={false}
                  value={`${projectId}`}
                  className="mt-1"
                >
                  {_.orderBy(projects, 'name')?.map((project) => (
                    <Option value={`${project.id}`} key={project.id}>
                      {project.name}
                    </Option>
                  ))}
                </Select>
                <Select
                  label="runNewJob.department"
                  onChange={(e) => {
                    setSelectedDepartment(e);
                  }}
                  value={selectedDepartment}
                  name="department"
                >
                  <Option value={null}>runNewJob.department.select</Option>
                  {_.orderBy(departments, 'name')?.map((department) => (
                    <Option key={department.id} value={department}>
                      {department.name}
                    </Option>
                  ))}
                </Select>
                <TextInput
                  value={name}
                  onChange={handleNameChange}
                  name="nameInput"
                  label="createNewJob.name"
                  placeholder=""
                  required
                />
                <Textarea
                  value={description}
                  onChange={(val) => handleDescriptionChange(val)}
                  name="descriptionTextarea"
                  label="createNewJob.description"
                  autocorrect="on"
                  clearInput
                  spellcheck
                  rows={3}
                />
              </>
            ) : null}
            {chosenJobType === ChosenJobType.specialJob ? (
              <>
                <Button
                  onClick={() => {
                    setChosenJobType(ChosenJobType.none);
                  }}
                  className="mr-4"
                >
                  {t('createNewJob.goBack')}
                </Button>
                <TextInput
                  value={name}
                  onChange={handleNameChange}
                  name="nameInput"
                  label="createNewJob.name"
                  placeholder=""
                  required
                />
                <Textarea
                  value={description}
                  onChange={(val) => handleDescriptionChange(val)}
                  name="descriptionTextarea"
                  label="createNewJob.description"
                  autocorrect="on"
                  clearInput
                  spellcheck
                  rows={3}
                />
                <NumberInput
                  value={amount}
                  onChange={handleAmountChange}
                  name="amountInput"
                  label="createNewJob.amount"
                  placeholder=""
                  min="1"
                  max="99"
                  required
                />
              </>
            ) : null}
          </FlexColumn>
        )}
      </IonContent>
      {chosenJobType === ChosenJobType.none || (
        <IonFooter className="bg-white">
          <Button
            expand="full"
            disabled={isPending}
            onClick={handleButtonClick}
          >
            {t('createNewJob.create')}
          </Button>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default CreateNewJob;
