/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Job
 */
export interface Job {
    /**
     * 
     * @type {number}
     * @memberof Job
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Job
     */
    foreignId: number;
    /**
     * 
     * @type {number}
     * @memberof Job
     */
    duration: number;
    /**
     * 
     * @type {number}
     * @memberof Job
     */
    donePerProject: number;
    /**
     * 
     * @type {number}
     * @memberof Job
     */
    doneAmount: number;
}

/**
 * Check if a given object implements the Job interface.
 */
export function instanceOfJob(value: object): value is Job {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('foreignId' in value) || value['foreignId'] === undefined) return false;
    if (!('duration' in value) || value['duration'] === undefined) return false;
    if (!('donePerProject' in value) || value['donePerProject'] === undefined) return false;
    if (!('doneAmount' in value) || value['doneAmount'] === undefined) return false;
    return true;
}

export function JobFromJSON(json: any): Job {
    return JobFromJSONTyped(json, false);
}

export function JobFromJSONTyped(json: any, ignoreDiscriminator: boolean): Job {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'foreignId': json['foreignId'],
        'duration': json['duration'],
        'donePerProject': json['donePerProject'],
        'doneAmount': json['doneAmount'],
    };
}

  export function JobToJSON(json: any): Job {
      return JobToJSONTyped(json, false);
  }

  export function JobToJSONTyped(value?: Job | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'foreignId': value['foreignId'],
        'duration': value['duration'],
        'donePerProject': value['donePerProject'],
        'doneAmount': value['doneAmount'],
    };
}

