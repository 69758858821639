import { useTranslate } from '../i18n/translate';
import { StampType } from '../types';

export function useWorkTypeToString() {
  const translate = useTranslate();
  return (stampType: StampType | null | undefined) => {
    if (stampType === StampType.WORK) {
      return translate(`working-state.working`);
    } else if (stampType === StampType.PAUSE) {
      return translate(`working-state.pause`);
    } else {
      return null;
    }
  };
}
