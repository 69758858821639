/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Project } from './Project';
import {
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
    ProjectToJSONTyped,
} from './Project';
import type { OrderedProjectHistory } from './OrderedProjectHistory';
import {
    OrderedProjectHistoryFromJSON,
    OrderedProjectHistoryFromJSONTyped,
    OrderedProjectHistoryToJSON,
    OrderedProjectHistoryToJSONTyped,
} from './OrderedProjectHistory';
import type { OrderInformation } from './OrderInformation';
import {
    OrderInformationFromJSON,
    OrderInformationFromJSONTyped,
    OrderInformationToJSON,
    OrderInformationToJSONTyped,
} from './OrderInformation';
import type { OrderProjectStatus } from './OrderProjectStatus';
import {
    OrderProjectStatusFromJSON,
    OrderProjectStatusFromJSONTyped,
    OrderProjectStatusToJSON,
    OrderProjectStatusToJSONTyped,
} from './OrderProjectStatus';

/**
 * 
 * @export
 * @interface OrderedProject
 */
export interface OrderedProject {
    /**
     * 
     * @type {number}
     * @memberof OrderedProject
     */
    id: number;
    /**
     * 
     * @type {Project}
     * @memberof OrderedProject
     */
    project: Project;
    /**
     * 
     * @type {number}
     * @memberof OrderedProject
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof OrderedProject
     */
    priority: number;
    /**
     * 
     * @type {number}
     * @memberof OrderedProject
     */
    doneAmount: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderedProject
     */
    checkedIn: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OrderedProject
     */
    checkedInDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderedProject
     */
    comment?: string;
    /**
     * 
     * @type {Array<OrderedProject>}
     * @memberof OrderedProject
     */
    options: Array<OrderedProject>;
    /**
     * 
     * @type {OrderProjectStatus}
     * @memberof OrderedProject
     */
    orderProjectStatus: OrderProjectStatus;
    /**
     * 
     * @type {OrderInformation}
     * @memberof OrderedProject
     */
    orderInformation: OrderInformation;
    /**
     * 
     * @type {Array<OrderedProjectHistory>}
     * @memberof OrderedProject
     */
    history: Array<OrderedProjectHistory>;
}



/**
 * Check if a given object implements the OrderedProject interface.
 */
export function instanceOfOrderedProject(value: object): value is OrderedProject {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('project' in value) || value['project'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('priority' in value) || value['priority'] === undefined) return false;
    if (!('doneAmount' in value) || value['doneAmount'] === undefined) return false;
    if (!('checkedIn' in value) || value['checkedIn'] === undefined) return false;
    if (!('options' in value) || value['options'] === undefined) return false;
    if (!('orderProjectStatus' in value) || value['orderProjectStatus'] === undefined) return false;
    if (!('orderInformation' in value) || value['orderInformation'] === undefined) return false;
    if (!('history' in value) || value['history'] === undefined) return false;
    return true;
}

export function OrderedProjectFromJSON(json: any): OrderedProject {
    return OrderedProjectFromJSONTyped(json, false);
}

export function OrderedProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderedProject {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'project': ProjectFromJSON(json['project']),
        'amount': json['amount'],
        'priority': json['priority'],
        'doneAmount': json['doneAmount'],
        'checkedIn': json['checkedIn'],
        'checkedInDate': json['checkedInDate'] == null ? undefined : (new Date(json['checkedInDate'])),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'options': ((json['options'] as Array<any>).map(OrderedProjectFromJSON)),
        'orderProjectStatus': OrderProjectStatusFromJSON(json['orderProjectStatus']),
        'orderInformation': OrderInformationFromJSON(json['orderInformation']),
        'history': ((json['history'] as Array<any>).map(OrderedProjectHistoryFromJSON)),
    };
}

  export function OrderedProjectToJSON(json: any): OrderedProject {
      return OrderedProjectToJSONTyped(json, false);
  }

  export function OrderedProjectToJSONTyped(value?: OrderedProject | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'project': ProjectToJSON(value['project']),
        'amount': value['amount'],
        'priority': value['priority'],
        'doneAmount': value['doneAmount'],
        'checkedIn': value['checkedIn'],
        'checkedInDate': value['checkedInDate'] == null ? undefined : ((value['checkedInDate']).toISOString()),
        'comment': value['comment'],
        'options': ((value['options'] as Array<any>).map(OrderedProjectToJSON)),
        'orderProjectStatus': OrderProjectStatusToJSON(value['orderProjectStatus']),
        'orderInformation': OrderInformationToJSON(value['orderInformation']),
        'history': ((value['history'] as Array<any>).map(OrderedProjectHistoryToJSON)),
    };
}

