import { useAuthStore } from '../state/auth';

export enum FeatureFlag {
  TIMETRACKING = 'TIMETRACKING',
  ORDERS = 'ORDERS',
  DAYPLAN = 'DAYPLAN',
  WEEKPLAN = 'WEEKPLAN',
  ROUTINES = 'ROUTINES',
  RUNNINGJOBS = 'RUNNINGJOBS',
  SPECIALJOBS = 'SPECIALJOBS',
  NEWJOBS = 'NEWJOBS',
  CODESCANNING = 'CODESCANNING',
}

export const isFeatureFlagActive = (
  featureFlags: string,
  featureFlag: string,
): boolean => {
  if (process.env.NODE_ENV === 'development') {
    return true;
  }
  return featureFlags.includes(featureFlag);
};

function useIsFeatureFlagActive(featureFlag: string): boolean {
  const activeFeatureFlags = useAuthStore((store) => store.feature_flags) ?? '';
  if (process.env.NODE_ENV === 'development') {
    return true;
  }
  return activeFeatureFlags.includes(featureFlag);
}

export default useIsFeatureFlagActive;
