import { IonIcon, useIonPicker } from '@ionic/react';

import { alarmOutline } from 'ionicons/icons';
import * as dateFns from 'date-fns';
import React from 'react';
import { useTranslate } from '../../../i18n/translate';
import { FlexRow } from '../Layout';
import { Text } from '../Typography';
import { dateFnsDurationToString } from '../../../utils/durationUtil';

export interface IDuration {
  hours: number;
  minutes: number;
  seconds: number;
  toDateFnsDuration(): dateFns.Duration;
  asSeconds(): number;
}

class Duration implements IDuration {
  constructor(
    readonly hours: number,
    readonly minutes: number,
    readonly seconds: number,
  ) {}
  toDateFnsDuration(): dateFns.Duration {
    return {
      days: 0,
      months: 0,
      years: 0,
      weeks: 0,
      hours: this.hours,
      minutes: this.minutes,
      seconds: this.seconds,
    };
  }
  asSeconds(): number {
    return this.hours * 60 * 60 + this.minutes * 60 + this.seconds;
  }
}

interface DurationPickerOptions {
  onChange: (duration: IDuration) => void;
}

export const useDurationPicker = (options: DurationPickerOptions) => {
  const translate = useTranslate();
  const [present] = useIonPicker();
  const hours = React.useMemo(() => {
    const vals = [];
    for (let i = 0; i < 25; i += 1) {
      vals.push({ text: `${i}`, value: i });
    }
    return vals;
  }, []);
  const minutes = React.useMemo(() => {
    const vals = [];
    for (let i = 0; i < 61; i += 1) {
      vals.push({ text: `${i}`, value: i });
    }
    return vals;
  }, []);
  const seconds = React.useMemo(() => {
    const vals = [];
    for (let i = 0; i < 61; i += 1) {
      vals.push({ text: `${i}`, value: i });
    }
    return vals;
  }, []);

  return () =>
    present(
      [
        { name: 'hours', options: hours, suffix: 'h' },
        { name: 'minutes', options: minutes, suffix: 'm' },
        { name: 'seconds', options: seconds, suffix: 's' },
      ],
      [
        {
          text: translate('durationPicker.confirm'),
          handler: (selection) =>
            options.onChange(
              new Duration(
                selection.hours.value,
                selection.minutes.value,
                selection.seconds.value,
              ),
            ),
        },
      ],
    );
};

export const DurationPicker: React.FC<{
  onChange: (duration: Duration) => void;
}> = ({ onChange }) => {
  const [value, setValue] = React.useState(new Duration(0, 0, 0));
  const present = useDurationPicker({
    onChange: (duration) => {
      setValue(duration);
      onChange(duration);
    },
  });
  const displayValue = React.useMemo(() => {
    return dateFnsDurationToString(value.toDateFnsDuration());
  }, [value]);

  return (
    <div onClick={present}>
      <FlexRow
        className="border-2 rounded-md px-2 py-1"
        justifyContent="between"
      >
        <Text>{displayValue}</Text>
        <IonIcon icon={alarmOutline} />
      </FlexRow>
    </div>
  );
};
