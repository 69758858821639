import { IonButton, IonIcon } from '@ionic/react';
import { arrowDown, arrowUp } from 'ionicons/icons';
import React from 'react';
import { Button } from './Button';

interface CollapseButtonProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
  id?: string;
  disabled?: boolean;
  className?: string;
  w?: number;
  h?: number;
}

export const CollapseButton: React.FC<CollapseButtonProps> = ({
  isOpen,
  toggleIsOpen,
  id,
  disabled = false,
  className = '',
  w = 8,
  h = 8,
}) => {
  return (
    <IonButton
      onClick={toggleIsOpen}
      id={id}
      expand="block"
      className={`${className} rounded-full w-${w} h-${h}`}
      disabled={disabled}
    >
      <IonIcon icon={isOpen ? arrowDown : arrowUp} />
    </IonButton>
  );
};
