import { MutationOptions, QueryOptions, unwrap } from './common';
import { Routine, RunningAny, RunningRoutine } from '~/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useRunningAnys } from './JobApi';
import { isRunningRoutine } from '~/utils/runningAny';

export function useRoutines<T = Routine[]>(
  options?: QueryOptions<Routine[], T>,
) {
  return useQuery({
    ...options,
    queryKey: ['routines'],
    queryFn: () => axios.get<Routine[]>('/routines/').then(unwrap),
  });
}

export function useRunningRoutine({
  routineId,
  ...options
}: Omit<QueryOptions<RunningAny[], RunningRoutine | undefined>, 'select'> & {
  employeeId: number;
  routineId: number;
}) {
  return useRunningAnys({
    ...options,
    select: (data) =>
      data.find(
        (ra) => isRunningRoutine(ra) && Number(ra.routine.id) === routineId,
      ) as RunningRoutine | undefined,
  });
}

export function useResumeRoutine(
  options?: MutationOptions<RunningRoutine, { runningRoutineId: number }>,
) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: ({ runningRoutineId }) =>
      axios.patch(`/start_paused_routine/${runningRoutineId}/`),
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['running'],
      });
      options?.onSuccess?.(...args);
    },
  });
}

export function useStartRoutine(
  options?: MutationOptions<RunningRoutine, { routineId: number }>,
) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: ({ routineId }) => axios.post(`/start_routine/${routineId}/`),
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['running'],
      });
      options?.onSuccess?.(...args);
    },
  });
}

type EndRoutineRequest = {
  runningRoutineId: number;
  amount: number;
  comment: string;
  improvement_comment?: string;
  time_suggestion?: number;
};

export function useEndRoutine(
  options?: MutationOptions<void, EndRoutineRequest>,
) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: ({ runningRoutineId, ...req }) =>
      axios.post(`/end_routine/${runningRoutineId}/`, req).then(unwrap),
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['running'],
      });
      options?.onSuccess?.(...args);
    },
  });
}
