import { IonCheckbox, IonIcon, IonItem } from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import routes from '../constants/routes.json';
import { useTranslate } from '~/i18n/translate';
import { useEmployeeId } from '~/state/auth';
import { PictureType, Project } from '~/types';
import {
  FlexColumn,
  FlexRow,
  NumberInput,
  PictureViewer,
  RunBtn,
  Text,
} from './defaultUIComponents';
import { useDeleteTodo, useTodo } from '~/api/DayplanApi';
import { useJob, useStartJob } from '~/api/JobApi';
import { useDayplanStore } from '~/state/dayplan';
import { useSummedOrderedJob } from '~/api/OrderingApi';

type Props = {
  jobId: number;
  parentPage: 'Order' | 'Dayplan' | 'RunningJobs';
  option?: Project;
  isStartable?: boolean;
};

const JobTile: React.FC<Props> = ({
  jobId,
  parentPage,
  option,
  isStartable = true,
}) => {
  const translate = useTranslate();
  const history = useHistory();
  const runningJobRoute = routes.RUN_JOB.replace(':id', jobId.toString());
  const { data: totalAmount } = useSummedOrderedJob({
    jobId,
    select: (data) => data.total,
    enabled: parentPage !== 'Dayplan',
  });

  const employeeId = useEmployeeId();
  const { data: todo } = useTodo({ jobId, employeeId });
  const { data: job } = useJob({ jobId });

  const { mutate: deleteTodo } = useDeleteTodo();
  const { mutate: startJob } = useStartJob({
    onSuccess: () => {
      history.push(runningJobRoute);
    },
  });
  const { tmpTodo, setTmpTodo, toggleTmpTodo } = useDayplanStore((state) => {
    return {
      tmpTodo: state.todos.find((todo) => todo.job_id === jobId),
      setTmpTodo: (amount: number) => state.setTodoAmount(jobId, amount),
      toggleTmpTodo: () => state.toggleTodo(jobId),
    };
  });

  const [checked, setChecked] = useState(false);

  const displayedProjectName = useMemo(() => {
    const projectName = job?.projects?.[0]?.name;
    if (projectName && !option) {
      return '(' + projectName + ')';
    } else if (option) {
      return '(' + option.name + ')';
    } else return '';
  }, [job, option]);

  const jobString = useMemo(
    () =>
      history.location.pathname == routes.RUNNING_JOBS
        ? translate('jobTile.singular') + ': '
        : '',
    [history],
  );

  const onTmpTodoToggle = () => {
    setChecked(!checked);
    toggleTmpTodo();
  };

  return (
    <IonItem className="mr-1 ml-1 relative mt-2">
      <FlexColumn className="mb-0">
        <FlexRow>
          <Text className="font-bold mr-1">{`${translate(
            'jobTile.stepNumber',
          )} ${job?.step_number}`}</Text>
          <Text>{displayedProjectName}</Text>
        </FlexRow>
        <Text className="break-words mr-8">{`${jobString}${job?.name}`}</Text>
        {job?.job_pictures && (
          <PictureViewer
            pictureIds={job.job_pictures}
            pictureType={PictureType.JOB}
            projectOrRunningAnyId={job.id}
          />
        )}
        {job?.important_message ? (
          <FlexColumn>
            <Text color="red-500">runJob.important</Text>
            <Text color="red-500">{job.important_message}</Text>
          </FlexColumn>
        ) : null}
        {parentPage !== 'Dayplan' ? (
          <FlexRow justifyContent="between" className="mb-1">
            <Text>{translate('jobTile.totalAmount') + ' ' + totalAmount}</Text>
          </FlexRow>
        ) : (
          <Text>
            {translate('jobTile.todoAmount') + ' ' + (todo?.amount ?? 0)}
          </Text>
        )}

        {isStartable ? (
          <RunBtn
            className="primary absolute top-0 right-0"
            onClick={() => startJob({ jobId })}
          />
        ) : null}
        {parentPage !== 'Dayplan' || (
          <IonIcon
            icon={closeCircleOutline}
            className="primary absolute bottom-0 right-0 z-10 text-4xl"
            onClick={() =>
              deleteTodo({
                employeeId,
                todoId: Number(todo?.id),
              })
            }
          />
        )}
        {parentPage !== 'Order' || (
          <FlexColumn>
            <IonCheckbox checked={checked} onIonChange={onTmpTodoToggle}>
              {translate('jobTile.checkbox.label')}
            </IonCheckbox>
            {!checked || (
              <NumberInput
                value={tmpTodo?.amount ?? 0}
                onChange={(inputValue) => setTmpTodo(inputValue)}
                inputmode="numeric"
                name="amountInput"
                label="jobTile.InputCopyToDayplan.placeholder"
                min="1"
                max="99"
                required
              />
            )}
          </FlexColumn>
        )}
        {job?.description && (
          <>
            <Text className="mt-1 underline">{'jobTile.description'}</Text>
            <Text className="break-words mb-1">
              {job?.description.substring(0, 280)}
            </Text>
          </>
        )}
      </FlexColumn>
    </IonItem>
  );
};

export default JobTile;
