import {
  IonContent,
  IonHeader,
  IonIcon,
  IonicSlides,
  IonImg,
  IonLabel,
  IonModal,
  IonSpinner,
  IonThumbnail,
  IonToolbar,
} from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Autoplay,
  Keyboard,
  Pagination,
  Scrollbar,
  Zoom,
} from 'swiper/modules';
import { closeSharp } from 'ionicons/icons';
import React from 'react';
import { useTranslate } from '~/i18n/translate';
import { AllPictureIdTypes, PictureType } from '~/types';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';
import '@ionic/react/css/ionic-swiper.css';
import {
  useAllPicturesOfRunningAny,
  usePictureOfRunningAny,
} from '~/api/PictureApi';
import { useDisclosure } from '~/utils/disclosure';

export type PictureViewerProps = {
  pictureIds: AllPictureIdTypes[];
  pictureType: PictureType;
  projectOrRunningAnyId?: string;
  classNamePreviewPicture?: string;
};

export const PictureViewer: React.FC<PictureViewerProps> = ({
  pictureIds: initPictureIds,
  pictureType,
  classNamePreviewPicture: className = '',
}: PictureViewerProps) => {
  const translate = useTranslate();
  const modal = useDisclosure();

  const { data: picturesDataToShow, isLoading } = useAllPicturesOfRunningAny({
    picIds: initPictureIds.map((picId) => ({
      id: Number(picId.id),
      type: pictureType,
    })),
    enabled: modal.isOpen,
  });

  const { data: thumbnail } = usePictureOfRunningAny({
    pictureId: Number(initPictureIds?.[0]?.id),
    pictureType,
    enabled: !!Number(initPictureIds?.[0]?.id),
  });

  if (!initPictureIds || initPictureIds.length === 0) return null;

  if (isLoading) return <IonSpinner />;

  return (
    <>
      <IonThumbnail
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '7px',
          '--size': '100px',
        }}
        className={className}
        onClick={modal.open}
      >
        <IonImg src={thumbnail?.picture} />
      </IonThumbnail>
      <IonModal
        isOpen={modal.isOpen}
        style={{
          background: 'rgba(44,39,45,0.2)',
        }}
      >
        <IonHeader>
          <IonToolbar className="px-2">
            <IonLabel className="text-2xl">
              {translate('pictureViewer.headerText')}
            </IonLabel>
            <IonIcon
              icon={closeSharp}
              slot="end"
              size="xl"
              onClick={modal.close}
              className="text-2xl"
            />
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <Swiper
            centeredSlides
            direction="vertical"
            slidesPerView={1.5}
            spaceBetween={40}
            modules={[
              Autoplay,
              Keyboard,
              Pagination,
              Scrollbar,
              Zoom,
              IonicSlides,
            ]}
            autoplay={false}
            keyboard={true}
            pagination={true}
            scrollbar={true}
            zoom={true}
            className="h-full"
          >
            {picturesDataToShow?.map((allPictureType) => (
              <SwiperSlide key={allPictureType.id}>
                <div className="swiper-zoom-container">
                  <img src={allPictureType.picture} alt="a-picture" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </IonContent>
      </IonModal>
    </>
  );
};

export default PictureViewer;
