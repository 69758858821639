import { MutationOptions, QueryOptions, unwrap } from './common';
import { NewJob, RunningAny, RunningNewJob } from '~/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useRunningAnys } from './JobApi';
import { isRunningNewJob } from '~/utils/runningAny';

export function useRunningNewJob({
  newJobId,
  ...options
}: Omit<QueryOptions<RunningAny[], RunningNewJob | undefined>, 'select'> & {
  employeeId: number;
  newJobId: number;
}) {
  return useRunningAnys({
    ...options,
    select: (data) =>
      data.find(
        (ra) => isRunningNewJob(ra) && Number(ra.new_job.id) === newJobId,
      ) as RunningNewJob | undefined,
  });
}

export function useStartNewJob(
  options?: MutationOptions<RunningNewJob, { newJobId: number }>,
) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: ({ newJobId }) =>
      axios.post(`/start_new_job/${newJobId}/`).then(unwrap),
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['running'],
      });
      options?.onSuccess?.(...args);
    },
  });
}

export function useResumeNewJob(
  options?: MutationOptions<RunningNewJob, { runningNewJobId: number }>,
) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: ({ runningNewJobId }) =>
      axios.patch(`/start_paused_new_job/${runningNewJobId}/`).then(unwrap),
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['running'],
      });
      options?.onSuccess?.(...args);
    },
  });
}

type CreateNewJobRequest = {
  name: string;
  description: string;
  project: number;
  done_per_project: 1;
  department: number;
};

export function useCreateNewJob(
  options?: MutationOptions<NewJob, CreateNewJobRequest>,
) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: (values) => axios.post(`/new_job/`, values).then(unwrap),
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: ['running'] });
      options?.onSuccess?.(...args);
    },
  });
}

type EndNewJobRequest = {
  runningNewJobId: number;
  comment: string;
  amount: number;
};

export function useEndNewJob(options: MutationOptions<void, EndNewJobRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: ({ runningNewJobId, ...req }) =>
      axios.post(`/end_new_job/${runningNewJobId}/`, req).then(unwrap),
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({ queryKey: ['running'] });
      void queryClient.invalidateQueries({ queryKey: ['new_job'] });
      options?.onSuccess?.(...args);
    },
  });
}
