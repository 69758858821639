import { LanguageSpec } from '../LanguageSpec';

export const en: LanguageSpec = {
  'menuRoutes.timeTracking': 'Time Tracking',
  'menuRoutes.orders': 'Orders',
  'menuRoutes.dayplan': 'Dayplan',
  'menuRoutes.weekplan': 'Weekplan',
  'menuRoutes.routines': 'Routines',
  'menuRoutes.newJob': 'New Job',
  'menuRoutes.runningJobs': 'Running Jobs',
  'menuRoutes.createSpecialJob': 'New Specialjob',
  'menuRoutes.specialJobs': 'Specialjobs',
  'menuRoutes.settings': 'Settings',
};
