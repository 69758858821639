/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const HistoryType = {
    CHECKED_IN: 'CHECKED_IN',
    DONE: 'DONE'
} as const;
export type HistoryType = typeof HistoryType[keyof typeof HistoryType];


export function instanceOfHistoryType(value: any): boolean {
    for (const key in HistoryType) {
        if (Object.prototype.hasOwnProperty.call(HistoryType, key)) {
            if (HistoryType[key as keyof typeof HistoryType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function HistoryTypeFromJSON(json: any): HistoryType {
    return HistoryTypeFromJSONTyped(json, false);
}

export function HistoryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoryType {
    return json as HistoryType;
}

export function HistoryTypeToJSON(value?: HistoryType | null): any {
    return value as any;
}

export function HistoryTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): HistoryType {
    return value as HistoryType;
}

