import {
  IonContent,
  IonFooter,
  IonLoading,
  IonPage,
  IonText,
  useIonToast,
} from '@ionic/react';
import React, { useMemo, useState } from 'react';

import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import {
  Button,
  CancelModal,
  NumberInput,
  PictureViewer,
  TakePhotoButton,
  Textarea,
} from '~/components/defaultUIComponents';
import Toolbar from '../components/Toolbar/Toolbar';
import routes from '../constants/routes.json';
import { useTranslate } from '~/i18n/translate';
import { PictureType } from '~/types';
import { endingNewJobSchema } from '~/utils/yup/schemata';
import { useEndNewJob, useRunningNewJob } from '~/api/NewJobApi';
import { useEmployeeId } from '~/state/auth';
import { useDisclosure } from '~/utils/disclosure';

const RunNewJob: React.FC = () => {
  const history = useHistory();
  const t = useTranslate();
  const employeeId = useEmployeeId();
  const match = useRouteMatch({
    path: routes.RUN_NEW_JOB,
    exact: true,
    location: history.location,
  });
  const newJobId = Number(
    (match?.params as { new_job: string } | undefined)?.new_job as
      | string
      | undefined,
  );

  const { data: runningNewJob, isLoading } = useRunningNewJob({
    employeeId,
    newJobId,
  });

  const { mutate: endNewJob } = useEndNewJob({
    onSuccess: () => {
      history.push(routes.WEEK_PLAN);
    },
  });

  const runningPictures = useMemo(
    () => runningNewJob?.pictures ?? [],
    [runningNewJob],
  );

  const [present] = useIonToast();

  const [description] = useState(runningNewJob?.new_job.description);
  const [comment, setComment] = useState(description);
  const [amount, setAmount] = useState<number>(0);

  const modal = useDisclosure();

  const showToastSchemaIsNotValid = (message: string = 'yup.notValid') => {
    void present({
      message: t(message),
      duration: 3000,
      color: 'danger',
    });
  };

  const onSubmit = (e?: React.FormEvent, cancel: boolean = false) => {
    e?.preventDefault();
    if (!cancel && runningPictures.length === 0) {
      showToastSchemaIsNotValid('runNewJob.picture.needed');
      return;
    }

    endingNewJobSchema
      .isValid({
        amount,
        comment,
      })
      .then(function (valid: boolean) {
        if (valid || cancel) {
          endNewJob({
            runningNewJobId: Number(runningNewJob?.id),
            comment: comment ?? '',
            amount: cancel ? 0 : amount,
          });
        } else showToastSchemaIsNotValid();
      })
      .catch((error: string | undefined) => {
        showToastSchemaIsNotValid(error);
      });
  };

  const handleCommentChange = (value: string | null | undefined) => {
    if (!value) {
      setComment('');
      return;
    }
    setComment(value.trimLeft());
  };

  const handleAmountChange = (value: number) => {
    setAmount(value);
  };

  return (
    <form onSubmit={onSubmit}>
      <IonPage>
        <Toolbar>{t('runNewJob.toolbar.title')}</Toolbar>
        <IonContent>
          <IonLoading isOpen={isLoading} />
          <div className="flex flex-col gap-3 px-3 pt-4">
            <IonText>{runningNewJob?.new_job.name}</IonText>
            <NumberInput
              value={amount}
              onChange={handleAmountChange}
              name="amountInput"
              label="runNewJob.amount"
              placeholder=""
              max="99"
              required
            />
            <Textarea
              value={comment}
              onChange={(val) => handleCommentChange(val)}
              name="commentTextarea"
              label="runNewJob.description"
              autocorrect="on"
              clearInput
              spellcheck
              rows={3}
            />
            <PictureViewer
              pictureIds={runningPictures}
              pictureType={PictureType.NEWJOB}
              projectOrRunningAnyId={runningNewJob?.id}
            />
          </div>
        </IonContent>
        <IonFooter className="bg-white w-full flex">
          <Button className="min-w-fit" onClick={modal.toggle}>
            runNewJob.cancel
          </Button>
          <Button className="w-full" type="submit">
            runNewJob.endNewJob
          </Button>
          <TakePhotoButton
            runningAnyId={runningNewJob?.id ?? ''}
            pictureType={PictureType.NEWJOB}
            className="h-14 w-15"
          />
          <CancelModal
            openModal={modal.isOpen}
            toggleOpenModal={modal.toggle}
            onSubmit={(e) => onSubmit(e, true)}
          />
        </IonFooter>
      </IonPage>
    </form>
  );
};
export default RunNewJob;
