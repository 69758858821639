import React from 'react';
import { useHistory } from 'react-router';
import routes from '../constants/routes.json';
import { AnyType, Routine } from '~/types';
import { DefaultRunningAnyTile } from './defaultUIComponents';
import { useRunningAnys } from '~/api/JobApi';
import { useEmployeeId } from '~/state/auth';
import { isRunningRoutine } from '~/utils/runningAny';
import { useResumeRoutine, useStartRoutine } from '~/api/RoutineApi';

type RoutineProps = {
  routine: Routine;
  isStartable?: boolean;
};

const RoutineTile: React.FC<RoutineProps> = ({ routine, isStartable }) => {
  const history = useHistory();
  const employeeId = useEmployeeId();

  const { data: runningRoutine } = useRunningAnys({
    employeeId,
    select: (ra) =>
      ra.find((rr) => isRunningRoutine(rr) && rr.routine.id == routine.id),
  });

  const onSuccess = () => {
    history.push(startRoutineRoute);
  };

  const { mutate: startRoutine } = useStartRoutine({ onSuccess });
  const { mutate: resumeRoutine } = useResumeRoutine({ onSuccess });
  const startRoutineRoute = routes.RUN_ROUTINE.replace(':routine', routine.id);

  const runRoutineOnClick = () => {
    if (runningRoutine?.status === 'R') {
      return history.push(startRoutineRoute);
    }
    if (runningRoutine) {
      return resumeRoutine({ runningRoutineId: Number(runningRoutine.id) });
    }
    startRoutine({ routineId: Number(routine.id) });
  };

  return (
    <DefaultRunningAnyTile
      anyType={AnyType.routine}
      name={routine.name}
      description={routine.description}
      runRunningAny={runRoutineOnClick}
      isStartable={isStartable}
    />
  );
};

export default RoutineTile;
