import { IonSearchbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useTranslate } from '../../../i18n/translate';

interface SearchFieldProps {
  onInputChange: (input: string) => void;
  inputPlaceHolder?: string;
  className?: string;
}

export const SearchField: React.FC<SearchFieldProps> = ({
  onInputChange,
  inputPlaceHolder = 'searchField.search',
  className = 'mx-0 px-0',
}) => {
  const [input, setInput] = useState<string | null | undefined>();
  const translate = useTranslate();
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onInputChange(input || '');
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [input, onInputChange]);

  return (
    <IonSearchbar
      value={input}
      className={`${className} text-gray-800`}
      placeholder={translate(inputPlaceHolder)}
      onIonChange={(e) => setInput(e.detail.value)}
      showClearButton="always"
    />
  );
};
