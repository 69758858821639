import { IonCheckbox, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react';
import { caretDown } from 'ionicons/icons';
import React from 'react';
import { useTranslate } from '../../../i18n/translate';
import { Modal } from '../Modal';

interface ModalSelectProps<T> {
  value: T | null | undefined;
  data: T[];
  values: (t: T) => string;
  labels: (t: T) => string;
  onSelect: (t: T | null | undefined) => void;
}

export function ModalSelect<T>({
  data,
  labels,
  onSelect,
  values,
  value: defaultValue,
}: ModalSelectProps<T>) {
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState<T | null | undefined>(defaultValue);
  return (
    <>
      <Modal
        headerText={'modalSelect.header'}
        submitText={'modalSelect.submit'}
        isOpen={open}
        toggleOpen={() => setOpen((open) => !open)}
        onSubmit={() => {
          onSelect(value);
          setOpen(false);
        }}
      >
        <IonList className="px-5">
          {data?.map((d) => (
            <IonItem onClick={() => setValue(d)} key={values(d)}>
              <IonLabel>{translate(labels(d))}</IonLabel>
              <IonCheckbox checked={!!value && values(value) === values(d)} />
            </IonItem>
          ))}
        </IonList>
      </Modal>
      <IonItem onClick={() => setOpen(true)} className="">
        <IonLabel>
          {!!value
            ? translate(labels(value))
            : translate('modalSelect.placeholder')}
        </IonLabel>
        <IonIcon slot="end" icon={caretDown} />
      </IonItem>
    </>
  );
}
