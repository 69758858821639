import React, { useEffect, useState } from 'react';

export function useDebouncedState<T>(props?: {
  timeout?: number;
}): [
  T | undefined,
  T | undefined,
  React.Dispatch<React.SetStateAction<T | undefined>>,
] {
  const [value, setValue] = useState<T>();
  const [debouncedValue, setDebouncedValue] = useState<T>();
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, props?.timeout ?? 1500);
    return () => clearTimeout(timeoutId);
  }, [value, setDebouncedValue]);
  return [value, debouncedValue, setValue];
}
