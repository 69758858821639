import { IonText } from '@ionic/react';
import React, { PropsWithChildren, useMemo } from 'react';
import { useTranslate } from '../../../i18n/translate';
import { BaseTextProps } from './types';

export interface TextProps extends BaseTextProps {
  onClick?: () => void;
  style?:
    | {
        [key: string]: any;
      }
    | undefined;
}

export const Text: React.FC<PropsWithChildren<TextProps>> = ({
  children,
  color,
  opacity,
  textSize = 'base',
  className = '',
  onClick,
  style,
  ...props
}) => {
  const translate = useTranslate();
  const Color = useMemo(() => (color ? `text-${color}` : ''), [color]);
  const Opacity = useMemo(
    () => (opacity ? `text-opacity-${opacity}` : ''),
    [opacity],
  );
  const TextSize = useMemo(() => `text-${textSize}`, [textSize]);
  return (
    <IonText
      className={`${Color} ${Opacity} ${TextSize} ${className} text-black`}
      onClick={onClick}
      style={style}
      {...props}
    >
      {translate(children)}
    </IonText>
  );
};
