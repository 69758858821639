import { Duration, Moment } from 'moment-timezone';

export type ValidationError = {
  usernameError?: string;
  emailError?: string;
  passwordError?: string;
  repeatPasswordError?: string;
};

export type AuthenticationPayloadType = {
  token: string;
  feature_flags: string;
  companyName: string;
  companyId: string;
  id: string;
  email: string;
  bookedUserAmount?: number;
  currentUserAmount?: number;
  city?: string;
  phoneNumber?: string;
  country?: string;
  employee_id?: string;
  previous_non_working_time: number; //seconds
  last_working_date_id: number;
  already_has_non_working_reason_for_date: boolean;
};

export type AuthResponse = {
  authenticated: boolean;
  id: string;
  company_name: string;
  company_id: string;
  token: string;
  email: string;
  booked_user_amount: number;
  current_user_amount: number;
  city: string;
  phone_number: string;
  country: string;
  employee_id: string;
  feature_flags: string;
  needs_two_factor: boolean;
  previous_non_working_time: number; //seconds
  last_working_date_id: number;
  already_has_non_working_reason_for_date: boolean;
  isLogoutAction: boolean;
  roles: EmployeeRole[];
};

export type Company = {
  companyName: string;
  companyId: string;
  city?: string;
  phoneNumber?: string;
  country?: string;
  bookedUserAmount?: number;
  currentUserAmount?: number;
};

export type User = {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
};

export type Worker = {
  id: string;
  user: User;
  week_hours: number;
  work_days_per_week: number;
};

export type Employee = {
  id: string;
  user: User;
  weekHours: number;
  workHoursPerDay: number;
  company: string;
  departments?: Department[];
  status: 'W' | 'P';
};

export type Department = {
  id: string;
  name: string;
  description: string;
  employees?: Employee[];
};

export type DepartmentEmployeePayloadType = {
  employee: Employee;
  department: Department;
};

export type Project = {
  id: number;
  name: string;
  sub_projects?: Project[];
  jobs?: Job[];
  options?: Project[];
  option_ids?: number[];
  project_pictures: AllPictureIdTypes[];
  is_option?: boolean;
};

export type Job = {
  id: string;
  projects?: Project[];
  name: string;
  duration: Duration;
  description: string;
  step_number: number;
  done_per_project: number;
  department?: Department;
  important_message?: string | null;
  job_pictures?: AllPictureIdTypes[];
};

export enum TookTooLongType {
  jobTookTooLong = 'JobTookTooLong',
  jobTookRegTooLong = 'JobTookRegTooLong',
}

export type NewJob = {
  id: string;
  department?: string;
  project: Project;
  name: string;
  duration?: Duration;
  description: string;
};

export type PreOrderProject = {
  project: Project;
  amount: number;
  options: string[];
};

export type Order = {
  id: string;
  company: Company;
  client_name: string;
  issue_date: string;
  due_date: string;
  order_projects: OrderProject[];
};

export type OrderProject = {
  id: string;
  company: Company;
  order: Order;
  project: Project;
  amount: number;
  done_amount: number;
  order_jobs: OrderJob[];
  options: OrderProject[];
  order_project: OrderProject;
  comment: string;
  summed_time: number;
};

export type OrderJob = {
  id: string;
  company: Company;
  order_project: OrderProject;
  amount: number;
  done_amount: number;
  job: Job;
};

export type SpecialJob = {
  id: string;
  name: string;
  description: string;
  company: Company;
  start: Moment;
  last_start: Moment;
  duration: Duration;
  department?: string;
  employee: Employee;
  status: 'P' | 'R';
  message?: string;
  special_job_pictures?: AllPictureIdTypes[];
  amount?: number;
};

export type RunningSpecialJob = {
  id: string;
  company: Company;
  employee: Employee;
  special_job: SpecialJob;
  start: Moment;
  last_start: Moment;
  end?: Moment;
  duration: Duration;
  status: 'P' | 'R';
  pictures: AllPictureIdTypes[];
};

export type SummedSpecialJob = {
  specialJob: SpecialJob;
  comment?: string;
};

export type Routine = {
  id: string;
  name: string;
  company: Company;
  duration: Duration;
  description: string;
  routine_pictures?: AllPictureIdTypes[];
};

export type RunningRoutine = {
  id: string;
  company: Company;
  employee: Employee;
  routine: Routine;
  start: Moment;
  last_start: Moment;
  duration: Duration;
  status: 'P' | 'R';
  pictures: AllPictureIdTypes[];
};

export type SummedRoutine = {
  routine: Routine;
  done_amount: number;
};

export type SummedProjectOrder = {
  project: Project;
  orderProjects: OrderProject[];
  totalAmount: number;
  minDate: number;
};

export type SummedJob = {
  job: Job;
  order_jobs: OrderJob[];
  summed_amount: number;
  done_amount: number;
};

export type RunningJob = {
  id: string;
  company: Company;
  employee: Employee;
  job: Job;
  start: Moment;
  duration: Duration;
  status: 'P' | 'R';
  last_start: Moment;
  pictures: AllPictureIdTypes[];
};

export type RunningNewJob = {
  id: string;
  company: Company;
  employee: Employee;
  new_job: NewJob;
  start: Moment;
  duration: Duration;
  status: 'P' | 'R';
  last_start: Moment;
  pictures: AllPictureIdTypes[];
};

export enum StampType {
  WORK = 'W',
  PAUSE = 'P',
}

export enum WorkingState {
  WORKING,
  PAUSE,
  NOTHING,
}

export type WorkingHistory = {
  employee: string;
  id: string;
  job: string;
  new_job: string;
  routine: string;
  special_job: string;
  start: number;
  end: number;
  type: StampType;
};

export type TimestampRequest = {
  type: StampType;
  end?: boolean;
};

export type Timestamp = {
  start: number;
  end?: number;
  type: StampType;
  logout_on_leave?: boolean;
};

export type TimestampDate = {
  id: number;
  timestamps?: Timestamp[];
  date: number;
  employee: string;
  non_working_time: number;
  pause_time: number;
  working_time: number;
};

export type Todo = {
  id: string;
  job: Job;
  amount: number;
};

export type CreatableTodo = {
  job_id: number;
  amount: number;
};

export type WeekplanEntry = {
  id: string;
  order_project: OrderProject;
  KW: number;
  year: number;
  amount: number;
  done_amount: number;
  comment: string;
  checked_for_employees: string[];
};

export type ThunkCallback = () => void;

export type ThunkErrorCallback = (error?: Error) => void;

export type RoutineLike = {
  id: string;
  name: string;
  company: Company;
  duration: string;
  description: string;
};

export type RunningJobLike = {
  id: string;
  company: Company;
  employee: Employee;
  job?: Job;
  new_job?: NewJob;
  special_job?: SpecialJob;
  routine?: Routine;
  duration: number;
  status: 'P' | 'R';
  start: string;
  last_start: string;
};

export type AllPictureTypes = {
  id: string;
  picture: string;
  rotation: number;
  running_job: string;
  running_routine: string;
  running_special_job: string;
  running_new_job: string;
  project: string;
  type: PictureType;
};

export type AllPictureIdTypes = {
  id: string;
  running_job: string;
  running_routine: string;
  running_special_job: string;
  running_new_job: string;
  project: string;
  type: PictureType;
};

export type RunningAny =
  | RunningJob
  | RunningNewJob
  | RunningSpecialJob
  | RunningRoutine;

export enum PictureType {
  JOB = 'RJ',
  ROUTINE = 'RR',
  NEWJOB = 'RN',
  SPECIALJOB = 'RS',
  PROJECT = 'PROJECT',
}

export enum ChangeRequestType {
  JOB_STEP_NUMBER = 0,
  DELETE = 1,
  COPY_TO_PROJECT = 2,
  COMBINE_STEPS = 3,
  _ = 4,
}

export type CompanySettings = {
  allow_leave_when_jobs_open: boolean;
  logout_on_leave: boolean;
  max_running_amount: number;
  max_factor: number;
  show_warnings: boolean;
  look_back_duration: number;
  min_times_done: number;
  max_mean_factor: number;
  pause_time: number;
  order_kw_instead_of_date: boolean;
  show_non_working_time_warning: boolean;
  non_working_time_threshold: number;
  non_working_time_threshold_2: number;
};

export interface Role {
  id: string;
  name: string;
  code: RoleCode;
}

export enum RoleCode {
  OVERSEER = 'OVERSEER',
  PROJECT_FINISHER = 'PROJECT_FINISHER',
}

export interface EmployeeRole {
  id: string;
  role: Role;
}

export interface MonthInfo {
  holiday_days: number;
  public_holiday_days: number;
  sick_days: number;
  expected_time: number;
}

export interface ChangeRequestValues {
  job: number; // jobId
  type: ChangeRequestType; // default = 4
  copy_to_project?: number; // project_id; ignored for types: 0,1,3,4    used in:2
  info?: string; // ignored for types: 0-3                               used in:4
  combine_with?: number; // job_id; ignored for types: 0,1,2,4           used in:3
  step_number?: number; // ignored for types 1-4                         used in:0
  text_to_add?: string;
}

export enum AnyType {
  job,
  routine,
  newJob,
  specialJob,
}
