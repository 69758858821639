/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderedProject } from './OrderedProject';
import {
    OrderedProjectFromJSON,
    OrderedProjectFromJSONTyped,
    OrderedProjectToJSON,
    OrderedProjectToJSONTyped,
} from './OrderedProject';

/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    customerName: string;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    dueDate: Date;
    /**
     * 
     * @type {Array<OrderedProject>}
     * @memberof Order
     */
    projects: Array<OrderedProject>;
}

/**
 * Check if a given object implements the Order interface.
 */
export function instanceOfOrder(value: object): value is Order {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('customerName' in value) || value['customerName'] === undefined) return false;
    if (!('dueDate' in value) || value['dueDate'] === undefined) return false;
    if (!('projects' in value) || value['projects'] === undefined) return false;
    return true;
}

export function OrderFromJSON(json: any): Order {
    return OrderFromJSONTyped(json, false);
}

export function OrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Order {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerName': json['customerName'],
        'dueDate': (new Date(json['dueDate'])),
        'projects': ((json['projects'] as Array<any>).map(OrderedProjectFromJSON)),
    };
}

  export function OrderToJSON(json: any): Order {
      return OrderToJSONTyped(json, false);
  }

  export function OrderToJSONTyped(value?: Order | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'customerName': value['customerName'],
        'dueDate': ((value['dueDate']).toISOString().substring(0,10)),
        'projects': ((value['projects'] as Array<any>).map(OrderedProjectToJSON)),
    };
}

