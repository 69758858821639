import {
  IonContent,
  IonFooter,
  IonLoading,
  IonPage,
  useIonToast,
} from '@ionic/react';
import React, { useMemo, useState } from 'react';

import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  Button,
  CancelModal,
  NumberInput,
  PictureViewer,
  TakePhotoButton,
  Textarea,
  TookTooLongModal,
} from '~/components/defaultUIComponents';
import Toolbar from '../components/Toolbar/Toolbar';
import routes from '../constants/routes.json';
import { useTranslate } from '~/i18n/translate';
import { PictureType, TookTooLongType } from '~/types';
import { runningAnySchema } from '~/utils/yup/schemata';
import { useEndRoutine, useRunningRoutine } from '~/api/RoutineApi';
import { useEmployeeId } from '~/state/auth';
import _ from 'lodash';
import { isAxiosError } from 'axios';
import { ApiError } from '~/api/common';
import { useDisclosure } from '~/utils/disclosure';

const RunRoutine: React.FC = () => {
  const t = useTranslate();
  const history = useHistory();
  const employeeId = useEmployeeId();
  const match = useRouteMatch({
    path: routes.RUN_ROUTINE,
    exact: true,
    location: history.location,
  });
  const routineId = Number(
    (match?.params as { routine: string } | undefined)?.routine as
      | string
      | undefined,
  );
  const { data: runningRoutine, isLoading } = useRunningRoutine({
    employeeId,
    routineId,
    enabled: !!employeeId,
  });

  const { mutate: endRunningRoutine } = useEndRoutine({
    onSuccess: () => {
      history.replace(routes.ROUTINES);
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        const res = error.response?.data as ApiError | undefined;
        if (res) {
          if (res.detail === 'error.running_job.took_too_long') {
            setTookTooLongType(TookTooLongType.jobTookTooLong);
            return rejectionModal.open();
          } else if (res.detail === 'error.running_job.took_reg_too_long') {
            setTookTooLongType(TookTooLongType.jobTookRegTooLong);
            return rejectionModal.open();
          }
        }
      }
      void present({
        message: t('error.unknown'),
        duration: 1000,
        color: 'danger',
      });
    },
  });

  const routinePictures = useMemo(
    () =>
      _.concat(
        runningRoutine?.pictures ?? [],
        runningRoutine?.routine?.routine_pictures ?? [],
      ),
    [runningRoutine],
  );

  const cancelModal = useDisclosure();
  const rejectionModal = useDisclosure();
  const [present] = useIonToast();

  const [amount, setAmount] = useState<number>(0);
  const [comment, setComment] = useState('');
  const [tookTookTooLongType, setTookTooLongType] = useState(
    TookTooLongType.jobTookTooLong,
  );
  const [improvement, setImprovement] = useState<string>();
  const [timeSuggestionInSeconds, setTimeSuggestionInSeconds] =
    useState<number>();

  const tookTooLongHeader = useMemo(() => {
    if (tookTookTooLongType === TookTooLongType.jobTookTooLong) {
      return t('runRoutine.routineTookTooLongHeaderText');
    } else if (tookTookTooLongType === TookTooLongType.jobTookRegTooLong) {
      return t('runRoutine.routineTookTooRegLongHeaderText');
    }
    return '';
  }, [tookTookTooLongType, t]);

  const showToastSchemaIsNotValid = (message: string = 'yup.notValid') => {
    void present({
      message: t(message),
      duration: 1000,
      color: 'danger',
    });
  };

  const onModalSubmit = () => {
    runningAnySchema
      .isValid({
        amount,
        comment,
      })
      .then(function (valid) {
        if (valid) {
          endRunningRoutine({
            runningRoutineId: Number(runningRoutine?.id),
            amount,
            comment: comment ?? '',
            improvement_comment: improvement,
            time_suggestion: timeSuggestionInSeconds,
          });
          rejectionModal.close();
        } else showToastSchemaIsNotValid();
      })
      .catch((error) => {
        showToastSchemaIsNotValid(error);
      });
  };

  const onSubmit = (e?: React.FormEvent, cancel: boolean = false) => {
    e?.preventDefault();
    runningAnySchema
      .isValid({
        amount,
        comment,
      })
      .then(function (valid) {
        if (valid || cancel) {
          endRunningRoutine({
            runningRoutineId: Number(runningRoutine?.id),
            amount: cancel ? 0 : amount,
            comment: comment ?? '',
            improvement_comment: improvement,
            time_suggestion: timeSuggestionInSeconds,
          });
        } else showToastSchemaIsNotValid();
      })
      .catch((error) => {
        showToastSchemaIsNotValid(error);
      });
  };

  const handleAmountChange = (value: number) => {
    if (value) {
      setAmount(value);
    }
  };
  const handleCommentChange = (value: string | null | undefined) => {
    if (!value) {
      setComment('');
      return;
    }
    setComment(value.trimLeft());
  };

  return (
    <form onSubmit={onSubmit}>
      <IonPage>
        <Toolbar>{t('runRoutine.toolbar.title')}</Toolbar>
        <IonContent>
          <IonLoading isOpen={isLoading} />
          <TookTooLongModal
            tookTooLongHeader={tookTooLongHeader}
            showModal={rejectionModal.isOpen}
            toggleShowJobTookTooLongModal={rejectionModal.toggle}
            onModalSubmit={onModalSubmit}
            improvement={improvement ?? ''}
            setImprovement={(im) => setImprovement(!im ? undefined : im)}
            improvementLabel="runRoutine.improvementText"
            actualDuration={runningRoutine?.duration as unknown as number}
            demandedDurationPerPiece={
              runningRoutine?.routine.duration as unknown as string
            }
            doneAmount={amount ?? 0}
            tookTooLongType={tookTookTooLongType}
            timeSuggestionInSeconds={timeSuggestionInSeconds ?? 0}
            setTimeSuggestionInSeconds={(time) =>
              setTimeSuggestionInSeconds(!time ? undefined : time)
            }
          />
          <div className="flex flex-col gap-3 px-3 pt-4">
            <NumberInput
              value={amount || undefined}
              onChange={handleAmountChange}
              name="amountInput"
              label="runRoutine.amount"
              placeholder=""
              min="0"
              max="99"
              required
            />
            <Textarea
              value={comment}
              onChange={(val) => handleCommentChange(val)}
              name="commentTextarea"
              label="runRoutine.comment"
              autocorrect="on"
              clearInput
              spellcheck
              rows={3}
            />
            <PictureViewer
              pictureIds={routinePictures}
              pictureType={PictureType.ROUTINE}
              projectOrRunningAnyId={runningRoutine?.id}
            />
          </div>
        </IonContent>
        <IonFooter className="bg-white w-full flex">
          <Button className="min-w-fit" onClick={cancelModal.toggle}>
            runRoutine.cancel
          </Button>
          <Button className="w-full" type="submit">
            runRoutine.end
          </Button>
          {runningRoutine && (
            <TakePhotoButton
              runningAnyId={runningRoutine.id}
              pictureType={PictureType.ROUTINE}
              className="h-14 w-15"
            />
          )}
          <CancelModal
            openModal={cancelModal.isOpen}
            toggleOpenModal={cancelModal.toggle}
            onSubmit={(e) => onSubmit(e, true)}
          />
        </IonFooter>
      </IonPage>
    </form>
  );
};

export default RunRoutine;
