/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectOrder } from './ProjectOrder';
import {
    ProjectOrderFromJSON,
    ProjectOrderFromJSONTyped,
    ProjectOrderToJSON,
    ProjectOrderToJSONTyped,
} from './ProjectOrder';

/**
 * 
 * @export
 * @interface OrderRequest
 */
export interface OrderRequest {
    /**
     * 
     * @type {string}
     * @memberof OrderRequest
     */
    customerName: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderRequest
     */
    dueDate: Date;
    /**
     * 
     * @type {Array<ProjectOrder>}
     * @memberof OrderRequest
     */
    projects: Array<ProjectOrder>;
}

/**
 * Check if a given object implements the OrderRequest interface.
 */
export function instanceOfOrderRequest(value: object): value is OrderRequest {
    if (!('customerName' in value) || value['customerName'] === undefined) return false;
    if (!('dueDate' in value) || value['dueDate'] === undefined) return false;
    if (!('projects' in value) || value['projects'] === undefined) return false;
    return true;
}

export function OrderRequestFromJSON(json: any): OrderRequest {
    return OrderRequestFromJSONTyped(json, false);
}

export function OrderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'customerName': json['customerName'],
        'dueDate': (new Date(json['dueDate'])),
        'projects': ((json['projects'] as Array<any>).map(ProjectOrderFromJSON)),
    };
}

  export function OrderRequestToJSON(json: any): OrderRequest {
      return OrderRequestToJSONTyped(json, false);
  }

  export function OrderRequestToJSONTyped(value?: OrderRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'customerName': value['customerName'],
        'dueDate': ((value['dueDate']).toISOString().substring(0,10)),
        'projects': ((value['projects'] as Array<any>).map(ProjectOrderToJSON)),
    };
}

