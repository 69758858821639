import { IonButton } from '@ionic/react';
import React, { FC, PropsWithChildren } from 'react';
import { useTranslate } from '../../../i18n/translate';

export interface ButtonProps {
  type?: 'button' | 'reset' | 'submit';
  disabled?: boolean;
  expand?: 'block' | 'full';
  onClick?: React.MouseEventHandler<HTMLIonButtonElement>;
  onBlur?: (e: CustomEvent<void>) => void;
  onFocus?: (e: CustomEvent<void>) => void;
  className?: string;
  id?: string;
  style?:
    | {
        [key: string]: any;
      }
    | undefined;
  shape?: 'round' | undefined;
}

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  onBlur,
  onFocus,
  expand = 'block',
  className = '',
  onClick,
  disabled,
  id,
  style,
  shape,
  ...props
}) => {
  const translate = useTranslate();
  return (
    <IonButton
      id={id}
      onIonFocus={onFocus}
      onIonBlur={onBlur}
      expand={expand}
      className={`max-w-full overflow-hidden ${className}`}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      style={style}
      shape={shape}
      {...props}
    >
      {translate(children)}
    </IonButton>
  );
};
