import moment from 'moment-timezone';

export const getMillisecondsFromDateInput = (dateString: string) => {
  const date = new Date(dateString);
  date.setHours(0, 0, 0, 0);
  return date.valueOf();
};

export const zeroDurationReturn = '00min';

export const momentDurationToHMin = (
  durationMoment: moment.Duration | undefined,
) => {
  if (!durationMoment) return zeroDurationReturn;
  const durationString = durationMoment as unknown as string;
  let hourString = durationString.substring(0, 2) + 'h';
  if (hourString === '00h') hourString = '';
  const minutes = durationString.substring(3, 5);
  return hourString + ' ' + minutes + 'min';
};

export interface HoursMinutesSeconds {
  hours: number;
  minutes: number;
  seconds: number;
}

export const secondsToHoursMinutesSeconds = (
  secondsToConvert: number | null | undefined,
): HoursMinutesSeconds => {
  if (typeof secondsToConvert === 'number' && secondsToConvert > 0) {
    const hours = Math.floor(secondsToConvert / 3600);
    const minutes = Math.floor((secondsToConvert % 3600) / 60);
    const seconds = Math.floor((secondsToConvert % 3600) % 60);
    return { hours, minutes, seconds };
  }
  return { hours: 0, minutes: 0, seconds: 0 };
};

export const secondsToHMin = (
  seconds: number | null | undefined,
  forceTwoDigits: boolean,
) => {
  const errorReturn = '0min';
  if (typeof seconds === 'number' && seconds >= 0) {
    const hoursMinutesSeconds = secondsToHoursMinutesSeconds(seconds);
    if (!hoursMinutesSeconds) return errorReturn;
    let returnString = '';
    if (hoursMinutesSeconds.hours != 0) {
      if (hoursMinutesSeconds.hours < 10 && forceTwoDigits) returnString += '0';
      returnString += hoursMinutesSeconds.hours + 'h ';
    }
    if (hoursMinutesSeconds.minutes != 0) {
      if (hoursMinutesSeconds.minutes < 10 && forceTwoDigits)
        returnString += '0';
      returnString += hoursMinutesSeconds.minutes + 'min ';
    }
    if (returnString === '') returnString = errorReturn;
    return returnString;
  } else {
    return errorReturn;
  }
};

export const secondsToHMinS = (
  seconds: number | null | undefined,
  forceTwoDigits: boolean,
) => {
  const errorReturn = '0min';
  if (typeof seconds === 'number' && seconds >= 0) {
    const hoursMinutesSeconds = secondsToHoursMinutesSeconds(seconds);
    if (!hoursMinutesSeconds) return errorReturn;
    const hMin = secondsToHMin(seconds, forceTwoDigits);
    if (hMin === errorReturn) return errorReturn;
    let secondsString = ' ';
    if (hoursMinutesSeconds.seconds < 10 && forceTwoDigits)
      secondsString += '0';
    secondsString += hoursMinutesSeconds.seconds;
    secondsString += 'sek';
    return hMin + secondsString;
  } else {
    return errorReturn;
  }
};

// hh:mm:ss
export const secondsToMomentNotation = (
  seconds: number,
  showSeconds: boolean = true,
) => {
  const errorReturn = '0min';
  const hoursMinutesSeconds = secondsToHoursMinutesSeconds(seconds);
  if (!hoursMinutesSeconds) return errorReturn;
  let returnString = '';
  if (hoursMinutesSeconds.hours < 10) returnString += '0';
  returnString += hoursMinutesSeconds.hours;
  returnString += ':';
  if (hoursMinutesSeconds.minutes < 10) returnString += '0';
  returnString += hoursMinutesSeconds.minutes;
  if (showSeconds) {
    returnString += ':';
    if (hoursMinutesSeconds.seconds < 10) returnString += '0';
    returnString += hoursMinutesSeconds.seconds;
  }
  return returnString;
};

// hh:mm:ss
export const millisecondsToMomentNotation = (
  milliseconds: number,
  showSeconds: boolean = true,
) => {
  const seconds = Math.round(milliseconds / 1000);
  return secondsToMomentNotation(seconds, showSeconds);
};

export const momentNotationToMilliseconds = (momentNotation: string) => {
  if (!momentNotation) {
    return 0;
  }
  const hoursString = momentNotation.substr(0, 2);
  const minutesString = momentNotation.substr(3, 2);
  const secondsString = momentNotation.substr(6, 2);
  const hours = parseInt(hoursString);
  const minutes = parseInt(minutesString);
  const seconds = parseInt(secondsString);

  return hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
};

interface YearMonthDay {
  day: string;
  month: string;
  year: string;
}

export const ISOStringToYearMonthDay = (
  ISOString: string,
  fixDay: boolean = true,
) => {
  const year = ISOString.substring(0, 4);
  const month = ISOString.substring(5, 7);
  //ISOString has the wrong day
  let dayInt = parseInt(ISOString.substring(8, 10), 10);
  if (fixDay) dayInt += 1;
  const day = dayInt.toString();

  return {
    day,
    month,
    year,
  } as YearMonthDay;
};
