import {
  addOutline,
  checkboxOutline,
  chevronUpOutline,
  copyOutline,
  createOutline,
  listOutline,
  refreshOutline,
  settingsOutline,
  timeOutline,
} from 'ionicons/icons';
import routes from './constants/routes.json';
import { FeatureFlag } from './utils/featureFlag';

export interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  featureFlag: string;
}

export const appPages = (hasOverseerRole: boolean | undefined): AppPage[] => {
  const appPages = [
    {
      title: 'menuRoutes.timeTracking',
      url: routes.TIME_TRACKING,
      iosIcon: timeOutline,
      mdIcon: timeOutline,
      featureFlag: FeatureFlag.TIMETRACKING,
    },
    {
      title: 'menuRoutes.orders',
      url: routes.ORDERS,
      iosIcon: listOutline,
      mdIcon: listOutline,
      featureFlag: FeatureFlag.ORDERS,
    },
    {
      title: 'menuRoutes.dayplan',
      url: routes.DAY_PLAN,
      iosIcon: checkboxOutline,
      mdIcon: checkboxOutline,
      featureFlag: FeatureFlag.DAYPLAN,
    },
    {
      title: 'menuRoutes.weekplan',
      url: routes.WEEK_PLAN,
      iosIcon: chevronUpOutline,
      mdIcon: chevronUpOutline,
      featureFlag: FeatureFlag.WEEKPLAN,
    },
    {
      title: 'menuRoutes.routines',
      url: routes.ROUTINES,
      iosIcon: refreshOutline,
      mdIcon: refreshOutline,
      featureFlag: FeatureFlag.ROUTINES,
    },
    {
      title: 'menuRoutes.newJob',
      url: routes.CREATE_JOB,
      iosIcon: addOutline,
      mdIcon: addOutline,
      featureFlag: FeatureFlag.NEWJOBS,
    },
    {
      title: 'menuRoutes.runningJobs',
      url: routes.RUNNING_JOBS,
      iosIcon: copyOutline,
      mdIcon: copyOutline,
      featureFlag: FeatureFlag.RUNNINGJOBS,
    },
    {
      title: 'menuRoutes.specialJobs',
      url: routes.SPECIAL_JOBS,
      iosIcon: createOutline,
      mdIcon: createOutline,
      featureFlag: FeatureFlag.SPECIALJOBS,
    },
    // {
    //   title: 'TEST',
    //   url: routes.RUNNING_JOB,
    //   iosIcon: createOutline,
    //   mdIcon: createOutline,
    //   featureFlag: FeatureFlag.RUNNINGJOBS,
    // },
    {
      title: 'menuRoutes.settings',
      url: routes.SETTINGS,
      iosIcon: settingsOutline,
      mdIcon: settingsOutline,
      featureFlag: FeatureFlag.TIMETRACKING,
    },
  ];

  if (hasOverseerRole) {
    appPages.splice(7, 0, {
      title: 'menuRoutes.jobsColleagues',
      url: routes.JOBS_COLLEAGUES,
      iosIcon: copyOutline,
      mdIcon: copyOutline,
      featureFlag: FeatureFlag.RUNNINGJOBS,
    });
  }

  return appPages;
};
