/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SummedJobOrders,
} from '../models/index';
import {
    SummedJobOrdersFromJSON,
    SummedJobOrdersToJSON,
} from '../models/index';

export interface GetSummedOrderedJobRequest {
    jobId: number;
}

/**
 * 
 */
export class SummedOrderedJobsApi extends runtime.BaseAPI {

    /**
     */
    async getSummedOrderedJobRaw(requestParameters: GetSummedOrderedJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SummedJobOrders>> {
        if (requestParameters['jobId'] == null) {
            throw new runtime.RequiredError(
                'jobId',
                'Required parameter "jobId" was null or undefined when calling getSummedOrderedJob().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orderedjobs/summed/{jobId}`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters['jobId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SummedJobOrdersFromJSON(jsonValue));
    }

    /**
     */
    async getSummedOrderedJob(requestParameters: GetSummedOrderedJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SummedJobOrders> {
        const response = await this.getSummedOrderedJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
