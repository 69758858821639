import { MutationOptions, QueryOptions, unwrap } from './common';
import { CreatableTodo, Todo } from '~/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export function useTodos<T = Todo[]>({
  employeeId,
  ...options
}: QueryOptions<Todo[], T> & { employeeId: number }) {
  const queryClient = useQueryClient();
  return useQuery({
    ...options,
    queryKey: ['todo', 'list'],
    queryFn: async () => {
      const todos = await axios
        .get<Todo[]>('/todos/', { params: { employee_id: employeeId } })
        .then(unwrap);
      for (const todo of todos) {
        queryClient.setQueryData(['todo', todo.id], todo);
      }
      return todos;
    },
  });
}

export function useTodo({
  jobId,
  ...options
}: Omit<QueryOptions<Todo[], Todo | undefined>, 'select'> & {
  jobId: number;
  employeeId: number;
}) {
  return useTodos<Todo | undefined>({
    ...options,
    select: (todos) => todos.find((t) => Number(t.job.id) === jobId),
  });
}

export function useCreateTodos(
  options?: MutationOptions<
    Todo[],
    { todos: CreatableTodo[]; employeeId: number }
  >,
) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: ({ employeeId, todos }) =>
      axios.post('/todo/', {
        employee_id: employeeId,
        todos,
      }),
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({ queryKey: ['todo'] });
      options?.onSuccess?.(...args);
    },
  });
}

export function useDeleteTodo(
  options?: MutationOptions<void, { todoId: number; employeeId: number }>,
) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: ({ todoId, employeeId }) =>
      axios.delete(`/todos/${employeeId}/delete/${todoId}/`, {
        params: { employee_id: employeeId },
      }),
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({ queryKey: ['todo'] });
      options?.onSuccess?.(...args);
    },
  });
}
