import { QueryOptions, unwrap } from '~/api/common';
import { Employee, RunningAny } from '~/types';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export function useEmployees<T = Employee[]>(
  option?: QueryOptions<Employee[], T>,
) {
  return useQuery({
    ...option,
    queryKey: ['employee', 'list'],
    queryFn: () => axios.get<Employee[]>('/company/users/').then(unwrap),
  });
}

export function useJobsOfCollegue<T = RunningAny[]>({
  employeeId,
  ...options
}: QueryOptions<RunningAny[], T> & { employeeId: number }) {
  return useQuery({
    ...options,
    queryKey: ['running_jobs', 'list', employeeId],
    queryFn: () =>
      axios.get<RunningAny[]>(`/running_jobs/all/${employeeId}/`).then(unwrap),
  });
}
