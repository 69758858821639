type CollapseProps = {
  children: React.ReactNode;
  isOpen: boolean;
};

export function Collapse({ children, isOpen }: CollapseProps) {
  if (isOpen) {
    return <>{children}</>;
  }

  return null;
}
