import { IonContent, IonIcon, IonPage } from '@ionic/react';
import React from 'react';
import { FlexRow } from '~/components/defaultUIComponents';
import Toolbar from '../components/Toolbar/Toolbar';
import { useTranslate } from '~/i18n/translate';
import { useUpdateSettings } from '~/api/SettingsApi';
import { useSettingsStore } from '~/state/settings';

const Settings: React.FC = () => {
  const t = useTranslate();
  const settingsStore = useSettingsStore();

  const { mutate: updateSettings } = useUpdateSettings({
    onSuccess: ({ language }) => {
      void settingsStore.setLanguage(language);
    },
  });

  const changeLanguageToDe = () => {
    updateSettings({ language: 'de' });
  };

  const changeLanguageToEn = () => {
    updateSettings({ language: 'en' });
  };

  return (
    <IonPage>
      <Toolbar>{t('settings.toolbar')}</Toolbar>
      <IonContent>
        <FlexRow justifyContent="evenly">
          <IonIcon
            src="assets/flagIcons/de.svg"
            onClick={changeLanguageToDe}
            style={{ fontSize: '64px' }}
          />
          <IonIcon
            src="assets/flagIcons/gb.svg"
            onClick={changeLanguageToEn}
            style={{ fontSize: '64px' }}
          />
        </FlexRow>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
