import { IonContent, IonLoading, IonPage } from '@ionic/react';
import React, { useState } from 'react';

import {
  FlexColumn,
  ListView,
  SearchField,
} from '../components/defaultUIComponents';
import JobTile from '../components/JobTile';
import Toolbar from '../components/Toolbar/Toolbar';
import { useTranslate } from '../i18n/translate';
import { useTodos } from '../api/DayplanApi';
import { useEmployeeId } from '~/state/auth';

const Dayplan: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const translate = useTranslate();
  const employeeId = useEmployeeId();
  const { data: todos = [], isLoading } = useTodos({ employeeId });

  return (
    <IonPage>
      <Toolbar>{translate('dayplan.toolbar')}</Toolbar>
      <IonContent>
        <IonLoading isOpen={isLoading} />
        <FlexColumn className="p-3">
          <SearchField onInputChange={setSearchTerm} />
          <ListView
            data={todos}
            search={searchTerm}
            searchFields={(todo) => [todo.job.name]}
          >
            {({ data }) => (
              <>
                {data.map((todo) => (
                  <JobTile
                    key={todo.id}
                    jobId={Number(todo.job.id)}
                    parentPage="Dayplan"
                  />
                ))}
              </>
            )}
          </ListView>
        </FlexColumn>
      </IonContent>
    </IonPage>
  );
};

export default Dayplan;
