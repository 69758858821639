import { useState } from 'react';

export function useDisclosure() {
  const [open, setOpen] = useState(false);
  return {
    isOpen: open,
    open: () => setOpen(true),
    close: () => setOpen(false),
    toggle: () => setOpen((open) => !open),
  };
}
