import { IonCard, IonCardContent, IonCardHeader, IonItem } from '@ionic/react';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslate } from '~/i18n/translate';
import { Collapse, FlexRow, Option, Select, Text } from './defaultUIComponents';
import { CollapseButton } from './defaultUIComponents/Button/CollapseButton';
import JobTile from './JobTile';
import { useProject } from '~/api/ProjectApi';
import { useDepartments } from '~/api/DepartmentApi';

type Props = {
  projectId?: number;
  hasOptions?: boolean;
  isOption?: boolean;
};

const JobsInProject: React.FC<Props> = ({
  projectId,
  hasOptions = false,
  isOption = false,
}) => {
  const translate = useTranslate();

  const { data: departments } = useDepartments({
    select: (departments) => _.sortBy(departments, (d) => d.name.toLowerCase()),
  });

  const { data: project } = useProject({
    projectId: projectId ?? -1,
    enabled: !!projectId,
  });

  const [open, setOpen] = useState(false);

  const [selectedDepartmentName, setSelectedDepartmentName] =
    useState<string>();

  const jobs = useMemo(() => {
    const jobs = _.orderBy(
      project?.jobs?.filter(
        (job) => !!departments?.find((d) => job.department?.id == d.id),
      ),
      (job) => job.step_number,
    );

    if (!selectedDepartmentName) {
      return jobs;
    }
    return jobs.filter((j) => j.department?.name === selectedDepartmentName);
  }, [project, departments, selectedDepartmentName]);

  const headerName = useMemo(() => {
    if (!isOption) {
      return translate('jobsInOrder.jobs');
    } else {
      return `${translate('optionsInProject.option')} ${project?.name}`;
    }
  }, [project, isOption]);

  return (
    <IonCard className="small outer-card">
      <IonCardHeader>
        <IonItem>
          <FlexRow justifyContent="between" alignContent="end">
            <Text textSize="2xl" className="">
              {headerName}
            </Text>
            {hasOptions ? (
              <Text textSize="lg">
                {translate('jobsInProject.thereAreOptions')}
              </Text>
            ) : null}
            <CollapseButton
              isOpen={open}
              toggleIsOpen={() => setOpen((open) => !open)}
            />
          </FlexRow>
        </IonItem>
        {open && (
          <Select
            placeholder={
              selectedDepartmentName === ''
                ? 'select.placeholder'
                : selectedDepartmentName
            }
            label="jobsInProject.select.label"
            name="departmentFilter"
            onChange={(e) => setSelectedDepartmentName(e)}
            value={selectedDepartmentName}
          >
            {departments?.map((d, index) => (
              <Option key={index} value={d.name}>
                {d.name}
              </Option>
            ))}
          </Select>
        )}
      </IonCardHeader>
      <Collapse isOpen={open}>
        <IonCardContent className="px-3">
          {jobs.map((job) => (
            <JobTile
              key={job.id}
              jobId={Number(job.id)}
              parentPage="Order"
              option={project}
            />
          ))}
        </IonCardContent>
      </Collapse>
    </IonCard>
  );
};

export default JobsInProject;
