import { IonInput } from '@ionic/react';
import React from 'react';
import { useTranslate } from '../../../i18n/translate';
import { BaseInputProps } from './types';

interface NumberInputProps extends BaseInputProps {
  value?: number | null | undefined;
  onChange(value: number): void;
  inputmode?: 'decimal' | 'numeric';
  max?: string;
  min?: string;
  maxlength?: number;
  minlength?: number;
  step?: string;
  row?: boolean;
}

export const NumberInput: React.FC<NumberInputProps> = ({
  onChange,
  onBlur,
  onFocus,
  label,
  placeholder,
  inputmode = 'numeric',
  row = false,
  value,
  ...props
}) => {
  const translate = useTranslate();
  return (
    <IonInput
      style={{
        color: '#000',
      }}
      type="number"
      placeholder={translate(placeholder)}
      onIonFocus={onFocus}
      onIonBlur={onBlur}
      onIonInput={(e) =>
        onChange(
          inputmode === 'numeric'
            ? parseInt(e.detail.value || '0')
            : parseFloat(e.detail.value || '0'),
        )
      }
      inputmode={inputmode}
      value={value ?? 0}
      fill="outline"
      label={translate(label)}
      labelPlacement="floating"
      {...props}
    />
  );
};
