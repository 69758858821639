import { IonCard, IonCardContent, IonItem } from '@ionic/react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Collapse,
  FlexColumn,
  FlexRow,
  Text,
} from '../../components/defaultUIComponents';
import { CollapseButton } from '../../components/defaultUIComponents/Button/CollapseButton';
import { TimestampDate } from '../../types';
import {
  useDateToLocaleString,
  useTimeToLocaleString,
} from '../../utils/dateUtil';
import { secondsToHMin } from '../../utils/timeUtils';
import { useWorkTypeToString } from '../../utils/workTypeUtil';

interface DayProps {
  date: TimestampDate;
  openCollapseId: number | null | undefined;
  setOpenCollapseId: React.Dispatch<React.SetStateAction<number | undefined>>;
}
export const Day: React.FC<DayProps> = ({
  date,
  openCollapseId,
  setOpenCollapseId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dateToLocaleString = useDateToLocaleString();
  const timeToLocaleString = useTimeToLocaleString();
  const workTypeToString = useWorkTypeToString();

  const toggleIsOpen = () => {
    setOpenCollapseId(date.id);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (openCollapseId !== date.id) setIsOpen(false);
  }, [openCollapseId, date]);

  return (
    <IonCard key={new Date(date.date).toISOString()}>
      <FlexColumn className="px-4">
        <FlexRow justifyContent="between">
          <Text textSize="2xl" color="black">
            {dateToLocaleString(new Date(date.date))}
          </Text>
          <CollapseButton
            className="-mr-1"
            isOpen={isOpen}
            toggleIsOpen={toggleIsOpen}
          />
        </FlexRow>
        <FlexRow justifyContent="between">
          <Text>timeTracking.workingTime</Text>
          <Text>{secondsToHMin(date.working_time, true)}</Text>
        </FlexRow>
        <FlexRow justifyContent="between">
          <Text>timeTracking.pauseTime</Text>
          <Text>{secondsToHMin(date.pause_time, true)}</Text>
        </FlexRow>
        <FlexRow justifyContent="between">
          <Text>timeTracking.nonWorkingTime</Text>
          <Text>{secondsToHMin(date.non_working_time, true)}</Text>
        </FlexRow>
      </FlexColumn>
      <Collapse isOpen={isOpen}>
        <IonCardContent>
          {_.orderBy(date.timestamps, (stamp) => stamp.start)?.map(
            (stamp, index) => {
              return (
                <FlexRow key={stamp.start} justifyContent="between">
                  <Text>{workTypeToString(stamp.type)}</Text>
                  <Text>{`${timeToLocaleString(
                    new Date(stamp.start),
                  )} - ${timeToLocaleString(stamp?.end ?? new Date())}`}</Text>
                </FlexRow>
              );
            },
          )}
        </IonCardContent>
      </Collapse>
    </IonCard>
  );
};
