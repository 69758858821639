import { IonLabel, IonText } from '@ionic/react';
import React from 'react';
import { useTranslate } from '../../../i18n/translate';

export interface LabelProps {
  className?: string;
  position?: 'fixed' | 'floating' | 'stacked';
  children: React.ReactNode;
}

export const Label: React.FC<LabelProps> = ({
  children,
  className = '',
  ...props
}) => {
  const translate = useTranslate();
  return (
    <IonText
      className={`text-base lg:text-2xl text-gray-800 ${className} `}
      {...props}
    >
      {translate(children)}
    </IonText>
  );
};
