import * as dateFns from 'date-fns';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import settings from '../constants/settings.json';
import { Language } from '../i18n/LanguageSpec';
import { useSettingsStore } from '../state/settings';

const toDate = (d: Moment | Date | number | string | null | undefined) => {
  if (!d) {
    return null;
  }
  if (typeof d === 'number' || typeof d === 'string') {
    return new Date(d);
  }
  return d instanceof Date ? d : d.toDate();
};

export const dateToLocaleString = (
  date: Moment | Date | null | undefined | string,
  language: Language,
) => {
  return toDate(date)?.toLocaleDateString(language, {
    weekday: 'short',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });
};

export function useDateToLocaleString() {
  const language = useSettingsStore((store) => store.language);
  return (date: Moment | Date | null | undefined | string) =>
    dateToLocaleString(date, language);
}

export const timeToLocaleString = (
  time: Moment | Date | number | null | undefined | string,
  language: Language,
) => {
  return toDate(time)?.toLocaleTimeString(language, {
    hour: 'numeric',
    minute: 'numeric',
  });
};

export function useTimeToLocaleString() {
  const language = useSettingsStore((store) => store.language);
  return (time: Moment | Date | number | null | undefined | string) =>
    timeToLocaleString(time, language);
}

export function parseAnyToMoment<T extends { [key: string]: any }>(obj: T): T {
  for (const key of Object.keys(obj)) {
    if (['start', 'end', 'lastStart'].includes(key)) {
      _.set(obj, key, moment(obj[key]));
    }
    if (['duration'].includes(key)) {
      _.set(obj, key, moment.duration(obj[key]));
    }
  }
  return obj;
}

export function toApiFormat(date: Date) {
  return dateFns.format(date, settings.dateFnsApiDateFormat);
}
