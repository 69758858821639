import { Job, RunningAny, RunningJob } from '~/types';
import { MutationOptions, QueryOptions, unwrap } from './common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import {
  isRunningJob,
  isRunningNewJob,
  isRunningRoutine,
  isRunningSpecialJob,
} from '~/utils/runningAny';

export function useJob<T = Job>({
  jobId,
  ...options
}: QueryOptions<Job, T> & { jobId: number }) {
  return useQuery({
    ...options,
    queryKey: ['job', jobId],
    queryFn: () => axios.get<Job>(`/job/${jobId}/`).then(unwrap),
  });
}

export function useStartJob(
  options?: MutationOptions<RunningJob, { jobId: number }>,
) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: ({ jobId }) => axios.post(`/start_job/${jobId}/`).then(unwrap),
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['running'],
      });
      void queryClient.invalidateQueries({
        queryKey: ['dates'],
      });
      options?.onSuccess?.(...args);
    },
  });
}

export function useRunningAnys<T = RunningAny[]>({
  employeeId,
  ...options
}: QueryOptions<RunningAny[], T> & { employeeId: number }) {
  return useQuery({
    ...options,
    queryKey: [
      'running',
      'job',
      'routine',
      'new',
      'special',
      'any',
      employeeId,
    ],
    queryFn: () => axios.get(`/running_jobs/all/${employeeId}/`).then(unwrap),
  });
}

/**
 * Gets the current running job. Will redirect to the running jobs page if there is no running job.
 * @param options
 */
export function useRunningJob(
  options: Omit<QueryOptions<RunningAny[], RunningJob>, 'select'> & {
    employeeId: number;
  },
) {
  return useRunningAnys({
    ...options,
    select: (data) => {
      const ra = data.find((ra) => ra.status === 'R');
      if (!ra || !isRunningJob(ra)) {
        return undefined;
      }
      return ra;
    },
  });
}

export function usePauseRunningAny(
  options?: MutationOptions<RunningAny, { runningAny: RunningAny }>,
) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: ({ runningAny }) => {
      if (isRunningJob(runningAny)) {
        return axios.patch(`/pause_running_job/${runningAny.id}/`).then(unwrap);
      } else if (isRunningNewJob(runningAny)) {
        return axios.patch(`/pause_new_job/${runningAny.id}/`).then(unwrap);
      } else if (isRunningRoutine(runningAny)) {
        return axios.patch(`/pause_routine/${runningAny.id}/`).then(unwrap);
      } else if (isRunningSpecialJob(runningAny)) {
        return axios.patch(`/pause_special_job/${runningAny.id}/`).then(unwrap);
      } else {
        throw new Error('Unknown runningAny type');
      }
    },
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['running'],
      });
      options?.onSuccess?.(...args);
    },
  });
}

export function useEndJob(
  options?: MutationOptions<
    RunningJob,
    {
      runningJobId: number;
      amount: number;
      comment?: string;
      improvement_comment?: string;
      time_suggestion?: number;
    }
  >,
) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: ({ runningJobId, ...req }) =>
      axios.post(`/job_end/${runningJobId}/`, req).then(unwrap),
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['running'],
      });
      options?.onSuccess?.(...args);
    },
  });
}
