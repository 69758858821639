import moment, { Duration, Moment } from 'moment-timezone';
import { Duration as DateFnsDuration } from 'date-fns';

export const durationToString = (duration: Duration): string => {
  duration = getDurationInCorrectType(duration);
  if (!duration.isValid()) duration = moment.duration(0, 'seconds');
  return moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
};

export const getDurationInCorrectType = (
  duration: number | Duration,
): Duration => {
  if (typeof duration === 'number') return numberToDuration(duration);
  if (typeof duration === 'string') return stringToDuration(duration);
  return duration;
};

export const numberToDuration = (durationAsNumber: number): Duration => {
  return moment.duration({ seconds: durationAsNumber });
};

export const stringToDuration = (durationAsString: string): Duration => {
  return numberToDuration(parseInt(durationAsString));
};

export const durationToSeconds = (duration: Duration): number =>
  Math.floor(duration.asSeconds());

export const durationFromTimeStamp = (m: Moment): Duration =>
  moment.duration(moment().diff(m));

export const durationSecondsFromTimeStamp = (m: Moment): number =>
  moment().diff(m, 'seconds');

export const dateFnsDurationToString = (duration: DateFnsDuration) => {
  let hours: number | string =
    (duration.hours ?? 0) + (duration.days ?? 0) * 24;
  if (hours < 10) {
    hours = `0${hours}`;
  }
  const minutes =
    (duration.minutes ?? 0) < 10 ? `0${duration.minutes}` : duration.minutes;
  const seconds =
    (duration.seconds ?? 0) < 10 ? `0${duration.seconds}` : duration.seconds;

  return `${hours}:${minutes}:${seconds}`;
};
