/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  JobDoneRequest,
  SummedJobOrders,
} from '../models/index';
import {
    JobDoneRequestFromJSON,
    JobDoneRequestToJSON,
    SummedJobOrdersFromJSON,
    SummedJobOrdersToJSON,
} from '../models/index';

export interface JobDoneOperationRequest {
    jobId: number;
    jobDoneRequest: JobDoneRequest;
}

/**
 * 
 */
export class OrderedJobsApi extends runtime.BaseAPI {

    /**
     */
    async jobDoneRaw(requestParameters: JobDoneOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SummedJobOrders>> {
        if (requestParameters['jobId'] == null) {
            throw new runtime.RequiredError(
                'jobId',
                'Required parameter "jobId" was null or undefined when calling jobDone().'
            );
        }

        if (requestParameters['jobDoneRequest'] == null) {
            throw new runtime.RequiredError(
                'jobDoneRequest',
                'Required parameter "jobDoneRequest" was null or undefined when calling jobDone().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orderedjobs/{jobId}/done`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters['jobId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JobDoneRequestToJSON(requestParameters['jobDoneRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SummedJobOrdersFromJSON(jsonValue));
    }

    /**
     */
    async jobDone(requestParameters: JobDoneOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SummedJobOrders> {
        const response = await this.jobDoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
