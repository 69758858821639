import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { ChangeRequestType, Job } from '~/types';
import { Option, Select, Textarea } from '../Input';
import { ModalSelect } from '../Input/ModalSelect';
import { FlexColumn } from '../Layout';
import { Text } from '../Typography';
import { Modal } from './Modal';
import { useJob } from '~/api/JobApi';
import { useProject, useProjects } from '~/api/ProjectApi';
import { useCreateChangeRequest } from '~/api/ChangeRequestApi';

interface InfoInputProps {
  info: string | undefined;
  setInfo: (value: string) => void;
  label?: string;
}

const InfoInput: React.FC<InfoInputProps> = ({
  info,
  setInfo,
  label = 'changeRequestModal.info.label',
}) => {
  const setInfoOnChange = (value: string | null | undefined) => {
    if (value || value === '') setInfo(value);
  };
  return (
    <>
      <Text className="text-xl">{label}</Text>
      <Textarea
        value={info}
        onChange={setInfoOnChange}
        name="info-textarea"
        label=""
        placeholder=""
        autoGrow
      />
    </>
  );
};

interface ChangeRequestModalProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
  jobId: number;
}

const changeRequestTypes = Object.values(ChangeRequestType).slice(
  0,
  Object.values(ChangeRequestType).length / 2,
) as string[];

export const ChangeRequestModal: React.FC<ChangeRequestModalProps> = ({
  isOpen,
  toggleIsOpen,
  jobId,
}) => {
  const { data: job } = useJob({ jobId, enabled: isOpen });

  const { mutate: createChangeRequest } = useCreateChangeRequest({
    onSuccess() {
      toggleIsOpen();
    },
  });

  const [chosenChangeRequestType, setChosenChangeRequestType] =
    useState<string>();

  const [stepNumber, setStepnumber] = useState<number>();
  const [copyToProject, setCopyToProject] = useState<string>();
  const [combineWith, setCombineWith] = useState<string>();
  const [info, setInfo] = useState<string>();

  const onChange = (value: string | string[] | null | undefined) => {
    if (value) setChosenChangeRequestType(value as string);
  };

  const disabledSubmitButton = useMemo(() => {
    if (
      chosenChangeRequestType ===
        changeRequestTypes[ChangeRequestType.JOB_STEP_NUMBER] &&
      stepNumber
    ) {
      return false;
    } else if (
      chosenChangeRequestType ===
        changeRequestTypes[ChangeRequestType.DELETE] &&
      info &&
      info.trim().length > 0
    ) {
      return false;
    } else if (
      chosenChangeRequestType ===
        changeRequestTypes[ChangeRequestType.COPY_TO_PROJECT] &&
      copyToProject
    ) {
      return false;
    } else
      return !(
        chosenChangeRequestType ===
          changeRequestTypes[ChangeRequestType.COMBINE_STEPS] &&
        combineWith &&
        info &&
        info.trim().length > 0
      );
  }, [chosenChangeRequestType, stepNumber, copyToProject, combineWith, info]);

  const onSubmit = () => {
    const type = Object.values(ChangeRequestType).findIndex(
      (crt) => crt === chosenChangeRequestType,
    );

    createChangeRequest({
      job: job ? parseInt(job.id) : 0,
      type,
      copy_to_project: copyToProject ? parseInt(copyToProject) : undefined,
      info,
      combine_with: combineWith ? parseInt(combineWith) : undefined,
      step_number: stepNumber,
    });
  };

  return (
    <Modal
      headerText={job?.name || ''}
      submitText="changeRequestModal.submitButton.text"
      isOpen={isOpen}
      toggleOpen={toggleIsOpen}
      onSubmit={onSubmit}
      showCancelButton
      disabledSubmitButton={disabledSubmitButton}
    >
      <FlexColumn className="mt-4 px-4">
        <Text className="text-xl">{`Aktueller Schritt: ${job?.step_number}`}</Text>
        <Text className="text-lg mb-4">{job?.name}</Text>
        <Text className="text-xl">changeRequestModal.chooseType</Text>
        <ModalSelect
          data={changeRequestTypes?.filter((type) => type !== '_')}
          labels={(crt) => `changeRequestModal.type.${crt}`}
          values={(crt) => crt}
          onSelect={(crt) => onChange(crt)}
          value={chosenChangeRequestType}
        />

        <ChangeRequestModalContent
          job={job}
          chosenChangeRequestType={chosenChangeRequestType}
          isOpen={isOpen}
          setStepnumber={setStepnumber}
          stepNumber={stepNumber}
          info={info}
          setInfo={setInfo}
          combineWith={combineWith}
          setCombineWith={setCombineWith}
          copyToProject={copyToProject}
          setCopyToProject={setCopyToProject}
        />
      </FlexColumn>
    </Modal>
  );
};

function ChangeRequestModalContent({
  job,
  isOpen,
  chosenChangeRequestType,
  setStepnumber,
  stepNumber,
  info,
  setInfo,
  combineWith,
  setCombineWith,
  copyToProject,
  setCopyToProject,
}: {
  job?: Job;
  isOpen: boolean;
  chosenChangeRequestType?: string;
  setStepnumber: (value: number) => void;
  stepNumber?: number;
  info?: string;
  setInfo: (value: string) => void;
  combineWith?: string;
  setCombineWith: (value: string) => void;
  copyToProject?: string;
  setCopyToProject: (value: string) => void;
}) {
  const { data: projects } = useProjects({ enabled: isOpen });
  const { data: projectOfJob } = useProject({
    projectId: Number(job?.projects?.[0].id),
    enabled: !!Number(job?.projects?.[0].id) && isOpen,
  });

  if (
    chosenChangeRequestType ===
    changeRequestTypes[ChangeRequestType.JOB_STEP_NUMBER]
  ) {
    const filteredJobs = projectOfJob?.jobs?.filter((j) => j.id != job?.id);

    const setStepnumberOnChange = (
      value: string | string[] | null | undefined,
    ) => {
      if (value) {
        setStepnumber(parseInt(value as string));
      }
    };

    return (
      <>
        <Text className="text-xl">changeRequestModal.stepNumber.label</Text>
        <Select
          placeholder="select.placeholder.chooser"
          value={stepNumber?.toString()}
          label=""
          name=""
          onChange={setStepnumberOnChange}
          showDefaultValue={false}
        >
          {_.orderBy(filteredJobs, 'step_number')?.map((job, index) => (
            <Option value={job.step_number.toString()} key={index}>
              {job.step_number}
            </Option>
          ))}
        </Select>
        <InfoInput info={info} setInfo={setInfo} />
      </>
    );
  } else if (
    chosenChangeRequestType === changeRequestTypes[ChangeRequestType.DELETE]
  ) {
    return <InfoInput info={info} setInfo={setInfo} />;
  } else if (
    chosenChangeRequestType ===
    changeRequestTypes[ChangeRequestType.COPY_TO_PROJECT]
  ) {
    const filteredProjects = projects?.filter((p) => p.id !== projectOfJob?.id);

    const setCopyToProjectOnChange = (
      id: string | string[] | null | undefined,
    ) => {
      if (id) {
        setCopyToProject(id as string);
      }
    };

    return (
      <>
        <Text>changeRequestModal.copyToProject.label</Text>
        <Select
          placeholder="select.placeholder.chooser"
          value={copyToProject}
          label=""
          name="copyToProject-select"
          onChange={setCopyToProjectOnChange}
          showDefaultValue={false}
        >
          {filteredProjects?.map((p, index) => (
            <Option value={p.id} key={index}>
              {p.name}
            </Option>
          ))}
        </Select>
      </>
    );
  } else if (
    chosenChangeRequestType ===
    changeRequestTypes[ChangeRequestType.COMBINE_STEPS]
  ) {
    const filteredJobs = projectOfJob?.jobs?.filter((j) => j.id != job?.id);

    const setCombineWithOnChange = (
      jobId: string | string[] | null | undefined,
    ) => {
      if (jobId) {
        setCombineWith(jobId as string);
      }
    };

    return (
      <>
        <Text>changeRequestModal.combineWith.label</Text>
        <Select
          placeholder="select.placeholder.chooser"
          label=""
          name="combineWith-select"
          onChange={setCombineWithOnChange}
          showDefaultValue={false}
          value={combineWith}
        >
          {_.orderBy(filteredJobs, 'step_number')?.map((j, index) => (
            <Option value={j.id} key={index}>
              {`${j.step_number}: ${j.name}`}
            </Option>
          ))}
        </Select>
        <InfoInput info={info} setInfo={setInfo} />
      </>
    );
  } else return null;
}
