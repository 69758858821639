import React from 'react';
import { useTranslate } from '../../../i18n/translate';
import { Label, LabelProps } from '../Label';
import { Flex } from '../Layout/Flex';
import { Text } from './Text';
import { BaseTextProps } from './types';

interface LabeledTextProps {
  label: string;
  labelProps?: LabelProps;
  textProps?: BaseTextProps;
  className?: string;
  classNameLabel?: string;
  position?: 'stacked' | 'fixed';
  mrIn?: number;
  children: React.ReactNode;
}

export const LabeledText: React.FC<LabeledTextProps> = ({
  children,
  label,
  labelProps,
  className = '',
  classNameLabel = '',
  textProps,
  position = 'stacked',
  mrIn = 0,
  ...props
}) => {
  const translate = useTranslate();
  return (
    <Flex
      direction={position === 'stacked' ? 'column' : 'row'}
      justifyContent="between"
      {...props}
    >
      <Label position={position} {...labelProps} className={classNameLabel}>
        {translate(label)}
      </Label>
      <Text
        className={`lg:mt-2 ${className}`}
        style={mrIn > 0 ? { marginRight: `${mrIn}px` } : {}}
        {...textProps}
      >
        {children}
      </Text>
    </Flex>
  );
};
