import { create } from 'zustand';
import { Language } from '../i18n/LanguageSpec';
import { Preferences } from '@capacitor/preferences';

type SettingsStore = {
  language: Language;
  setLanguage: (language: Language) => Promise<void>;
  fetchLanguage: () => Promise<void>;
};

export const useSettingsStore = create<SettingsStore>((set) => ({
  language: 'de' as Language,
  setLanguage: async (language: Language) => {
    set({ language });
    await Preferences.set({ key: 'language', value: language });
  },
  fetchLanguage: async () => {
    const language: Language =
      ((await Preferences.get({ key: 'language' })).value as Language | null) ??
      'de';
    set({ language });
  },
}));
