/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectOrder
 */
export interface ProjectOrder {
    /**
     * 
     * @type {number}
     * @memberof ProjectOrder
     */
    projectId: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectOrder
     */
    amount: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProjectOrder
     */
    options: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ProjectOrder
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectOrder
     */
    priority: number;
}

/**
 * Check if a given object implements the ProjectOrder interface.
 */
export function instanceOfProjectOrder(value: object): value is ProjectOrder {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('options' in value) || value['options'] === undefined) return false;
    if (!('priority' in value) || value['priority'] === undefined) return false;
    return true;
}

export function ProjectOrderFromJSON(json: any): ProjectOrder {
    return ProjectOrderFromJSONTyped(json, false);
}

export function ProjectOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectOrder {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['projectId'],
        'amount': json['amount'],
        'options': json['options'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'priority': json['priority'],
    };
}

  export function ProjectOrderToJSON(json: any): ProjectOrder {
      return ProjectOrderToJSONTyped(json, false);
  }

  export function ProjectOrderToJSONTyped(value?: ProjectOrder | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'projectId': value['projectId'],
        'amount': value['amount'],
        'options': value['options'],
        'comment': value['comment'],
        'priority': value['priority'],
    };
}

