import React from 'react';
import { FlexColumn } from '../Layout';
import { Modal } from './Modal';
import { Text } from '../Typography';

interface CancelModalProps {
  openModal: boolean;
  toggleOpenModal: () => void;
  onSubmit: (e?: React.FormEvent, cancel?: boolean) => void;
  disabled?: boolean;
}

export const CancelModal: React.FC<CancelModalProps> = ({
  openModal,
  toggleOpenModal,
  onSubmit,
  disabled,
}) => {
  return (
    <Modal
      headerText=""
      submitText="modal.abort.yes"
      cancelText="modal.abort.no"
      isOpen={openModal}
      toggleOpen={toggleOpenModal}
      onSubmit={() => onSubmit(undefined, true)}
      showCancelButton
      showButtonsInFooter={false}
      disabledSubmitButton={disabled}
    >
      <FlexColumn className="mt-40 mb-4">
        <Text className="text-xl">runAny.cancel.confirm</Text>
      </FlexColumn>
    </Modal>
  );
};
