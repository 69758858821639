import React, { useMemo } from 'react';
import routes from '../../constants/routes.json';
import { useTranslate } from '~/i18n/translate';
import {
  isRunningJob,
  isRunningNewJob,
  isRunningRoutine,
  isRunningSpecialJob,
} from '~/utils/runningAny';
import { RunningAny, WorkingState } from '~/types';
import { DefaultLink, FlexRow, Text } from '../defaultUIComponents';
import {
  useCurrentTimstStamp,
  useDates,
  useWorkingState,
} from '~/api/TimetrackingApi';
import { useTimers } from '~/pages/TimeTracking/TimeTracking';
import { useSummedOrderedProjects } from '~/api/OrderingApi';

interface RenderRunningAnyProps {
  runningAny: RunningAny | undefined;
}

export const RenderRunningAny: React.FC<RenderRunningAnyProps> = ({
  runningAny,
}) => {
  const { data: orders } = useSummedOrderedProjects();
  const projects = useMemo(() => orders?.map((o) => o.project), [orders]);
  const { data: dates = [] } = useDates();
  const { data: workingState = WorkingState.NOTHING } = useWorkingState();
  const { data: currentTimeStamp } = useCurrentTimstStamp();

  const translate = useTranslate();

  const workingStateText = useMemo(() => {
    if (workingState === WorkingState.WORKING) {
      return 'toolbar.nonWorkingTime';
    } else if (workingState === WorkingState.PAUSE) {
      return 'toolbar.pauseTime';
    } else return '';
  }, [workingState]);

  const runningAnyName = useMemo(() => {
    if (!runningAny) return '';
    if (isRunningJob(runningAny)) {
      const runningJob = runningAny;
      if (
        runningJob.job &&
        runningJob.job.projects &&
        runningJob.job.projects.length > 0
      ) {
        const projectId = runningJob.job?.projects[0] as unknown as string;
        const project = projects?.find((p) => p.id == Number(projectId));
        return `${project ? project.name : ''} ${translate(
          'jobTile.stepNumber',
        )} ${runningJob.job.step_number}`;
      }
      return runningJob.job.name;
    } else if (isRunningNewJob(runningAny)) {
      return runningAny.new_job.name;
    } else if (isRunningRoutine(runningAny)) {
      return runningAny.routine.name;
    } else if (isRunningSpecialJob(runningAny)) {
      const specialJob = runningAny.special_job;
      if (specialJob.name) return specialJob.name;
      else return specialJob.description.substring(0, 30);
    } else return '';
  }, [runningAny, projects]);

  const { currentDurationPause } = useTimers(
    dates,
    currentTimeStamp,
    workingState,
  );

  const runningAnyRoute = useMemo(() => {
    if (!runningAny) return '';
    if (isRunningJob(runningAny)) {
      return routes.RUN_JOB.replace(':id', runningAny.job.id);
    } else if (isRunningNewJob(runningAny)) {
      return routes.RUN_NEW_JOB.replace(':new_job', runningAny.new_job.id);
    } else if (isRunningRoutine(runningAny)) {
      return routes.RUN_ROUTINE.replace(':routine', runningAny.routine.id);
    } else if (isRunningSpecialJob(runningAny)) {
      return routes.RUN_SPECIAL_JOBS.replace(
        ':specialJob',
        runningAny.special_job.id,
      );
    } else return '';
  }, [runningAny]);

  if (!runningAny)
    return (
      <>
        {workingState === WorkingState.PAUSE && (
          <FlexRow>
            <Text className="ml-4 mr-3 mt-1 w-15">{currentDurationPause}</Text>
            <Text className="text-xl mb-2 text-red-500">
              {workingStateText}
            </Text>
          </FlexRow>
        )}
      </>
    );
  return (
    <FlexRow>
      <FlexRow className="pt-1 px-2">
        <DefaultLink to={runningAnyRoute}>{runningAnyName}</DefaultLink>
      </FlexRow>
    </FlexRow>
  );
};
