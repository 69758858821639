import { IonAlert, IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslate } from '../../i18n/translate';
import { Camera as CapCam } from '@capacitor/camera';

export const Camera: FC<PropsWithChildren> = ({ children }) => {
  const [showAlert, setShowAlert] = useState(false);
  const translate = useTranslate();
  const cameraIsAvailable = true;
  const [hasPermissions, setHasPermissions] = useState(true);
  const requestPermissions = async () => {
    const permissions = await CapCam.checkPermissions();
    if (permissions.camera === 'denied') {
      const result = await CapCam.requestPermissions({
        permissions: ['camera'],
      });
      if (result.camera === 'denied') {
        setHasPermissions(false);
      } else if (result.camera === 'granted') {
        setHasPermissions(true);
      }
    } else if (permissions.camera === 'granted') {
      setHasPermissions(true);
    }
  };
  useEffect(() => {
    requestPermissions();
  }, []);
  // const cameraIsAvailable =
  //   isPlatform('hybrid') || process.env.NODE_ENV === 'development';
  return (
    <>
      {cameraIsAvailable && hasPermissions ? (
        children
      ) : (
        <>
          <IonFab vertical="bottom" horizontal="center">
            <IonFabButton onClick={() => setShowAlert(true)}>
              <IonIcon icon={informationCircleOutline} />
            </IonFabButton>
          </IonFab>
          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            backdropDismiss={true}
            header={translate('cameraAlertHeader')}
            message={translate('cameraAlertMessage')}
            buttons={['OK']}
          />
        </>
      )}
    </>
  );
};
