import {
  IonContent,
  IonItem,
  IonList,
  IonLoading,
  IonPage,
} from '@ionic/react';
import _ from 'lodash';
import React from 'react';

import { Text } from '~/components/defaultUIComponents';
import SpecialJobTile from '../components/SpecialJobTile';
import Toolbar from '../components/Toolbar/Toolbar';
import { useTranslate } from '~/i18n/translate';
import { useSpecialJobs } from '~/api/SpecialJobApi';
import { useEmployeeId } from '~/state/auth';

const SpecialJob: React.FC = () => {
  const translate = useTranslate();
  const employeeId = useEmployeeId();
  const { data: specialJobs, isLoading } = useSpecialJobs({ employeeId });

  return (
    <IonPage>
      <Toolbar>{translate('specialJob.toolbar')}</Toolbar>
      <IonContent>
        <IonLoading isOpen={isLoading} />
        {specialJobs && specialJobs.length > 0 ? (
          <IonList>
            {_.orderBy(specialJobs, 'name').map((specialJob) => (
              <SpecialJobTile
                key={specialJob.id}
                specialJob={specialJob}
                isStartable
              />
            ))}
          </IonList>
        ) : (
          <IonItem lines="none">
            <Text textSize="xl">{translate('specialJobs.noSpecialJobs')}</Text>
          </IonItem>
        )}
      </IonContent>
    </IonPage>
  );
};

export default SpecialJob;
