import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonItem,
  IonList,
} from '@ionic/react';
import React, { useMemo, useState } from 'react';
import { useTranslate } from '~/i18n/translate';
import { Collapse, FlexRow, Text } from './defaultUIComponents';
import { CollapseButton } from './defaultUIComponents/Button/CollapseButton';
import OrderTile from './OrderTile';
import { AggregatedSummedProjectOrders } from '~/api/OrderingApi';

type Props = {
  summedProjectOrder?: AggregatedSummedProjectOrders;
};

const OrdersInProject: React.FC<Props> = ({ summedProjectOrder }) => {
  const translate = useTranslate();
  const [open, setOpen] = useState(false);

  const orders = useMemo(
    () =>
      summedProjectOrder?.orderedProjects?.filter(
        (op) => !op.project.is_option,
      ),
    [summedProjectOrder],
  );

  return (
    <IonCard className="small outer-card">
      <IonCardHeader>
        <IonItem>
          <FlexRow justifyContent="between">
            <Text textSize="2xl">{translate('orderInProject.orders')}</Text>
            <Text textSize="xl" className="mt-1">
              {translate('orderInProject.summedAmount') + ' '}
              {summedProjectOrder?.totalAmount}
            </Text>
            <CollapseButton
              isOpen={open}
              toggleIsOpen={() => setOpen((open) => !open)}
            />
          </FlexRow>
        </IonItem>
      </IonCardHeader>
      <Collapse isOpen={open}>
        <IonCardContent className="px-3">
          <IonList>
            {orders?.map((op) => (
              <OrderTile orderProject={op} key={op.id} />
            ))}
          </IonList>
        </IonCardContent>
      </Collapse>
    </IonCard>
  );
};

export default OrdersInProject;
