import { MutationOptions, QueryOptions, unwrap } from '~/api/common';
import { AllPictureTypes, PictureType } from '~/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

function loadPictureOfRunningAny(
  realId: string,
  pictureType: PictureType,
): Promise<AllPictureTypes> {
  let url = '';
  if (pictureType === PictureType.JOB) {
    url = `job/pic/${realId}/`;
  } else if (pictureType === PictureType.NEWJOB) {
    url = `new_job/pic/${realId}/`;
  } else if (pictureType === PictureType.ROUTINE) {
    url = `routine/pic/${realId}/`;
  } else if (pictureType === PictureType.SPECIALJOB) {
    url = `special_job/pic/${realId}/`;
  } else if (pictureType === PictureType.PROJECT) {
    url = `project/pic/${realId}`;
  }
  return axios.get(url).then(unwrap);
}

export function usePictureOfRunningAny({
  pictureType,
  pictureId,
  ...options
}: QueryOptions<AllPictureTypes> & {
  pictureId: number;
  pictureType: PictureType;
}) {
  return useQuery({
    ...options,
    queryKey: ['picture', pictureId, pictureType],
    queryFn: async () =>
      loadPictureOfRunningAny(pictureId.toString(), pictureType),
  });
}

export function useAllPicturesOfRunningAny<T = AllPictureTypes[]>({
  picIds,
  ...options
}: QueryOptions<AllPictureTypes[], T> & {
  picIds: { id: number; type: PictureType }[];
}) {
  const queryClient = useQueryClient();
  return useQuery({
    ...options,
    queryKey: ['pictures', picIds],
    queryFn: async () => {
      const pictures = await Promise.all(
        picIds.map((picId) =>
          loadPictureOfRunningAny(picId.id.toString(), picId.type),
        ),
      );
      pictures.forEach((pic, i) => {
        queryClient.setQueryData(
          ['picture', picIds[i].id, picIds[i].type],
          pic,
        );
      });
      return pictures;
    },
  });
}

export function useAddPictureToRunningAny(
  options?: MutationOptions<
    AllPictureTypes,
    {
      image: { base64String?: string };
      runningAnyId: number;
      pictureType: PictureType;
    }
  >,
) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: ({ runningAnyId, image, pictureType }) =>
      axios
        .post(`/running/add_pic/${runningAnyId}/`, {
          picture: 'data:image/jpeg;base64,' + image.base64String,
          jobType: pictureType,
        })
        .then(unwrap),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['running'] });
    },
  });
}
