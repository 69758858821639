/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderedJob } from './OrderedJob';
import {
    OrderedJobFromJSON,
    OrderedJobFromJSONTyped,
    OrderedJobToJSON,
    OrderedJobToJSONTyped,
} from './OrderedJob';
import type { Job } from './Job';
import {
    JobFromJSON,
    JobFromJSONTyped,
    JobToJSON,
    JobToJSONTyped,
} from './Job';

/**
 * 
 * @export
 * @interface SummedJobOrders
 */
export interface SummedJobOrders {
    /**
     * 
     * @type {Job}
     * @memberof SummedJobOrders
     */
    job: Job;
    /**
     * 
     * @type {Array<OrderedJob>}
     * @memberof SummedJobOrders
     */
    orders: Array<OrderedJob>;
    /**
     * 
     * @type {number}
     * @memberof SummedJobOrders
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof SummedJobOrders
     */
    done: number;
    /**
     * 
     * @type {number}
     * @memberof SummedJobOrders
     */
    remaining: number;
}

/**
 * Check if a given object implements the SummedJobOrders interface.
 */
export function instanceOfSummedJobOrders(value: object): value is SummedJobOrders {
    if (!('job' in value) || value['job'] === undefined) return false;
    if (!('orders' in value) || value['orders'] === undefined) return false;
    if (!('total' in value) || value['total'] === undefined) return false;
    if (!('done' in value) || value['done'] === undefined) return false;
    if (!('remaining' in value) || value['remaining'] === undefined) return false;
    return true;
}

export function SummedJobOrdersFromJSON(json: any): SummedJobOrders {
    return SummedJobOrdersFromJSONTyped(json, false);
}

export function SummedJobOrdersFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummedJobOrders {
    if (json == null) {
        return json;
    }
    return {
        
        'job': JobFromJSON(json['job']),
        'orders': ((json['orders'] as Array<any>).map(OrderedJobFromJSON)),
        'total': json['total'],
        'done': json['done'],
        'remaining': json['remaining'],
    };
}

  export function SummedJobOrdersToJSON(json: any): SummedJobOrders {
      return SummedJobOrdersToJSONTyped(json, false);
  }

  export function SummedJobOrdersToJSONTyped(value?: SummedJobOrders | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'job': JobToJSON(value['job']),
        'orders': ((value['orders'] as Array<any>).map(OrderedJobToJSON)),
        'total': value['total'],
        'done': value['done'],
        'remaining': value['remaining'],
    };
}

