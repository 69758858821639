import { QueryOptions, unwrap } from './common';
import { Department } from '~/types';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export function useDepartments<T = Department[]>(
  options?: QueryOptions<Department[], T>,
) {
  return useQuery({
    ...options,
    queryKey: ['departments'],
    queryFn: () => axios.get<Department[]>('/departments/').then(unwrap),
  });
}
