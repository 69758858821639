/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Project } from './Project';
import {
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
    ProjectToJSONTyped,
} from './Project';
import type { OrderedProject } from './OrderedProject';
import {
    OrderedProjectFromJSON,
    OrderedProjectFromJSONTyped,
    OrderedProjectToJSON,
    OrderedProjectToJSONTyped,
} from './OrderedProject';

/**
 * 
 * @export
 * @interface SummedProjectOrders
 */
export interface SummedProjectOrders {
    /**
     * 
     * @type {Project}
     * @memberof SummedProjectOrders
     */
    project: Project;
    /**
     * 
     * @type {Array<OrderedProject>}
     * @memberof SummedProjectOrders
     */
    orderedProjects: Array<OrderedProject>;
    /**
     * 
     * @type {number}
     * @memberof SummedProjectOrders
     */
    totalAmount: number;
    /**
     * 
     * @type {Date}
     * @memberof SummedProjectOrders
     */
    minDate: Date;
}

/**
 * Check if a given object implements the SummedProjectOrders interface.
 */
export function instanceOfSummedProjectOrders(value: object): value is SummedProjectOrders {
    if (!('project' in value) || value['project'] === undefined) return false;
    if (!('orderedProjects' in value) || value['orderedProjects'] === undefined) return false;
    if (!('totalAmount' in value) || value['totalAmount'] === undefined) return false;
    if (!('minDate' in value) || value['minDate'] === undefined) return false;
    return true;
}

export function SummedProjectOrdersFromJSON(json: any): SummedProjectOrders {
    return SummedProjectOrdersFromJSONTyped(json, false);
}

export function SummedProjectOrdersFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummedProjectOrders {
    if (json == null) {
        return json;
    }
    return {
        
        'project': ProjectFromJSON(json['project']),
        'orderedProjects': ((json['orderedProjects'] as Array<any>).map(OrderedProjectFromJSON)),
        'totalAmount': json['totalAmount'],
        'minDate': (new Date(json['minDate'])),
    };
}

  export function SummedProjectOrdersToJSON(json: any): SummedProjectOrders {
      return SummedProjectOrdersToJSONTyped(json, false);
  }

  export function SummedProjectOrdersToJSONTyped(value?: SummedProjectOrders | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'project': ProjectToJSON(value['project']),
        'orderedProjects': ((value['orderedProjects'] as Array<any>).map(OrderedProjectToJSON)),
        'totalAmount': value['totalAmount'],
        'minDate': ((value['minDate']).toISOString().substring(0,10)),
    };
}

