import {
  RunningAny,
  RunningJob,
  RunningNewJob,
  RunningRoutine,
  RunningSpecialJob,
} from '~/types';

export function isRunningJob(job: RunningAny): job is RunningJob {
  return !!(job as RunningJob)?.job;
}

export function isRunningNewJob(job: RunningAny): job is RunningNewJob {
  return !!(job as RunningNewJob)?.new_job;
}

export function isRunningSpecialJob(job: RunningAny): job is RunningSpecialJob {
  return !!(job as RunningSpecialJob)?.special_job;
}

export function isRunningRoutine(job: RunningAny): job is RunningRoutine {
  return !!(job as RunningRoutine)?.routine;
}
