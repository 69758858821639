import { MutationOptions, QueryOptions, unwrap } from './common';
import { RunningAny, RunningSpecialJob, SpecialJob } from '~/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useRunningAnys } from './JobApi';
import { isRunningSpecialJob } from '~/utils/runningAny';

export function useSpecialJobs<T = SpecialJob[]>({
  employeeId,
  ...options
}: QueryOptions<SpecialJob[], T> & { employeeId: number }) {
  return useQuery({
    ...options,
    queryKey: ['special_job', 'list'],
    queryFn: () =>
      axios.get<SpecialJob[]>(`/special_jobs/${employeeId}/`).then(unwrap),
  });
}

export function useSpecialJob({
  specialJobId,
  ...options
}: Omit<QueryOptions<SpecialJob[], SpecialJob>, 'select'> & {
  employeeId: number;
  specialJobId: number;
}) {
  return useSpecialJobs({
    ...options,
    select: (jobs) => jobs.find((job) => Number(job.id) === specialJobId),
  });
}

export function useRunningSpecialJob({
  specialJobId,
  ...options
}: Omit<QueryOptions<RunningAny[], RunningSpecialJob | undefined>, 'select'> & {
  employeeId: number;
  specialJobId: number;
}) {
  return useRunningAnys({
    ...options,
    select: (data) =>
      data.find(
        (ra) =>
          isRunningSpecialJob(ra) && Number(ra.special_job.id) === specialJobId,
      ) as RunningSpecialJob | undefined,
  });
}

export function useStartSpecialJob(
  options?: MutationOptions<RunningSpecialJob, { specialJobId: number }>,
) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: ({ specialJobId }) =>
      axios.post(`/start_special_job/${specialJobId}/`).then(unwrap),
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['running'],
      });
      options?.onSuccess?.(...args);
    },
  });
}

export function useResumeSpecialJob(
  options?: MutationOptions<RunningSpecialJob, { runningSpecialJobId: number }>,
) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: ({ runningSpecialJobId }) =>
      axios
        .patch(`/start_paused_special_job/${runningSpecialJobId}/`)
        .then(unwrap),
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['running'],
      });
      options?.onSuccess?.(...args);
    },
  });
}

type CreateSpecialJobRequest = {
  name: string;
  description: string;
  duration: number;
  amount: number;
  employee: number;
};

export function useCreateSpecialJob(
  options?: MutationOptions<SpecialJob, CreateSpecialJobRequest>,
) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: (values) => axios.post(`/special_job/`, values).then(unwrap),
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: ['special_job'] });
      options?.onSuccess?.(...args);
    },
  });
}

type EndSpecialJobRequest = {
  runningSpecialJobId: number;
  amount: number;
  comment: string;
};

export function useEndSpecialJob(
  options?: MutationOptions<void, EndSpecialJobRequest>,
) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: ({ runningSpecialJobId, ...req }) =>
      axios.post(`/end_special_job/${runningSpecialJobId}/`, req).then(unwrap),
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({ queryKey: ['running'] });
      void queryClient.invalidateQueries({ queryKey: ['special_job'] });
      options?.onSuccess?.(...args);
    },
  });
}
