import React, { FC, PropsWithChildren } from 'react';
import { Text, TextProps } from '../Typography';

export const LinkButton: FC<PropsWithChildren<TextProps>> = ({
  children,
  color,
  opacity,
  textSize = 'base',
  className = '',
  onClick,
  ...props
}) => {
  return (
    <Text
      color={color}
      opacity={opacity}
      textSize={textSize}
      className={`${className} text-blue-700 cursor-pointer`}
      onClick={onClick}
      {...props}
    >
      {children}
    </Text>
  );
};
