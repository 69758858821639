import React, { useMemo } from 'react';
import { RoleCode } from '~/types';
import { useAuthStore } from '~/state/auth';

interface ShowForRoleProps {
  role: RoleCode;
  children: React.ReactNode;
}

export const ShowForRole: React.FC<ShowForRoleProps> = ({ children, role }) => {
  const roles = useAuthStore((store) => store.roles);
  const hasRole = useMemo(() => {
    return roles?.find((er) => er.role.code === role);
  }, [roles]);
  if (!hasRole) {
    return null;
  }
  return <>{children}</>;
};
