import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonPage,
  IonSearchbar,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { person } from 'ionicons/icons';
import React, { useState } from 'react';
import { Button, FlexRow, TextInput } from '~/components/defaultUIComponents';
import { useTranslate } from '~/i18n/translate';
import { useLogin } from '~/api/AuthApi';
import { useAuthStore } from '~/state/auth';
import { Redirect } from 'react-router-dom';
import routes from '~/constants/routes.json';
import { useSearchCompany } from '~/api/CompanyApi';
import { useDebouncedState } from '~/utils/useDebouncedState';
import { useDisclosure } from '~/utils/disclosure';
import { Preferences } from '@capacitor/preferences';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';

type Company = { id: number; name: string };

const selectedCompanyKey = 'v1_selected_company';
const usernameKey = 'v1_username';

const Authenticate: React.FC = () => {
  const t = useTranslate();
  const [checked, setChecked] = useState(false);

  const { data: company } = useQuery({
    queryKey: ['selectedCompany'],
    queryFn: async () => {
      try {
        const result = await Preferences.get({ key: selectedCompanyKey });
        if (result.value) {
          const parsed = JSON.parse(result.value);
          if (isCompany(parsed)) {
            return parsed;
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        return console.error('Cannot load Company', err);
      }
    },
  });

  const { data: username = "" } = useQuery({
    queryKey: ['username'],
    queryFn: async () => {
      try {
        const result = await Preferences.get({ key: usernameKey });
        return result.value as string;
      } catch (err) {
        // eslint-disable-next-line no-console
        return console.warn('Cannot load Username', err);
      }
    },
  });

  const { values, errors, handleSubmit, setFieldValue, getFieldProps } =
    useFormik<AuthSchema>({
      initialValues: { company: company as unknown as Company, username, password: '' } satisfies AuthSchema,
      onSubmit: (values) => {
        void Preferences.set({key: usernameKey, value: values.username})
        login({
          username: values.username,
          password: values.password,
          company_id: values.company.id,
        });
      },
      validationSchema: authSchema,
      validateOnBlur: true,
      enableReinitialize: true,
    });

  const { mutate: login } = useLogin();

  const isLoggedIn = useAuthStore((store) => store.authenticated);

  if (isLoggedIn) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return <Redirect to={routes.TIME_TRACKING} />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{t('authenticate.login')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="flex flex-col justify-center items-center py-5 px-2 gap-5">
            <IonIcon
              className="mt-5 mb-8 text-4xl"
              icon={person}
              color="primary"
            />
            <CompanySelect
              value={values.company}
              onChange={(company) => {
                void setFieldValue('company', company);
                void Preferences.set({
                  key: selectedCompanyKey,
                  value: JSON.stringify(company),
                });
              }}
              helperText={t(errors.company?.id)}
            />
            <TextInput
              label="authenticate.user-name"
              autocorrect="off"
              clearInput
              {...getFieldProps('username')}
              onChange={(val) => void setFieldValue('username', val)}
              helperText={t(errors.username)}
            />
            <TextInput
              {...getFieldProps('password')}
              onChange={(val) => void setFieldValue('password', val)}
              label="authenticate.password"
              autocorrect="off"
              obscureText={!checked}
              clearInput
              helperText={t(errors.password)}
            />
            <FlexRow className="w-full justify-end">
              <IonCheckbox
                checked={checked}
                onIonChange={() => setChecked(!checked)}
                labelPlacement="start"
              >
                {t('authenticate.showPassword')}
              </IonCheckbox>
            </FlexRow>
          </div>
        </IonContent>
        <IonFooter className="bg-white">
          <Button type="submit" expand="full">
            {t('authenticate.login')}
          </Button>
        </IonFooter>
      </IonPage>
    </form>
  );
};

type CompanySelectProps = {
  value?: Company;
  onChange: (company: Company) => void;
  helperText?: string;
};

function CompanySelect(props: CompanySelectProps) {
  const t = useTranslate();
  const [searchTerm, debouncedSearchTerm, setSearchTerm] =
    useDebouncedState<string>();
  const { isOpen, toggle } = useDisclosure();

  const { data: companies = [], isLoading } = useSearchCompany({
    searchTerm: debouncedSearchTerm as string,
    enabled: !!debouncedSearchTerm && debouncedSearchTerm.length >= 3,
  });

  return (
    <>
      <div className="w-full">
        <div
          className="w-full cursor-pointer rounded border border-black border-opacity-30 p-3"
          onClick={() => {
            toggle();
          }}
        >
          <IonText>
            {props.value?.name ?? t('authenticate.chooseCompany')}
          </IonText>
        </div>
      </div>
      {props.helperText && (
        <IonText className="text-xs w-full pl-4 text-gray-500">
          {props.helperText}
        </IonText>
      )}
      <IonModal isOpen={isOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{t('authenticate.chooseCompany')}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={toggle}>{t('close')}</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSearchbar
            placeholder={t('authenticate.chooseCompany')}
            value={searchTerm}
            onIonInput={(e) => setSearchTerm(e.target.value ?? undefined)}
          />
          <div className="flex px-2 w-full">
            <IonText className="text-sm text-gray-500">
              {t('authenticate.searchCompany.helper')}
            </IonText>
          </div>
          <div className="overflow-y-auto overflow-x-hidden flex flex-col gap-2 justify-center items-center m-2 mt-4">
            {companies?.map((company) => (
              <div
                key={company.id}
                className="w-full rounded p-3 cursor-pointer bg-gray-100 shadow-2xl"
                onClick={() => {
                  props.onChange(company);
                  toggle();
                }}
              >
                <IonLabel>{company.name}</IonLabel>
              </div>
            ))}
            {isLoading && (
              <div>
                <IonSpinner name="circular" />
              </div>
            )}
          </div>
        </IonContent>
      </IonModal>
    </>
  );
}

function isCompany(value: unknown): value is Company {
  return (
    !!value &&
    typeof value === 'object' &&
    'id' in value &&
    typeof value.id === 'number' &&
    'name' in value
  );
}

const authSchema = yup.object({
  username: yup.string().required('yup.required'),
  password: yup.string().required('yup.required'),
  company: yup
    .object({
      id: yup.number().required('yup.required'),
      name: yup.string().required('yup.required'),
    })
    .required('yup.required'),
});

type AuthSchema = yup.InferType<typeof authSchema>;

export default Authenticate;
