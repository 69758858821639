import { IonContent, IonLoading, IonPage } from '@ionic/react';
import _ from 'lodash';
import React, { useState } from 'react';
import { ListView, SearchField } from '../components/defaultUIComponents';
import { JobsColleaguesTile } from '../components/defaultUIComponents/JobsInColleaguesTile';
import Toolbar from '../components/Toolbar/Toolbar';
import { useTranslate } from '../i18n/translate';
import { useEmployeeId } from '~/state/auth';
import { formatName } from '../utils/employeeUtils';
import { useEmployees } from '~/api/OverseerApi';

const Overseer: React.FC = () => {
  const translate = useTranslate();
  const [searchTerm, setSearchTerm] = useState('');
  const myId = useEmployeeId();
  const { data: employees, isLoading } = useEmployees({
    select: (employees) => employees?.filter((em) => Number(em.id) !== myId),
  });

  return (
    <IonPage>
      <Toolbar>{translate('jobsColleagues.toolbar')}</Toolbar>
      <IonContent>
        <IonLoading isOpen={isLoading} />
        <div className="p-2">
          <SearchField onInputChange={setSearchTerm} />
          <ListView
            data={_.orderBy(employees!, formatName)}
            search={searchTerm}
            searchFields={(e) => [
              e.user.first_name,
              e.user.last_name,
              e.user.username,
              `${e.user.first_name} ${e.user.last_name}`,
            ]}
          >
            {({ data: employees }) => (
              <>
                {employees?.map((e) => (
                  <JobsColleaguesTile key={e.id} employee={e} />
                ))}
              </>
            )}
          </ListView>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Overseer;
