import { IonCard, IonItem, IonList } from '@ionic/react';
import _ from 'lodash';
import React from 'react';
import { FlexRow, Text } from '~/components/defaultUIComponents';
import WeekplanTile from '../components/WeekplanTile';
import { CollapseButton } from './defaultUIComponents/Button/CollapseButton';
import { useTranslate } from '~/i18n/translate';
import { useOrderedProjects } from '~/api/OrderingApi';

type WeekplanHeaderProps = {
  weekHeader: number;
  uniqueWeeks: number[];
  openCollapseId: number;
  setOpenCollapseId: React.Dispatch<React.SetStateAction<number>>;
};

const WeekplanHeader: React.FC<WeekplanHeaderProps> = ({
  weekHeader,
  openCollapseId,
  setOpenCollapseId,
}) => {
  const translate = useTranslate();
  const { data: orderedProjects = [] } = useOrderedProjects({
    select: (data) =>
      _.orderBy(
        data?.filter((entry) => entry.priority === weekHeader),
        [
          (entry) => _.lowerCase(entry.project.name),
          (entry) => _.lowerCase(entry.orderInformation.customerName),
        ],
      ),
  });

  const isCollapsed = openCollapseId !== weekHeader;

  const toggleIsCollapsed = () => {
    setOpenCollapseId((open) => (open === weekHeader ? -1 : weekHeader));
  };

  return (
    <IonCard>
      <IonItem lines="none" className="mx-2">
        <FlexRow className="ml-1" justifyContent="between">
          <Text textSize="xl">
            {translate('weekplanTile.Prio')}: {weekHeader}
          </Text>
          <CollapseButton
            isOpen={isCollapsed}
            toggleIsOpen={toggleIsCollapsed}
          />
        </FlexRow>
      </IonItem>
      <IonList>
        {!isCollapsed &&
          orderedProjects.map((weekplanEntry) => (
            <WeekplanTile
              key={weekplanEntry.id}
              orderedProject={weekplanEntry}
            />
          ))}
      </IonList>
    </IonCard>
  );
};

export default WeekplanHeader;
