import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { closeSharp } from 'ionicons/icons';
import React, { useMemo } from 'react';
import { useTranslate } from '../../i18n/translate';
import { TookTooLongType } from '../../types';
import {
  millisecondsToMomentNotation,
  momentNotationToMilliseconds,
} from '../../utils/timeUtils';
import { Textarea } from './Input';
import { DurationPicker, IDuration } from './Input/DurationPicker';
import { Label } from './Label';
import { FlexColumn, FlexRow } from './Layout';
import { Text } from './Typography';

interface TookTooLongModalProps {
  tookTooLongHeader: string;
  showModal: boolean;
  toggleShowJobTookTooLongModal: () => void;
  onModalSubmit: () => void;
  improvement: string;
  setImprovement: (value: string | undefined) => void;
  timeSuggestionInSeconds: number;
  setTimeSuggestionInSeconds: (value: number | undefined) => void;
  improvementLabel: string;
  actualDuration: number;
  demandedDurationPerPiece: string;
  doneAmount: number;
  tookTooLongType: TookTooLongType;
  disabled?: boolean;
}

export const TookTooLongModal: React.FC<TookTooLongModalProps> = ({
  tookTooLongHeader,
  showModal,
  toggleShowJobTookTooLongModal,
  onModalSubmit,
  improvement,
  setImprovement,
  timeSuggestionInSeconds,
  setTimeSuggestionInSeconds,
  improvementLabel,
  actualDuration,
  demandedDurationPerPiece,
  doneAmount,
  tookTooLongType,
  disabled,
}) => {
  const translate = useTranslate();
  const onChangeDuration = React.useCallback((duration: IDuration) => {
    setTimeSuggestionInSeconds(duration.asSeconds());
  }, []);
  const modalSubmitDisabled = useMemo(() => {
    return timeSuggestionInSeconds === 0;
  }, [improvement, timeSuggestionInSeconds]);

  const timeTooLong = useMemo(() => {
    const demandedDurationPerPieceInMilliseconds = momentNotationToMilliseconds(
      demandedDurationPerPiece,
    );
    const timeTooLongInMilliseconds =
      actualDuration - demandedDurationPerPieceInMilliseconds * doneAmount;
    return millisecondsToMomentNotation(timeTooLongInMilliseconds);
  }, [actualDuration, demandedDurationPerPiece]);

  return (
    <IonModal isOpen={showModal}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{translate(tookTooLongHeader)}</IonTitle>
          <IonIcon
            icon={closeSharp}
            slot="end"
            size="xl"
            onClick={() => toggleShowJobTookTooLongModal()}
            className="text-2xl"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <FlexColumn
          spaceBetween={2}
          className="px-4 mt-2 max-h-full overflow-y-scroll"
        >
          {tookTooLongType === TookTooLongType.jobTookTooLong ? (
            <>
              <FlexRow justifyContent="between">
                <Text>tookTooLong.actualDuration</Text>
                <Text>{millisecondsToMomentNotation(actualDuration)}</Text>
              </FlexRow>
              <FlexRow justifyContent="between">
                <Text>tookTooLong.demandedDurationPerPiece</Text>
                <Text>{demandedDurationPerPiece}</Text>
              </FlexRow>
              <FlexRow justifyContent="between">
                <Text>tookTooLong.amountDone</Text>
                <Text>{doneAmount}</Text>
              </FlexRow>
              <FlexRow justifyContent="between">
                <Text>tookTooLong.timeTooMuch</Text>
                <Text>{timeTooLong}</Text>
              </FlexRow>
            </>
          ) : null}
          <Textarea
            name="improvement"
            value={improvement}
            label={improvementLabel}
            onChange={(value) => setImprovement((value as string) ?? '')}
            className="mb-4"
          />
          <Label className="mt-4">tookTooLong.timeSuggestion</Label>
          <DurationPicker onChange={onChangeDuration} />
        </FlexColumn>
      </IonContent>
      <IonFooter className="fixed bottom-0 left-0 right-0">
        <IonToolbar>
          <IonButton
            disabled={modalSubmitDisabled || disabled}
            expand="full"
            onClick={onModalSubmit}
          >
            {translate('orderTile.closeModal')}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};
