/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProjectType = {
    PROJECT: 'PROJECT',
    OPTION: 'OPTION'
} as const;
export type ProjectType = typeof ProjectType[keyof typeof ProjectType];


export function instanceOfProjectType(value: any): boolean {
    for (const key in ProjectType) {
        if (Object.prototype.hasOwnProperty.call(ProjectType, key)) {
            if (ProjectType[key as keyof typeof ProjectType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ProjectTypeFromJSON(json: any): ProjectType {
    return ProjectTypeFromJSONTyped(json, false);
}

export function ProjectTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectType {
    return json as ProjectType;
}

export function ProjectTypeToJSON(value?: ProjectType | null): any {
    return value as any;
}

export function ProjectTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): ProjectType {
    return value as ProjectType;
}

