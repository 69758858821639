import { de, en } from './langs';
import { Language, LanguageSpec } from './LanguageSpec';
import _ from 'lodash';
import { useSettingsStore } from '~/state/settings';
import { ReactNode } from 'react';

const languages: { [key: string]: LanguageSpec } = { de, en };

export const translate = (
  language: Language,
  textKey: unknown,
): ReactNode | string => {
  if (typeof textKey !== 'string') return textKey as ReactNode;
  if (!_.has(languages, language)) return textKey as ReactNode;
  return _.get(languages[language], textKey, textKey) as string;
};

export function useTranslate() {
  const language = useSettingsStore((store) => store.language);
  return (textKey: unknown): string => translate(language, textKey) as string;
}
