import React from 'react';
import JobsInProject from './JobsInProject';
import { AggregatedSummedProjectOrders } from '~/api/OrderingApi';

type Props = {
  summedProjectOrder?: AggregatedSummedProjectOrders;
};

const OptionsInProject: React.FC<Props> = ({ summedProjectOrder }) => {
  return (
    <>
      {summedProjectOrder?.project?.options?.map((option) => (
        <JobsInProject key={option.id} projectId={option.id} isOption />
      ))}
    </>
  );
};

export default OptionsInProject;
