import {
  DeleteOrderedProjectRequest,
  FinishOrderedProjectRequest,
  GetOrderedProjectsRequest,
  Order,
  OrderedProject,
  OrderedProjectDoneOperationRequest,
  OrderRequest,
  SummedJobOrders,
  SummedProjectOrders,
  ViewState,
} from '@its4plan/ordering';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {Project} from '~/types';
import {QueryOptions, useApiContext} from '~/api/common';
import {getProjects} from '~/api/ProjectApi';
import _ from 'lodash';

type MutationOptions<TData, TVariables> = Omit<
  UseMutationOptions<TData, Error, TVariables>,
  'mutationFn'
>;

export type AggregatedOrderedProject = Omit<
  OrderedProject,
  'project' | 'options'
> & {
  project: Project;
  options: AggregatedOrderedProject[];
};

export type AggregatedSummedProjectOrders = Omit<
  SummedProjectOrders,
  'project' | 'orderedProjects'
> & {
  project: Project;
  orderedProjects: AggregatedOrderedProject[];
};

export function useSummedOrderedProjects<T = AggregatedSummedProjectOrders[]>(
  options?: QueryOptions<AggregatedSummedProjectOrders[], T> & {
    viewState?: ViewState;
  },
) {
  const {summedOrderedProjectAPi} = useApiContext();
  return useQuery({
    queryKey: ['summed', 'orderedProject', options?.viewState],
    queryFn: async () => {
      const projectsById = await projectById();
      const summedOrderProjects =
        await summedOrderedProjectAPi.getSummedOrderedProjects({
          viewState: options?.viewState,
        });
      return mapSummedOrderedProjects(summedOrderProjects, projectsById);
    },
    ...options,
  });
}

export function useSummedOrderedProject<T = AggregatedSummedProjectOrders>(
  options: QueryOptions<AggregatedSummedProjectOrders, T> & {
    projectId: number;
  },
) {
  const {summedOrderedProjectAPi} = useApiContext();
  return useQuery({
    queryKey: ['summed', 'orderedProject', options.projectId],
    queryFn: async () => {
      const projectsById = await projectById();
      const summedOrderProject =
        await summedOrderedProjectAPi.getSummedOrderedProject({
          projectId: options.projectId,
        });
      return mapSummedOrderedProject(summedOrderProject, projectsById);
    },
    ...options,
  });
}

export function useOrderedProjects<T = OrderedProject[]>(
  options?: QueryOptions<OrderedProject[], T> & {
    request?: GetOrderedProjectsRequest;
  },
) {
  const {orderedProjectApi} = useApiContext();
  return useQuery({
    queryKey: ['orderedProject', options?.request],
    queryFn: () => orderedProjectApi.getOrderedProjects(options?.request),
    ...options,
  });
}

export function useDeleteOrderedProject(
  options?: MutationOptions<void, DeleteOrderedProjectRequest>,
) {
  const {orderedProjectApi} = useApiContext();
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: (request) => orderedProjectApi.deleteOrderedProject(request),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries();
      options?.onSuccess?.(data, variables, context);
    },
  });
}

export function useFinishOrderedProject(
  options?: MutationOptions<OrderedProject, FinishOrderedProjectRequest>,
) {
  const {orderedProjectApi} = useApiContext();
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: (request) => orderedProjectApi.finishOrderedProject(request),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries();
      options?.onSuccess?.(data, variables, context);
    },
  });
}

export function useSummedOrderedJob<T = SummedJobOrders>(
  options: QueryOptions<SummedJobOrders, T> & { jobId: number },
) {
  const {summedOrderedJobsApi} = useApiContext();
  return useQuery({
    ...options,
    queryKey: ['job', 'summed', options.jobId],
    queryFn: () =>
      summedOrderedJobsApi.getSummedOrderedJob({jobId: options.jobId}),
  });
}

export function useCreateOrder(options?: MutationOptions<Order, OrderRequest>) {
  const {orderApi} = useApiContext();
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: (orderRequest) => orderApi.order({orderRequest}),
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.invalidateQueries();
    },
  });
}

export function useOrderedProjectDone(
  options?: MutationOptions<OrderedProject, OrderedProjectDoneOperationRequest>,
) {
  const {orderedProjectApi} = useApiContext();
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: (doneRequest) =>
      orderedProjectApi.orderedProjectDone(doneRequest),
    onSuccess: async (...args) => {
      options?.onSuccess?.(...args);
      await queryClient.invalidateQueries();
    },
  });
}

async function projectById() {
  const projects = await getProjects();
  return projects.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {} as { [key: number]: Project });
}

function mapSummedOrderedProjects(
  summedOrderProjects: SummedProjectOrders[],
  projectsById: { [key: string]: Project },
): AggregatedSummedProjectOrders[] {
  return summedOrderProjects.map((sop) => {
    return {
      ...sop,
      project: projectsById[sop.project.foreignId] as Project,
      orderedProjects: sop.orderedProjects.map((op) =>
        mapOrderedProject(op, projectsById),
      ),
    };
  });
}

function mapProject(
  projectId: number,
  projectsById: { [key: string]: Project },
): Project {
  const project = projectsById[projectId] as Project;
  return {
    ...project,
    options: (_.isEmpty(project?.options)
        ? project?.option_ids?.map((id) => projectsById[id]).filter(Boolean)
        : project?.options)
      ?? [],
  };
}

function mapSummedOrderedProject(
  summedOrderedProject: SummedProjectOrders,
  projectsById: { [key: string]: Project },
): AggregatedSummedProjectOrders {
  return {
    ...summedOrderedProject,
    project: mapProject(summedOrderedProject.project.foreignId, projectsById),
    orderedProjects: summedOrderedProject.orderedProjects.map((op) =>
      mapOrderedProject(op, projectsById),
    ),
  };
}

function mapOrderedProject(
  orderedProject: OrderedProject,
  projectById: { [key: number]: Project },
): AggregatedOrderedProject {
  return {
    ...orderedProject,
    project: mapProject(orderedProject.project.foreignId, projectById),
    options: orderedProject.options.map((op) =>
      mapOrderedProject(op, projectById),
    ),
  };
}
