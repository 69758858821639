import {
  Camera,
  CameraDirection,
  CameraResultType,
  CameraSource,
} from '@capacitor/camera';
import { IonButton, IonIcon } from '@ionic/react';
import { camera } from 'ionicons/icons';
import React from 'react';
import { PictureType } from '~/types';
import { Camera as CameraWrapper } from './Camera';
import { useAddPictureToRunningAny } from '~/api/PictureApi';

interface TakePhotoButtonProps {
  runningAnyId: string;
  pictureType: PictureType;
  className?: string;
  expand?: 'full' | 'block';
  disabled?: boolean;
}

export const TakePhotoButton: React.FC<TakePhotoButtonProps> = ({
  className = 'w-14 h-14 mb-4 mr-1',
  ...props
}) => {
  const { mutate: addPicture } = useAddPictureToRunningAny();

  const takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      saveToGallery: false,
      presentationStyle: 'fullscreen',
      direction: CameraDirection.Rear,
      source: CameraSource.Camera,
    });
    addPicture({
      image,
      pictureType: props.pictureType,
      runningAnyId: Number(props.runningAnyId),
    });
  };

  return (
    <CameraWrapper>
      <IonButton className={className} onClick={takePicture} {...props}>
        <IonIcon icon={camera} />
      </IonButton>
    </CameraWrapper>
  );
};
