import React from 'react';
import { useHistory } from 'react-router';
import routes from '../../constants/routes.json';
import { useTranslate } from '~/i18n/translate';
import { WorkingState } from '~/types';
import { Button, FlexColumn, FlexRow } from '../defaultUIComponents';
import {
  useEndWork,
  usePauseWork,
  useStartWork,
  useWorkingState,
} from '~/api/TimetrackingApi';
import { useAuthStore } from '~/state/auth';
import { useCompanySettigns } from '~/api/CompanySettingsApi';
import { useLogout } from '~/api/AuthApi';

interface TimeTrackingButtonsProps {}

export const TimeTrackingButtons: React.FC<TimeTrackingButtonsProps> = () => {
  const { data: workingState = WorkingState.NOTHING } = useWorkingState();
  const needsTwoFactor = useAuthStore((store) => store.needs_two_factor);
  const { data: companySettings } = useCompanySettigns();
  const t = useTranslate();
  const history = useHistory();

  const { mutate: startWork, isPending: startWorkPending } = useStartWork();
  const { mutate: pauseWork, isPending: pauseWorkPending } = usePauseWork({
    onSuccess: () => {
      if (history.location.pathname.startsWith('/run')) {
        history.push(routes.RUNNING_JOBS);
      }
    },
  });
  const { mutate: handleEndWork, isPending: endWorkPending } = useEndWork();
  const { mutate: logout } = useLogout();

  const isLoading = startWorkPending || pauseWorkPending || endWorkPending;

  const endWork = () => {
    if (companySettings?.logout_on_leave) {
      return logout();
    }
    handleEndWork();
  };

  return (
    <>
      {workingState === WorkingState.WORKING ? (
        <FlexRow>
          <FlexColumn>
            <Button
              onClick={() => pauseWork()}
              disabled={isLoading}
              className="ml-1 mr-1 h-10"
            >
              {t('timeTracking.pause')}
            </Button>
          </FlexColumn>
          <FlexColumn>
            <Button
              onClick={() => endWork()}
              disabled={isLoading}
              className="mr-1  h-10"
            >
              {t('timeTracking.end')}
            </Button>
          </FlexColumn>
        </FlexRow>
      ) : (
        <Button
          onClick={() => startWork()}
          disabled={isLoading}
          className="ml-1 mr-2  h-10"
        >
          {t(
            workingState === WorkingState.NOTHING
              ? 'timeTracking.start'
              : 'timeTracking.pauseEnd',
          )}
        </Button>
      )}
    </>
  );
};
