import { IonTextarea } from '@ionic/react';
import React from 'react';
import { useTranslate } from '../../../i18n/translate';
import { BaseInputProps } from './types';

interface TextareaProps extends BaseInputProps {
  value: string | null | undefined;
  onChange: (value: string | null | undefined) => void;
  autoGrow?: boolean;
  maxlength?: number;
  minlength?: number;
  rows?: number;
  spellcheck?: boolean;
  wrap?: 'hard' | 'off' | 'soft';
}

export const Textarea: React.FC<TextareaProps> = ({
  onChange,
  onBlur,
  onFocus,
  label,
  placeholder,
  className = '',
  ...props
}) => {
  const translate = useTranslate();
  return (
    <IonTextarea
      style={{ color: '#000' }}
      placeholder={translate(placeholder)}
      onIonFocus={onFocus}
      onIonBlur={onBlur}
      onIonInput={(e) => onChange(e.detail.value)}
      fill="outline"
      label={translate(label)}
      labelPlacement="floating"
      {...props}
    />
  );
};
