import { isPlatform } from '@ionic/react';
import React from 'react';
import { CodeScannerNative } from './CodeScannerNative';
import CodeScannerWebApp from './CodeScannerWebApp';

interface HybridCodeScannerProps {
  isScanning: boolean;
  setIsScanning: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: (qrCode: string) => void;
  onAbort: () => void;
}

export const HybridCodeScanner: React.FC<HybridCodeScannerProps> = (props) => {
  const runningInApp = isPlatform('hybrid');
  return runningInApp ? (
    <CodeScannerNative
      functionToCallOnSuccess={props.onSuccess}
      functionToCallOnAbort={props.onAbort}
      isScanning={props.isScanning}
      setIsScanning={props.setIsScanning}
    />
  ) : (
    <CodeScannerWebApp
      functionToCallOnSuccess={props.onSuccess}
      functionToCallOnAbort={props.onAbort}
      isScanning={props.isScanning}
      setIsScanning={props.setIsScanning}
    />
  );
};
