import * as yup from 'yup';

const required = 'yup.required';

export const endingNewJobSchema = yup.object().shape({
  amount: yup.number().required(required).min(1).integer(),
  comment: yup.string().required(required),
});

export const runningAnySchema = yup.object().shape({
  amount: yup.number().required(required).min(1).integer(),
  comment: yup.string().optional(),
});

export const newProjectJobSchema = yup.object().shape({
  name: yup.string().required(required),
  project: yup.string().required(required),
});

export const newSpecialJobSchema = yup.object().shape({
  description: yup.string(),
  amount: yup.number().required(required).min(1).integer(),
});
