import React from 'react';
import { FlexColumn } from './FlexColumn';
import { FlexRow } from './FlexRow';
import { BaseFlexLayoutProps } from './types';

interface Direction {
  direction: 'row' | 'column';
  children: React.ReactNode;
}

export const Flex: React.FC<BaseFlexLayoutProps & Direction> = ({
  direction,
  children,
  ...props
}) => {
  return (
    <>
      {direction === 'column' ? (
        <FlexColumn {...props}>{children}</FlexColumn>
      ) : (
        <FlexRow {...props}>{children}</FlexRow>
      )}
    </>
  );
};
