import React from 'react';
import { useTranslate } from '~/i18n/translate';
import { RoleCode } from '~/types';
import { Button, FlexColumn, FlexRow, Text } from './defaultUIComponents';
import { OrderDoneModal } from './OrderDoneModal';
import { ShowForRole } from './ShowForRole';
import { useDisclosure } from '~/utils/disclosure';
import {
  AggregatedOrderedProject,
  useOrderedProjectDone,
} from '~/api/OrderingApi';

type OrderTileProps = {
  orderProject: AggregatedOrderedProject;
};

const OrderTile: React.FC<OrderTileProps> = ({ orderProject }) => {
  const translate = useTranslate();
  const { isPending } = useOrderedProjectDone();
  const modal = useDisclosure();

  return (
    <>
      <FlexColumn padding={4} className="mb-2">
        <FlexRow justifyContent="between">
          <Text textSize="lg">
            {orderProject?.orderInformation?.customerName}
          </Text>
        </FlexRow>
        <Text>
          {translate('orderTile.comment') + ' '}
          {orderProject?.comment}
        </Text>
        <FlexRow justifyContent="between">
          <Text>
            {translate('orderTile.amount') + ' '}
            {orderProject?.amount}
          </Text>
          <Text>
            {translate('orderTile.done_amount') + ' '}
            {orderProject?.doneAmount}
          </Text>
        </FlexRow>
        <FlexRow>
          <Text className="mr-1">{translate('orderTile.options')}</Text>
          <FlexColumn>
            {(orderProject?.options.length ?? 0) > 0 ? (
              <>
                {orderProject?.options.map((option) => (
                  <Text key={option.id}>{option.project.name}</Text>
                ))}
              </>
            ) : (
              <Text>timeTracking.noOptions.text</Text>
            )}
          </FlexColumn>
        </FlexRow>
        <ShowForRole role={RoleCode.PROJECT_FINISHER}>
          <Button disabled={isPending} onClick={modal.open}>
            {translate('orderTile.done')}
          </Button>
        </ShowForRole>
      </FlexColumn>
      <OrderDoneModal
        isOpen={modal.isOpen}
        close={modal.close}
        orderProject={orderProject}
      />
    </>
  );
};

export default OrderTile;
