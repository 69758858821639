import {
  IonCard,
  IonCardContent,
  IonContent,
  IonLoading,
  IonPage,
} from '@ionic/react';
import React from 'react';

import { FlexColumn } from '../components/defaultUIComponents';
import JobTile from '../components/JobTile';
import NewJobTile from '../components/NewJobTile';
import RoutineTile from '../components/RoutineTile';
import SpecialJobTile from '../components/SpecialJobTile';
import Toolbar from '../components/Toolbar/Toolbar';
import { useTranslate } from '../i18n/translate';
import {
  isRunningJob,
  isRunningNewJob,
  isRunningRoutine,
  isRunningSpecialJob,
} from '../utils/runningAny';
import { useRunningAnys } from '../api/JobApi';
import { useEmployeeId } from '~/state/auth';

const RunningJobs: React.FC = () => {
  const translate = useTranslate();
  const employeeId = useEmployeeId();

  const { data: runningAnys, isLoading } = useRunningAnys({ employeeId });

  return (
    <IonPage>
      <Toolbar>{translate('runningJobs.toolbar')}</Toolbar>
      <IonContent>
        <IonLoading isOpen={isLoading} />
        <FlexColumn>
          {runningAnys?.map((ra) =>
            isRunningJob(ra) ? (
              <IonCard key={ra.id}>
                <IonCardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <JobTile jobId={Number(ra.job.id)} parentPage="RunningJobs" />
                </IonCardContent>
              </IonCard>
            ) : isRunningRoutine(ra) ? (
              <RoutineTile key={ra.id} routine={ra.routine} isStartable />
            ) : isRunningSpecialJob(ra) ? (
              <SpecialJobTile
                key={ra.id}
                specialJob={ra.special_job}
                isStartable
              />
            ) : isRunningNewJob(ra) ? (
              <NewJobTile key={ra.id} newJob={ra.new_job} isStartable />
            ) : null,
          )}
        </FlexColumn>
      </IonContent>
    </IonPage>
  );
};

export default RunningJobs;
