/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SummedProjectOrders,
  ViewState,
} from '../models/index';
import {
    SummedProjectOrdersFromJSON,
    SummedProjectOrdersToJSON,
    ViewStateFromJSON,
    ViewStateToJSON,
} from '../models/index';

export interface GetSummedOrderedProjectRequest {
    projectId: number;
}

export interface GetSummedOrderedProjectsRequest {
    viewState?: ViewState;
}

/**
 * 
 */
export class SummedOrderedProjectsApi extends runtime.BaseAPI {

    /**
     */
    async getSummedOrderedProjectRaw(requestParameters: GetSummedOrderedProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SummedProjectOrders>> {
        if (requestParameters['projectId'] == null) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter "projectId" was null or undefined when calling getSummedOrderedProject().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orderedprojects/summed/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SummedProjectOrdersFromJSON(jsonValue));
    }

    /**
     */
    async getSummedOrderedProject(requestParameters: GetSummedOrderedProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SummedProjectOrders> {
        const response = await this.getSummedOrderedProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSummedOrderedProjectsRaw(requestParameters: GetSummedOrderedProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SummedProjectOrders>>> {
        const queryParameters: any = {};

        if (requestParameters['viewState'] != null) {
            queryParameters['viewState'] = requestParameters['viewState'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orderedprojects/summed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SummedProjectOrdersFromJSON));
    }

    /**
     */
    async getSummedOrderedProjects(requestParameters: GetSummedOrderedProjectsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SummedProjectOrders>> {
        const response = await this.getSummedOrderedProjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
