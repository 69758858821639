import { DatetimeChangeEventDetail } from '@ionic/core';
import { IonDatetime, IonIcon, IonPopover } from '@ionic/react';
import * as dateFns from 'date-fns';
import { calendar } from 'ionicons/icons';
import React, { useMemo, useState } from 'react';
import { FlexRow } from '..';
import { Text } from '../Typography';

interface DateInputProps {
  onChange?: (value: Date) => void;
  className?: string;
  value?: Date | number;
}

export const DateInput: React.FC<DateInputProps> = ({
  onChange,
  className,
  value,
}) => {
  const [open, setOpen] = useState(false);

  const _value = useMemo(() => {
    return dateFns.format(value ?? new Date(), 'yyyy-MM-dd');
  }, [value]);
  const displayValue = useMemo(() => {
    return dateFns.format(value ?? new Date(), 'dd.MM.yyyy');
  }, [value]);

  const onInputChange = (
    event: CustomEvent<DatetimeChangeEventDetail>,
  ): void => {
    if (onChange) {
      const dateString = event.detail.value as string;
      if (dateString) {
        onChange(new Date(dateString));
      }
    }
    setOpen(false);
  };

  return (
    <>
      <div onClick={() => setOpen(true)}>
        <FlexRow
          className="border-2 rounded-md px-2 py-1"
          justifyContent="between"
        >
          <Text>{displayValue}</Text>
          <IonIcon icon={calendar} />
        </FlexRow>
      </div>
      <IonPopover isOpen={open} onIonPopoverDidDismiss={() => setOpen(false)}>
        <IonDatetime
          value={_value}
          onIonChange={onInputChange}
          className={className}
          presentation="date"
        />
      </IonPopover>
    </>
  );
};
