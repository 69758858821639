/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectType } from './ProjectType';
import {
    ProjectTypeFromJSON,
    ProjectTypeFromJSONTyped,
    ProjectTypeToJSON,
    ProjectTypeToJSONTyped,
} from './ProjectType';
import type { Job } from './Job';
import {
    JobFromJSON,
    JobFromJSONTyped,
    JobToJSON,
    JobToJSONTyped,
} from './Job';

/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    foreignId: number;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    calculatedDuration: number;
    /**
     * 
     * @type {ProjectType}
     * @memberof Project
     */
    type: ProjectType;
    /**
     * 
     * @type {Array<Job>}
     * @memberof Project
     */
    jobs: Array<Job>;
}



/**
 * Check if a given object implements the Project interface.
 */
export function instanceOfProject(value: object): value is Project {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('foreignId' in value) || value['foreignId'] === undefined) return false;
    if (!('calculatedDuration' in value) || value['calculatedDuration'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('jobs' in value) || value['jobs'] === undefined) return false;
    return true;
}

export function ProjectFromJSON(json: any): Project {
    return ProjectFromJSONTyped(json, false);
}

export function ProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): Project {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'foreignId': json['foreignId'],
        'calculatedDuration': json['calculatedDuration'],
        'type': ProjectTypeFromJSON(json['type']),
        'jobs': ((json['jobs'] as Array<any>).map(JobFromJSON)),
    };
}

  export function ProjectToJSON(json: any): Project {
      return ProjectToJSONTyped(json, false);
  }

  export function ProjectToJSONTyped(value?: Project | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'foreignId': value['foreignId'],
        'calculatedDuration': value['calculatedDuration'],
        'type': ProjectTypeToJSON(value['type']),
        'jobs': ((value['jobs'] as Array<any>).map(JobToJSON)),
    };
}

