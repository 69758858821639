import {
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react';
import React, { createRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import routes from '../constants/routes.json';
import { useTranslate } from '~/i18n/translate';
import { AppPage, appPages } from '~/menuRoutes';
import useIsFeatureFlagActive from '../utils/featureFlag';
import { Button, FlexColumn, Text } from './defaultUIComponents';
import { useSpecialJobs } from '~/api/SpecialJobApi';
import { useAuthStore, useEmployeeId } from '~/state/auth';
import { useLogout } from '~/api/AuthApi';
import { RoleCode } from '~/types';

type Props = {
  appPage: AppPage;
  open: boolean;
};

const AppPageComponent = ({ appPage, open }: Props) => {
  const location = useLocation();
  const employeeId = useEmployeeId();
  const { data: specialJobs = [] } = useSpecialJobs({
    employeeId,
    enabled: open,
  });

  return useIsFeatureFlagActive(appPage.featureFlag) ? (
    <IonMenuToggle autoHide={false}>
      <IonItem
        className={location.pathname === appPage.url ? 'selected' : ''}
        routerLink={appPage.url}
        routerDirection="back"
        lines="none"
        detail={false}
      >
        <IonIcon slot="start" icon={appPage.iosIcon} />
        <Text className="text-xl">{appPage.title}</Text>
        {appPage.url === routes.SPECIAL_JOBS && specialJobs.length > 0 ? (
          <Button
            style={{ '--background': 'red' }}
            className="w-5"
            shape="round"
          >
            {specialJobs.length}
          </Button>
        ) : null}
      </IonItem>
    </IonMenuToggle>
  ) : null;
};

function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

const Menu: React.FC = () => {
  const translate = useTranslate();
  const { authenticated: isLoggedIn, company_name: companyName } =
    useAuthStore();
  const { mutate: logoutAction } = useLogout();

  const hasOverseerRole = useAuthStore(
    (store) => !!store.roles?.find((r) => r.role.code === RoleCode.OVERSEER),
  );
  const [open, setOpen] = useState<boolean>(false);

  const logout = async () => {
    closeMenu();
    await timeout(500);
    logoutAction();
  };

  const ref = createRef<HTMLIonMenuElement>();

  const closeMenu = () => {
    ref.current?.close();
  };

  if (!isLoggedIn) {
    return null;
  }

  return (
    <IonMenu
      contentId="main"
      type="overlay"
      ref={ref}
      onIonDidOpen={() => setOpen(true)}
      onIonDidClose={() => setOpen(false)}
    >
      <IonContent>
        <IonList id="inbox-list">
          <FlexColumn className="mb-2">
            <Text className="ml-1 text-xl">Its4-Plan</Text>
            <Text className="ml-1 text-xl">{companyName}</Text>
          </FlexColumn>
          {appPages(hasOverseerRole).map((appPage, index) => (
            <AppPageComponent key={index} appPage={appPage} open={open} />
          ))}
          <Button className="ml-1 mr-3" onClick={logout}>
            {translate('menu.logout')}
          </Button>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
