/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OrderedProject,
  OrderedProjectDoneRequest,
  OrderedProjectDurationSummary,
  ProjectOrder,
  ViewState,
} from '../models/index';
import {
    OrderedProjectFromJSON,
    OrderedProjectToJSON,
    OrderedProjectDoneRequestFromJSON,
    OrderedProjectDoneRequestToJSON,
    OrderedProjectDurationSummaryFromJSON,
    OrderedProjectDurationSummaryToJSON,
    ProjectOrderFromJSON,
    ProjectOrderToJSON,
    ViewStateFromJSON,
    ViewStateToJSON,
} from '../models/index';

export interface CheckInOrderedProjectRequest {
    id: number;
}

export interface DeleteOrderedProjectRequest {
    id: number;
}

export interface FinishOrderedProjectRequest {
    id: number;
}

export interface GetOrderedProjectByIdRequest {
    id: number;
}

export interface GetOrderedProjectsRequest {
    projectId?: number;
    viewState?: ViewState;
}

export interface GetOrderedProjectsDurationSummaryRequest {
    startDate: Date;
    endDate: Date;
}

export interface OrderedProjectDoneOperationRequest {
    id: number;
    orderedProjectDoneRequest: OrderedProjectDoneRequest;
}

export interface UpdateOrderedProjectRequest {
    id: number;
    projectOrder: ProjectOrder;
}

/**
 * 
 */
export class OrderedProjectApi extends runtime.BaseAPI {

    /**
     */
    async checkInOrderedProjectRaw(requestParameters: CheckInOrderedProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderedProject>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling checkInOrderedProject().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orderedproject/{id}/checkin`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderedProjectFromJSON(jsonValue));
    }

    /**
     */
    async checkInOrderedProject(requestParameters: CheckInOrderedProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderedProject> {
        const response = await this.checkInOrderedProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteOrderedProjectRaw(requestParameters: DeleteOrderedProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteOrderedProject().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orderedproject/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteOrderedProject(requestParameters: DeleteOrderedProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOrderedProjectRaw(requestParameters, initOverrides);
    }

    /**
     */
    async finishOrderedProjectRaw(requestParameters: FinishOrderedProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderedProject>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling finishOrderedProject().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orderedproject/{id}/finish`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderedProjectFromJSON(jsonValue));
    }

    /**
     */
    async finishOrderedProject(requestParameters: FinishOrderedProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderedProject> {
        const response = await this.finishOrderedProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrderedProjectByIdRaw(requestParameters: GetOrderedProjectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderedProject>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getOrderedProjectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orderedproject/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderedProjectFromJSON(jsonValue));
    }

    /**
     */
    async getOrderedProjectById(requestParameters: GetOrderedProjectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderedProject> {
        const response = await this.getOrderedProjectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrderedProjectsRaw(requestParameters: GetOrderedProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderedProject>>> {
        const queryParameters: any = {};

        if (requestParameters['projectId'] != null) {
            queryParameters['projectId'] = requestParameters['projectId'];
        }

        if (requestParameters['viewState'] != null) {
            queryParameters['viewState'] = requestParameters['viewState'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orderedprojects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderedProjectFromJSON));
    }

    /**
     */
    async getOrderedProjects(requestParameters: GetOrderedProjectsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderedProject>> {
        const response = await this.getOrderedProjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrderedProjectsDurationSummaryRaw(requestParameters: GetOrderedProjectsDurationSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderedProjectDurationSummary>>> {
        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling getOrderedProjectsDurationSummary().'
            );
        }

        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling getOrderedProjectsDurationSummary().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = (requestParameters['startDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = (requestParameters['endDate'] as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orderedprojects/duration-summary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderedProjectDurationSummaryFromJSON));
    }

    /**
     */
    async getOrderedProjectsDurationSummary(requestParameters: GetOrderedProjectsDurationSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderedProjectDurationSummary>> {
        const response = await this.getOrderedProjectsDurationSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderedProjectDoneRaw(requestParameters: OrderedProjectDoneOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderedProject>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling orderedProjectDone().'
            );
        }

        if (requestParameters['orderedProjectDoneRequest'] == null) {
            throw new runtime.RequiredError(
                'orderedProjectDoneRequest',
                'Required parameter "orderedProjectDoneRequest" was null or undefined when calling orderedProjectDone().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orderproject/{id}/done`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderedProjectDoneRequestToJSON(requestParameters['orderedProjectDoneRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderedProjectFromJSON(jsonValue));
    }

    /**
     */
    async orderedProjectDone(requestParameters: OrderedProjectDoneOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderedProject> {
        const response = await this.orderedProjectDoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrderedProjectRaw(requestParameters: UpdateOrderedProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateOrderedProject().'
            );
        }

        if (requestParameters['projectOrder'] == null) {
            throw new runtime.RequiredError(
                'projectOrder',
                'Required parameter "projectOrder" was null or undefined when calling updateOrderedProject().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orderedproject/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectOrderToJSON(requestParameters['projectOrder']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateOrderedProject(requestParameters: UpdateOrderedProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateOrderedProjectRaw(requestParameters, initOverrides);
    }

}
