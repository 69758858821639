/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Job } from './Job';
import {
    JobFromJSON,
    JobFromJSONTyped,
    JobToJSON,
    JobToJSONTyped,
} from './Job';

/**
 * 
 * @export
 * @interface OrderedJob
 */
export interface OrderedJob {
    /**
     * 
     * @type {number}
     * @memberof OrderedJob
     */
    id: number;
    /**
     * 
     * @type {Job}
     * @memberof OrderedJob
     */
    job: Job;
    /**
     * 
     * @type {number}
     * @memberof OrderedJob
     */
    amount: number;
}

/**
 * Check if a given object implements the OrderedJob interface.
 */
export function instanceOfOrderedJob(value: object): value is OrderedJob {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('job' in value) || value['job'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    return true;
}

export function OrderedJobFromJSON(json: any): OrderedJob {
    return OrderedJobFromJSONTyped(json, false);
}

export function OrderedJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderedJob {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'job': JobFromJSON(json['job']),
        'amount': json['amount'],
    };
}

  export function OrderedJobToJSON(json: any): OrderedJob {
      return OrderedJobToJSONTyped(json, false);
  }

  export function OrderedJobToJSONTyped(value?: OrderedJob | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'job': JobToJSON(value['job']),
        'amount': value['amount'],
    };
}

