import { QueryOptions, unwrap } from './common';
import { CompanySettings } from '~/types';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export function useCompanySettigns<T = CompanySettings>(
  options?: QueryOptions<CompanySettings, T>,
) {
  return useQuery({
    ...options,
    queryKey: ['companySettings'],
    queryFn: () =>
      axios.get<CompanySettings>(`/company/settings/`).then(unwrap),
  });
}
