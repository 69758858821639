import { IonCard, IonCardHeader, IonCardSubtitle } from '@ionic/react';
import { useMemo } from 'react';
import routes from '../constants/routes.json';
import { useTranslate } from '~/i18n/translate';
import { Text } from './defaultUIComponents';
import { AggregatedSummedProjectOrders } from '~/api/OrderingApi';

type Props = {
  summedProjectOrder: AggregatedSummedProjectOrders;
};

const ProjectTile = ({ summedProjectOrder }: Props) => {
  const translate = useTranslate();
  const route = useMemo(
    () =>
      routes.PROJECT.replace(':id', summedProjectOrder.project.id.toString()),
    [summedProjectOrder],
  );

  return (
    <IonCard
      routerLink={route}
      routerDirection="forward"
      className="w-full py-0 mx-0 my-1"
    >
      <IonCardHeader className="py-3">
        <Text className="text-xl text-black">
          {summedProjectOrder.project.name}
        </Text>
        <IonCardSubtitle>
          {translate('projectTile.totalAmount') + ' '}
          {summedProjectOrder.totalAmount}
        </IonCardSubtitle>
      </IonCardHeader>
    </IonCard>
  );
};

export default ProjectTile;
