/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderProjectStatus = {
    OPEN: 'OPEN',
    FINISHED: 'FINISHED'
} as const;
export type OrderProjectStatus = typeof OrderProjectStatus[keyof typeof OrderProjectStatus];


export function instanceOfOrderProjectStatus(value: any): boolean {
    for (const key in OrderProjectStatus) {
        if (Object.prototype.hasOwnProperty.call(OrderProjectStatus, key)) {
            if (OrderProjectStatus[key as keyof typeof OrderProjectStatus] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OrderProjectStatusFromJSON(json: any): OrderProjectStatus {
    return OrderProjectStatusFromJSONTyped(json, false);
}

export function OrderProjectStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderProjectStatus {
    return json as OrderProjectStatus;
}

export function OrderProjectStatusToJSON(value?: OrderProjectStatus | null): any {
    return value as any;
}

export function OrderProjectStatusToJSONTyped(value: any, ignoreDiscriminator: boolean): OrderProjectStatus {
    return value as OrderProjectStatus;
}

