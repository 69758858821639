import { IonSelect, IonSelectOption } from '@ionic/react';
import React from 'react';
import { useTranslate } from '../../../i18n/translate';
import { BaseInputProps } from './types';

interface SelectProps<T>
  extends Omit<Omit<BaseInputProps, 'onFocus'>, 'onBlur'> {
  value?: T;
  onChange(value: T): void;
  onCancel?: (event: CustomEvent<void>) => void;
  multiple?: boolean;
  cancelText?: string;
  okText?: string;
  selectedText?: string;
  onFocus?: (event: CustomEvent<void>) => void;
  onBlur?: (event: CustomEvent<void>) => void;
  className?: string;
  showDefaultValue?: boolean;
  children: React.ReactNode;
}

interface OptionProps<T> {
  value: T;
  disabled?: boolean;
  children: React.ReactNode;
}

export function Option<T>({ children, ...props }: OptionProps<T>) {
  const translate = useTranslate();
  return <IonSelectOption {...props}>{translate(children)}</IonSelectOption>;
}

export function Select<T>({
  value,
  onChange,
  onBlur,
  onFocus,
  label,
  cancelText = 'select.cancelText',
  okText = 'select.okText',
  children,
  placeholder,
  onCancel,
  className = '',
  showDefaultValue = true,
  ...props
}: SelectProps<T>) {
  const translate = useTranslate();
  return (
    <IonSelect
      cancelText={translate(cancelText)}
      onIonFocus={onFocus}
      onIonBlur={onBlur}
      onIonCancel={onCancel}
      placeholder={translate(placeholder)}
      onIonChange={(e) => onChange(e.detail.value)}
      value={value}
      okText={translate(okText)}
      style={{
        '--placeholder-opacity': '1',
      }}
      className="border-b border-black border-opacity-30"
      label={translate(label)}
      labelPlacement="floating"
      fill="outline"
      {...props}
    >
      {showDefaultValue ? <Option value="">select.defaultValue</Option> : null}
      {children}
    </IonSelect>
  );
}
