import { IonContent, IonItem, IonLoading, IonPage } from '@ionic/react';
import _ from 'lodash';
import React, { useState } from 'react';
import { ListView, SearchField, Text } from '../components/defaultUIComponents';
import RoutineTile from '../components/RoutineTile';
import Toolbar from '../components/Toolbar/Toolbar';
import { useTranslate } from '../i18n/translate';
import { useRoutines } from '../api/RoutineApi';

const Routines: React.FC = () => {
  const translate = useTranslate();

  const { data: routines, isLoading } = useRoutines();
  const [search, setSearch] = useState('');

  return (
    <IonPage>
      <Toolbar>{translate('routines.toolbar')}</Toolbar>
      <IonContent>
        <IonLoading isOpen={isLoading} />
        {routines && routines.length > 0 ? (
          <>
            <SearchField onInputChange={setSearch} />
            <ListView
              data={_.orderBy(routines, 'name')}
              search={search}
              searchFields={(r) => [r.name]}
            >
              {({ data }) => (
                <>
                  {data.map((routine) => (
                    <RoutineTile
                      key={routine.id}
                      routine={routine}
                      isStartable
                    />
                  ))}
                </>
              )}
            </ListView>
          </>
        ) : (
          <IonItem lines="none">
            <Text textSize="xl">{'routines.noRoutines'}</Text>
          </IonItem>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Routines;
