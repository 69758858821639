import { QueryOptions, unwrap } from '~/api/common';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

type CompanySearchResult = { name: string; id: number }[];

export function useSearchCompany<T = CompanySearchResult>({
  searchTerm,
  ...options
}: QueryOptions<CompanySearchResult, T> & { searchTerm: string }) {
  return useQuery({
    ...options,
    queryKey: ['companies', searchTerm],
    queryFn: () =>
      axios.get(`/company/search/?searchTerm=${searchTerm}`).then(unwrap),
  });
}
