import React, { useMemo } from 'react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider as TanstackQueryClientProvider,
} from '@tanstack/react-query';
import { useIonToast, UseIonToastResult } from '@ionic/react';
import { useTranslate } from './i18n/translate';
import { isAxiosError } from 'axios';
import { ApiContextProvider, ApiError } from './api/common';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export function AppContext({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider>
      <ApiContextProvider>{children}</ApiContextProvider>
    </QueryClientProvider>
  );
}

type PresentToast = UseIonToastResult[0];

function createQueryClient(
  toast: PresentToast,
  t: ReturnType<typeof useTranslate>,
) {
  const onError = (error: Error) => {
    if (!isAxiosError(error)) {
      return toast({
        message: t('error.unknown'),
        duration: 1000,
        position: 'bottom',
        color: 'danger',
      });
    }
    const response = error.response?.data as ApiError | undefined;
    const status = error.response?.status;
    if (response && status) {
      if (status === 403) {
        return toast({
          message: t('authenticate.wrongInput'),
          duration: 2000,
          position: 'bottom',
          color: 'danger',
        });
      }
      return toast({
        message: `Status ${status}: ${t(response.detail)}`,
        duration: 2000,
        position: 'bottom',
        color: 'danger',
      });
    }

    return toast({
      message: t('error.unknown'),
      duration: 1000,
      position: 'bottom',
      color: 'danger',
    });
  };
  const queryCache = new QueryCache();
  const mutationCache = new MutationCache({
    onError,
  });
  return new QueryClient({
    queryCache,
    mutationCache,
  });
}

function QueryClientProvider({ children }: { children: React.ReactNode }) {
  const [present] = useIonToast();
  const t = useTranslate();
  const queryClient = useMemo(
    () => createQueryClient(present, t),
    [present, t],
  );
  return (
    <TanstackQueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </TanstackQueryClientProvider>
  );
}
