import { Plugins } from '@capacitor/core';
import { IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { DefaultToast } from '../DefaultToast';
import { FlexRow } from '../Layout';
import { CodeScannerProps } from './types';

export const CodeScannerNative: React.FC<CodeScannerProps> = ({
  functionToCallOnSuccess,
  functionToCallOnAbort,
  isScanning,
  setIsScanning,
}) => {
  const [debugOpenToast, setDebugOpenToast] = useState(false);
  const [debugQrResult, setDebugQrResult] = useState('');

  const { BarcodeScanner } = Plugins;

  const startScan = async () => {
    setIsScanning(true);
    BarcodeScanner.hideBackground();

    const result = await BarcodeScanner.startScan({
      targetedFormats: ['QR_CODE'],
    });

    if (result.hasContent) {
      setDebugQrResult(result.content);
      setDebugOpenToast(true);
      functionToCallOnSuccess(result.content);
    }
  };

  const stopScan = () => {
    setIsScanning(false);
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
  };

  const userHasPermission = async () => {
    const status = await BarcodeScanner.checkPermission({ force: true });
    if (status.granted) {
      return true;
    }
    return false;
  };

  const openAppPermissionSettings = () => {
    BarcodeScanner.openAppSettings();
  };

  const toggleScanningActivity = async () => {
    if (isScanning) {
      if (await userHasPermission()) {
        startScan();
      } else {
        openAppPermissionSettings();
      }
    } else {
      stopScan();
    }
  };

  useEffect(() => {
    if (isScanning) toggleScanningActivity();
  }, [isScanning]);

  return (
    <>
      {isScanning ? (
        <FlexRow justifyContent="end">
          <IonIcon
            icon={closeOutline}
            onClick={() => {
              setIsScanning(false);
              functionToCallOnAbort();
            }}
            className="bg-white w-12 h-12"
          >
            Stop Scan
          </IonIcon>
        </FlexRow>
      ) : null}
      <DefaultToast
        open={debugOpenToast}
        message={debugQrResult}
        setOpen={() => setDebugOpenToast(false)}
      />
    </>
  );
};
