/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderInformation
 */
export interface OrderInformation {
    /**
     * 
     * @type {number}
     * @memberof OrderInformation
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OrderInformation
     */
    customerName: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderInformation
     */
    dueDate: Date;
}

/**
 * Check if a given object implements the OrderInformation interface.
 */
export function instanceOfOrderInformation(value: object): value is OrderInformation {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('customerName' in value) || value['customerName'] === undefined) return false;
    if (!('dueDate' in value) || value['dueDate'] === undefined) return false;
    return true;
}

export function OrderInformationFromJSON(json: any): OrderInformation {
    return OrderInformationFromJSONTyped(json, false);
}

export function OrderInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderInformation {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerName': json['customerName'],
        'dueDate': (new Date(json['dueDate'])),
    };
}

  export function OrderInformationToJSON(json: any): OrderInformation {
      return OrderInformationToJSONTyped(json, false);
  }

  export function OrderInformationToJSONTyped(value?: OrderInformation | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'customerName': value['customerName'],
        'dueDate': ((value['dueDate']).toISOString().substring(0,10)),
    };
}

