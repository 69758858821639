import React, { FC, PropsWithChildren, useMemo } from 'react';
import { BaseFlexLayoutProps } from './types';

interface RowProps extends BaseFlexLayoutProps {}

export const FlexRow: FC<PropsWithChildren<RowProps>> = ({
  children,
  padding = 0,
  margin = 0,
  justifyContent = 'start',
  justifyItems,
  alignContent,
  spaceBetween,
  className,
}) => {
  const JustifyItems = useMemo(
    () => (justifyItems ? `justify-items-${justifyItems}` : ''),
    [justifyItems],
  );
  const AlignContent = useMemo(
    () => (alignContent ? `content-${alignContent}` : ''),
    [alignContent],
  );
  const SpaceBetween = useMemo(
    () => (spaceBetween ? `space-x-${spaceBetween}` : ''),
    [spaceBetween],
  );

  return (
    <div
      className={`w-full flex flex-row p-${padding} m-${margin} justify-${justifyContent} ${JustifyItems} ${AlignContent} ${SpaceBetween} ${
        className || ''
      }`}
    >
      {children}
    </div>
  );
};
