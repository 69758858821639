/* tslint:disable */
/* eslint-disable */
/**
 * Ordering Api
 * Its4 Plan Ordering Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HistoryType } from './HistoryType';
import {
    HistoryTypeFromJSON,
    HistoryTypeFromJSONTyped,
    HistoryTypeToJSON,
    HistoryTypeToJSONTyped,
} from './HistoryType';

/**
 * 
 * @export
 * @interface OrderedProjectHistory
 */
export interface OrderedProjectHistory {
    /**
     * 
     * @type {number}
     * @memberof OrderedProjectHistory
     */
    id?: number;
    /**
     * 
     * @type {HistoryType}
     * @memberof OrderedProjectHistory
     */
    type: HistoryType;
    /**
     * 
     * @type {number}
     * @memberof OrderedProjectHistory
     */
    changedBy: number;
    /**
     * 
     * @type {number}
     * @memberof OrderedProjectHistory
     */
    doneAmount?: number;
    /**
     * 
     * @type {Date}
     * @memberof OrderedProjectHistory
     */
    doneAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderedProjectHistory
     */
    comment?: string;
}



/**
 * Check if a given object implements the OrderedProjectHistory interface.
 */
export function instanceOfOrderedProjectHistory(value: object): value is OrderedProjectHistory {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('changedBy' in value) || value['changedBy'] === undefined) return false;
    return true;
}

export function OrderedProjectHistoryFromJSON(json: any): OrderedProjectHistory {
    return OrderedProjectHistoryFromJSONTyped(json, false);
}

export function OrderedProjectHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderedProjectHistory {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'type': HistoryTypeFromJSON(json['type']),
        'changedBy': json['changedBy'],
        'doneAmount': json['doneAmount'] == null ? undefined : json['doneAmount'],
        'doneAt': json['doneAt'] == null ? undefined : (new Date(json['doneAt'])),
        'comment': json['comment'] == null ? undefined : json['comment'],
    };
}

  export function OrderedProjectHistoryToJSON(json: any): OrderedProjectHistory {
      return OrderedProjectHistoryToJSONTyped(json, false);
  }

  export function OrderedProjectHistoryToJSONTyped(value?: OrderedProjectHistory | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'type': HistoryTypeToJSON(value['type']),
        'changedBy': value['changedBy'],
        'doneAmount': value['doneAmount'],
        'doneAt': value['doneAt'] == null ? undefined : ((value['doneAt']).toISOString()),
        'comment': value['comment'],
    };
}

