import { createContext, useContext, useMemo } from 'react';
import { create, StoreApi } from 'zustand';
import { CreatableTodo } from '~/types';
import _ from 'lodash';

type TDayplanStore = {
  todos: CreatableTodo[];
  toggleTodo: (jobId: number) => void;
  isEditingTodos: boolean;
  setTodoAmount: (jobId: number, amount: number) => void;
};

export const useDayplanStore = create<TDayplanStore>((set, get) => ({
  todos: [],
  toggleTodo: (jobId: number) =>
    set((state) => {
      const index = _.findIndex(state.todos, (todo) => todo.job_id === jobId);
      const todo = { job_id: jobId, amount: 1 };
      if (index === -1) {
        return { todos: [...state.todos, todo] };
      } else {
        return {
          todos: [
            ...state.todos.slice(0, index),
            ...state.todos.slice(index + 1),
          ],
        };
      }
    }),
  setTodoAmount: (jobId: number, amount: number) => {
    set((state) => {
      const index = _.findIndex(state.todos, (todo) => todo.job_id === jobId);
      const todo = { job_id: jobId, amount };
      if (index === -1) {
        return { todos: [...state.todos, todo] };
      } else {
        return {
          todos: [
            ...state.todos.slice(0, index),
            todo,
            ...state.todos.slice(index + 1),
          ],
        };
      }
    });
  },
  get isEditingTodos() {
    return get().todos.length > 0;
  },
}));
