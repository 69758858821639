import '@total-typescript/ts-reset';
import {IonApp, IonRouterOutlet} from '@ionic/react';
import {IonReactHashRouter as IonReactRouter} from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Theme variables */
import './theme/variables.css';
import './theme/tailwind.css';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, {useEffect} from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import Menu from './components/Menu';
import routes from './constants/routes.json';
import {appPages} from './menuRoutes';
import Authenticate from './pages/Authenticate';
import QRCodePage from './pages/CodeScannerPage';
import CreateNewJob from './pages/CreateNewJob';
import Dayplan from './pages/Dayplan';
import Overseer from './pages/Overseer';
import Orders from './pages/Orders';
import ProjectPage from './pages/Project';
import Routines from './pages/Routines';
import RunJob from './pages/RunJob';
import RunNewJob from './pages/RunNewJob';
import RunningJobs from './pages/RunningJobs';
import RunRoutine from './pages/RunRoutine';
import RunSpecialJob from './pages/RunSpecialJob';
import Settings from './pages/Settings';
import SpecialJob from './pages/SpecialJob';
import TimeTracking from './pages/TimeTracking';
import Weekplan from './pages/Weekplan';
import {createHashHistory as createHistory} from 'history';

import {FeatureFlag, isFeatureFlagActive} from './utils/featureFlag';
import {useSettingsStore} from './state/settings';
import {useAuthStore} from './state/auth';
import {RoleCode} from '~/types';

const findFirstActiveMenuEntry = (
  featureFlags: string,
  hasOverseerRole: boolean | undefined,
) => {
  const activeMenuEntry = _.find(appPages(hasOverseerRole), (appPage) =>
    featureFlags.includes(appPage.featureFlag),
  );
  if (activeMenuEntry) {
    return activeMenuEntry.url;
  }
  return routes.AUTHENTICATE;
};

const RenderRedirect: React.FC<{ path: string }> = ({ path }) => {
  return <Redirect to={path} />;
};

type PrivateRouteProps = Omit<RouteProps, 'component'> & {
  featureFlag: FeatureFlag;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  path,
  featureFlag,
  children,
  ...props
}) => {
  const { feature_flags: featureFlags = '', authenticated: isLoggedIn } =
    useAuthStore();
  if (isLoggedIn && isFeatureFlagActive(featureFlags, featureFlag)) {
    return (
      <Route path={path} {...props}>
        {children}
      </Route>
    );
  }
  return <Redirect to={routes.AUTHENTICATE} />;
};

const history = createHistory();

const App: React.FC = () => {
  const { fetchLanguage } = useSettingsStore();
  const {
    authenticated: isLoggedIn = false,
    feature_flags: featureFlags = '',
  } = useAuthStore();
  const hasOverseerRole = useAuthStore(
    (store) => !!store.roles?.find((r) => r.role.code === RoleCode.OVERSEER),
  );

  useEffect(() => {
    void fetchLanguage();
  }, []);

  // GMT-0
  moment.tz.setDefault('Africa/Monrovia');

  return (
    <IonApp>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      <IonReactRouter history={history as any}>
        <Menu />
        <IonRouterOutlet id="main" >
          <PrivateRoute
            featureFlag={FeatureFlag.TIMETRACKING}
            path={routes.TIME_TRACKING}
            exact
          >
            <TimeTracking />
          </PrivateRoute>
          <PrivateRoute
            featureFlag={FeatureFlag.ORDERS}
            path={routes.ORDERS}
            exact
          >
            <Orders />
          </PrivateRoute>
          <PrivateRoute
            featureFlag={FeatureFlag.ORDERS}
            path={routes.PROJECT}
            exact
          >
            <ProjectPage />
          </PrivateRoute>
          <PrivateRoute
            featureFlag={FeatureFlag.RUNNINGJOBS}
            path={routes.RUN_JOB}
            exact
          >
            <RunJob />
          </PrivateRoute>
          <PrivateRoute
            featureFlag={FeatureFlag.DAYPLAN}
            path={routes.DAY_PLAN}
            exact
          >
            <Dayplan />
          </PrivateRoute>
          <PrivateRoute
            featureFlag={FeatureFlag.WEEKPLAN}
            path={routes.WEEK_PLAN}
            exact
          >
            <Weekplan />
          </PrivateRoute>
          <PrivateRoute
            featureFlag={FeatureFlag.ROUTINES}
            path={routes.ROUTINES}
            exact
          >
            <Routines />
          </PrivateRoute>
          <PrivateRoute
            featureFlag={FeatureFlag.ROUTINES}
            path={routes.RUN_ROUTINE}
            exact
          >
            <RunRoutine />
          </PrivateRoute>
          <PrivateRoute
            featureFlag={FeatureFlag.RUNNINGJOBS}
            path={routes.RUNNING_JOBS}
            exact
          >
            <RunningJobs />
          </PrivateRoute>
          <PrivateRoute
            featureFlag={FeatureFlag.RUNNINGJOBS}
            path={routes.JOBS_COLLEAGUES}
            exact
            render={() => {
              if (hasOverseerRole) {
                return <RunningJobs />;
              }
              return <Redirect to={routes.AUTHENTICATE} />;
            }}
          >
            <Overseer />
          </PrivateRoute>
          <PrivateRoute
            featureFlag={FeatureFlag.SPECIALJOBS}
            path={routes.SPECIAL_JOBS}
            exact
          >
            <SpecialJob />
          </PrivateRoute>
          <PrivateRoute
            featureFlag={FeatureFlag.SPECIALJOBS}
            path={routes.RUN_SPECIAL_JOBS}
            exact
          >
            <RunSpecialJob />
          </PrivateRoute>
          <PrivateRoute
            featureFlag={FeatureFlag.NEWJOBS}
            path={routes.CREATE_JOB}
            exact
          >
            <CreateNewJob />
          </PrivateRoute>
          <PrivateRoute
            featureFlag={FeatureFlag.NEWJOBS}
            path={routes.RUN_NEW_JOB}
            exact
          >
            <RunNewJob />
          </PrivateRoute>
          <PrivateRoute
            featureFlag={FeatureFlag.TIMETRACKING}
            path={routes.TIME_TRACKING}
            exact
          >
            <TimeTracking />
          </PrivateRoute>
          <PrivateRoute
            featureFlag={FeatureFlag.TIMETRACKING}
            path={routes.SETTINGS}
            exact
          >
            <Settings />
          </PrivateRoute>
          <Route path={routes.CODE_SCANNING} exact>
            <QRCodePage />
          </Route>
          <Route path={routes.AUTHENTICATE} exact>
            <Authenticate />
          </Route>
          <Route
            render={() =>
              !isLoggedIn ? (
                <Redirect to={routes.AUTHENTICATE} />
              ) : (
                <RenderRedirect
                  path={findFirstActiveMenuEntry(featureFlags, hasOverseerRole)}
                />
              )
            }
          />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
