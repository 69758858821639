import { IonContent, IonLoading, IonPage } from '@ionic/react';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { Button, FlexColumn } from '~/components/defaultUIComponents';
import Toolbar from '../components/Toolbar/Toolbar';
import WeekplanHeader from '../components/WeekplanHeader';
import WeekplanTile from '../components/WeekplanTile';
import { useTranslate } from '~/i18n/translate';
import { useOrderedProjects } from '~/api/OrderingApi';

const Weekplan: React.FC = () => {
  const [sortByWeek, setSortByWeek] = useState(true);
  const [openCollapseId, setOpenCollapseId] = useState(-1);
  const translate = useTranslate();

  const { data: orderedProjects = [], isLoading } = useOrderedProjects({
    select: (data) => _.orderBy(data, [(op) => op.priority]),
  });

  const uniqueWeeks = useMemo(() => {
    const weeks = orderedProjects.map((entry) => entry.priority);
    return weeks
      .filter((item, index) => weeks.indexOf(item) === index)
      .sort((a, b) => a - b);
  }, [orderedProjects]);

  const sortedOrderedProjects = useMemo(() => {
    return (
      _.sortBy(orderedProjects, (we) => we.project.name.toLocaleLowerCase()) ??
      []
    );
  }, [orderedProjects]);

  const sortButtonText =
    translate('weekplan.sortPrefixtText') +
    ' ' +
    translate(sortByWeek ? 'weekplan.sortByWeek' : 'weekplan.sortByName');

  const onSortChange = () => {
    setSortByWeek(!sortByWeek);
  };

  return (
    <IonPage>
      <Toolbar>{translate('weekplan.toolbar')}</Toolbar>
      <IonContent>
        <IonLoading isOpen={isLoading} />
        <FlexColumn padding={2}>
          <Button onClick={onSortChange} className="mb-2">
            {sortButtonText}
          </Button>

          {sortByWeek
            ? uniqueWeeks.map((weekHeader, index) => (
                <WeekplanHeader
                  key={index}
                  weekHeader={weekHeader}
                  uniqueWeeks={uniqueWeeks}
                  openCollapseId={openCollapseId}
                  setOpenCollapseId={setOpenCollapseId}
                />
              ))
            : sortedOrderedProjects.map((we) => (
                <WeekplanTile
                  key={we.id}
                  orderedProject={we}
                  sortByName={!sortByWeek}
                />
              ))}
        </FlexColumn>
      </IonContent>
    </IonPage>
  );
};

export default Weekplan;
