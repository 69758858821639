import React, { useMemo } from 'react';

interface ListViewChildProps<Data> {
  data: Data[];
}

interface ListViewProps<Data> {
  data: Data[];
  search: string;
  searchFields(d: Data): string[];
  children(props: ListViewChildProps<Data>): JSX.Element;
}

export function ListView<Data>({
  data: propData,
  search,
  searchFields,
  children,
}: ListViewProps<Data>) {
  const data = useMemo(
    () =>
      !search
        ? propData
        : propData.filter((d) =>
            searchFields(d)
              .map((sf) => sf.toLowerCase().includes(search.toLowerCase()))
              .reduce((c, n) => c || n, false),
          ),
    [propData, search, searchFields],
  );
  return <>{children({ data })}</>;
}
