import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonList,
  IonSpinner,
} from '@ionic/react';
import { checkmarkCircle, closeCircle, pauseCircle } from 'ionicons/icons';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import {
  isRunningJob,
  isRunningRoutine,
  isRunningSpecialJob,
} from '~/utils/runningAny';
import { Employee, RunningAny } from '~/types';
import { useDateToLocaleString, useTimeToLocaleString } from '~/utils/dateUtil';
import { durationToString } from '~/utils/durationUtil';
import { formatName } from '~/utils/employeeUtils';
import { CollapseButton } from './Button/CollapseButton';
import { FlexRow } from './Layout';
import { Flex } from './Layout/Flex';
import { Text } from './Typography';
import { Collapse } from './Collapse';
import { useJobsOfCollegue } from '~/api/OverseerApi';

interface RunningInfoProps {
  job: RunningAny;
}

const RunningInfo: React.FC<RunningInfoProps> = ({ job }) => {
  const formatToDateStr = useDateToLocaleString();
  const formatToTimeStr = useTimeToLocaleString();

  const name = useMemo(() => {
    if (isRunningJob(job)) {
      return job.job.name;
    } else if (isRunningRoutine(job)) {
      return job.routine.name;
    } else if (isRunningSpecialJob(job)) {
      return job.special_job.name;
    } else {
      return job.new_job.name;
    }
  }, [job]);

  return (
    <div className="grid gap-x-2 grid-cols-3 border-b-2">
      <Text className="col-span-2">{name}</Text>
      <Text>
        {job.status === 'P' ? 'job-state.pause' : 'job-state.running'}
      </Text>
      <div className="col-span-2">
        <Text className="mr-1">overseer.start</Text>
        <Text>{`${formatToDateStr(job.start)} ${formatToTimeStr(
          job.start,
        )}`}</Text>
      </div>
      <div>
        <Text className="mr-1">overseer.duration</Text>
        <Text>
          {durationToString(moment.duration({ seconds: job.duration as any }))}
        </Text>
      </div>
    </div>
  );
};

interface JobsColleaguesTileProps {
  employee: Employee;
}

export const JobsColleaguesTile: React.FC<JobsColleaguesTileProps> = ({
  employee,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: jobs, isLoading } = useJobsOfCollegue({
    employeeId: Number(employee.id),
    refetchInterval: 1000,
    enabled: isOpen,
  });

  return (
    <IonCard>
      <IonCardHeader>
        <FlexRow justifyContent="between">
          <IonCardTitle>{formatName(employee)}</IonCardTitle>
          <CollapseButton
            isOpen={isOpen}
            toggleIsOpen={() => setIsOpen((open) => !open)}
          />
        </FlexRow>
        <IonCardSubtitle>
          <Flex direction="row" justifyItems="center">
            {employee.status === 'W' && (
              <IonIcon
                color="primary"
                icon={checkmarkCircle}
                className="w-5 h-5 mr-1"
              />
            )}
            {employee.status === 'P' && (
              <IonIcon
                color="warning"
                icon={pauseCircle}
                className="w-5 h-5 mr-1"
              />
            )}
            {employee.status !== 'P' && employee.status !== 'W' && (
              <IonIcon
                color="danger"
                icon={closeCircle}
                className="w-5 h-5 mr-1"
              />
            )}

            <Text>
              {employee.status === 'W'
                ? 'overseer.status.arbeit'
                : employee.status === 'P'
                ? 'overseer.status.pause'
                : 'overseer.status.notLoggedIn'}
            </Text>
          </Flex>
        </IonCardSubtitle>
      </IonCardHeader>
      <Collapse isOpen={isOpen}>
        <IonCardContent>
          <IonList>
            {isLoading && <IonSpinner />}
            {jobs?.map((job) => (
              <RunningInfo key={job.id} job={job} />
            ))}
          </IonList>
        </IonCardContent>
      </Collapse>
    </IonCard>
  );
};
