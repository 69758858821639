import { IonInput } from '@ionic/react';
import React from 'react';
import { useTranslate } from '../../../i18n/translate';
import { BaseInputProps } from './types';

interface TextInputProps extends BaseInputProps {
  value: string | null | undefined;
  onChange(value: string | null | undefined): void;
  maxlength?: number;
  minlength?: number;
  pattern?: string;
  inputmode?: 'email' | 'search' | 'tel' | 'text' | 'url';
  type?: 'text' | 'email' | 'search' | 'tel' | 'url' | 'password';
  obscureText?: boolean;
  spellcheck?: boolean;
  row?: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({
  onChange,
  onBlur,
  onFocus,
  label,
  placeholder,
  obscureText,
  type,
  row = false,
  ...props
}) => {
  const translate = useTranslate();
  return (
    <IonInput
      style={{
        color: '#000',
      }}
      onIonFocus={onFocus}
      onIonBlur={onBlur}
      type={obscureText ? 'password' : type}
      onIonInput={(e) => onChange(e.detail.value)}
      fill="outline"
      label={translate(label)}
      labelPlacement="floating"
      {...props}
    />
  );
};
