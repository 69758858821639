import { MutationOptions, unwrap } from '~/api/common';
import { ChangeRequestValues } from '~/types';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

export function useCreateChangeRequest(
  options?: MutationOptions<void, ChangeRequestValues>,
) {
  return useMutation({
    ...options,
    mutationFn: (values) => axios.post(`/change_request/`, values).then(unwrap),
  });
}
