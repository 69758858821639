import React from 'react';
import QrReader from 'react-qr-reader';
import { CodeScannerProps } from './types';
import { Button } from '../Button';

const CodeScannerWebApp: React.FC<CodeScannerProps> = ({
  functionToCallOnSuccess,
  functionToCallOnAbort,
  isScanning,
  setIsScanning,
}) => {
  const handleScan = (data: string | null) => {
    if (data) {
      setIsScanning(false);
      functionToCallOnSuccess(data);
    }
  };

  const handleError = () => {
    setIsScanning(false);
    functionToCallOnAbort();
  };

  return (
    <div>
      {/* @ts-ignore */}
      <QrReader
        delay={300}
        className="lg:mt-4 m-auto max-w-lg md:w-full"
        onError={handleError}
        onScan={handleScan}
      />
      {process.env.NODE_ENV === 'development' && (
        <Button onClick={() => handleScan('MagicPass')}>Skip</Button>
      )}
    </div>
  );
};

export default CodeScannerWebApp;
