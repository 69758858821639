import { IonToast } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import React from 'react';
import { useTranslate } from '../../i18n/translate';

interface DefaultToastProps {
  open: boolean;
  message: string;
  setOpen:
    | ((value?: boolean) => boolean | void | Promise<boolean>)
    | React.Dispatch<React.SetStateAction<boolean>>;
}

export const DefaultToast: React.FC<DefaultToastProps> = ({
  open,
  message,
  setOpen: actionOnClose,
}: DefaultToastProps) => {
  const translate = useTranslate();
  return (
    <IonToast
      color="danger"
      isOpen={open}
      message={translate(message)}
      onDidDismiss={() => actionOnClose(false)}
      buttons={[
        {
          side: 'end',
          role: 'cancel',
          icon: closeOutline,
          handler: () => actionOnClose(false) as any,
        },
      ]}
      duration={5000}
    />
  );
};
