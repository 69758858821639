import { MutationOptions, QueryOptions, unwrap } from './common';
import { Language } from '~/i18n/LanguageSpec';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

type Settings = {
  language: Language;
};

export function useSettings<T = Settings>(options?: QueryOptions<Settings, T>) {
  return useQuery({
    ...options,
    queryFn: () => axios.get('/settings/').then(unwrap),
    queryKey: ['settings'],
  });
}

export function useUpdateSettings(
  options?: MutationOptions<Settings, Settings>,
) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: (settings: Settings) =>
      axios.put('/settings/', settings).then(unwrap),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ['settings'] });
      options?.onSuccess?.(...args);
    },
  });
}
