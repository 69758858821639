import { IonContent, IonLoading, IonPage } from '@ionic/react';
import React, { useState } from 'react';
import {
  FlexColumn,
  ListView,
  SearchField,
} from '~/components/defaultUIComponents';
import ProjectTile from '../components/ProjectTile';
import Toolbar from '../components/Toolbar/Toolbar';
import { useTranslate } from '~/i18n/translate';
import { useSummedOrderedProjects } from '~/api/OrderingApi';
import _ from 'lodash';

const Orders: React.FC = () => {
  const translate = useTranslate();
  const { data: orders = [], isLoading } = useSummedOrderedProjects({
    select: (data) =>
      _.orderBy(
        data.filter((sop) => !sop.project.is_option),
        [(order) => order.project.name],
      ),
  });
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <IonPage>
      <Toolbar>{translate('orders.toolbar')}</Toolbar>
      <IonContent>
        <IonLoading isOpen={isLoading} />
        <FlexColumn padding={2}>
          <SearchField onInputChange={setSearchTerm} />
          <ListView
            data={orders}
            search={searchTerm}
            searchFields={(order) => [order.project.name]}
          >
            {({ data }) => (
              <>
                {data.map((order) => (
                  <ProjectTile
                    summedProjectOrder={order}
                    key={order.project.id}
                  />
                ))}
              </>
            )}
          </ListView>
        </FlexColumn>
      </IonContent>
    </IonPage>
  );
};

export default Orders;
